import React, { useState } from "react";
import { Input } from "antd";
import { ToastContainer, toast } from "react-toastify";
import Dropdown from "./Drop";
const RecursiveJsonComponent = ({
  data,
  editable,
  onSubmitt,
  submitColor,
  onClose,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [readOnly, setReadOnly] = useState(editable);
  const [editedValues, setEditedValues] = useState(data);

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };
  const handleSubmit = () => {
    onSubmitt(editedValues);
  };

  const handleInputChange = (keyPath, newValue) => {
    setEditedValues((prevValues) => {
      // Deep copy the previous state to avoid modifying it directly
      const updatedValues = JSON.parse(JSON.stringify(prevValues));
      let current = updatedValues;

      for (let i = 0; i < keyPath.length - 1; i++) {
        current = current[keyPath[i]];
      }

      const lastKey = keyPath[keyPath.length - 1];
      const isArray = Array.isArray(current[lastKey]);
      const isObject = typeof current[lastKey] === "object";

      if (isArray && Array.isArray(newValue)) {
        current[lastKey] = [...newValue]; // Replace array with a new one
      } else if (isObject && typeof newValue === "object") {
        current[lastKey] = { ...current[lastKey], ...newValue }; // Update object with new properties
      } else {
        current[lastKey] = newValue; // Update other types of values
      }

      return updatedValues;
    });
  };

  const iterateJson = (data, keyPath = []) => {
    if (!data) {
      return (
        <Input
          disabled={false}
          key={keyPath.join("-")} // Combine keyPath for unique key
          placeholder={keyPath.length ? keyPath[keyPath.length - 1] : "Value"}
          value={getNestedValue(editedValues, keyPath)}
          size="large"
          onChange={(e) => handleInputChange(keyPath, e.target.value)}
        />
      );
    }

    if (typeof data === "object" && !Array.isArray(data)) {
      return Object.keys(data).map((key, id) => {
        // const [dropDown, setDropDown]=useState(false);

        const newKeyPath = [...keyPath, key];

        return (
          <div className={` p-[-4px] flex flex-col `} key={key}>
            <div className="flex"></div>
            <Dropdown item={""} index={""} keyi={key}>
              {iterateJson(data[key], newKeyPath)}
            </Dropdown>
          </div>
        );
      });
    } else if (Array.isArray(data)) {
      return data.map((item, index) => {
        const newKeyPath = [...keyPath, index];
        return (
          <Dropdown item={item} index={index} key={index}>
            <div className=" flex flex-col p-[-4px]" key={index}>
              {iterateJson(item, newKeyPath)}
            </div>
          </Dropdown>
        );
      });
    } else {
      return (
        <div>
          <Input
            disabled={readOnly}
            key={keyPath.join("-")} // Combine keyPath for unique key
            placeholder={keyPath.length ? keyPath[keyPath.length - 1] : "Value"}
            value={getNestedValue(editedValues, keyPath)}
            size="large"
            onChange={(e) => handleInputChange(keyPath, e.target.value)}
          />
        </div>
      );
    }
  };
  console.log("submit color :", submitColor);

  const getNestedValue = (obj, keyPath) => {
    let current = obj;
    for (const key of keyPath) {
      if (current && typeof current === "object") {
        current = current[key];
      } else {
        return ""; // Return empty string if path is invalid
      }
    }
    return current || ""; // Return value or empty string
  };

  return (
    <div className=" h-full w-full ">
      {isOpen && (
        <div className=" fixed h-[100vh]  w-[100vw] left-0 top-0 flex  justify-center items-center no-scroll  bg-black bg-opacity-50 z-[6] sm:">
          <div className="flex flex-col h-auto max-h-[80%] w-[70%]  bg-white rounded-lg overflow-hidden sm:w-[50%]">
            <div className="bg-gray-800 z-[600] flex justify-between text-white  py-4 px-6 ">
              <h1 className="text-sm " >Update User Record</h1>
              <div className="flex gap-3 h-7">
                <div
                  onClick={() => {
                    navigator.clipboard
                      .writeText(JSON.stringify(data, null, 2))
                      .then(
                        toast.success("Copied", {
                          position: "top-right",
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "light",
                        })
                      );
                  }}
                  className=" bg-red-500 cursor-pointer h-auto p-1 rounded"
                >
                  Copy
                </div>
                <div className="cursor-pointer p-1" onClick={onClose}>
                  Close
                </div>
              </div>
            </div>
            <div className="h-auto overflow-y-scroll p-4   overflow-hidden py-4">
              {iterateJson(data)}
            </div>
            <div className="bg-gray-800 flex justify-end gap-4  text-white py-4 px-6  ">
              <button
                onClick={handleSubmit}
                className={`bg-${submitColor} p-2 rounded`}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RecursiveJsonComponent;
