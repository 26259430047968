// loginSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { REACT_APP_API_URL } from "../../secrets";
const seceretKey = process.env.REACT_APP_BASE_URL
const token =localStorage.getItem('token');
export const CompaniesFetch = createAsyncThunk("ROwn/CompanieSlice", async (paginationAndQueryData) => {
    const response = await fetch(`${seceretKey}api/Rown/getCompanyDetail?Keyword=${paginationAndQueryData.searchQuery}&page=${paginationAndQueryData.currentPage}`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "mode":'no-cors',
            Authorization:token
        }
    });
    return await response.json();
});
export const companiesPatch = createAsyncThunk(
    'R-Own/companiesPatch',
    async(e, thunkAPI) => {
        console.log(e.userId)
      try {
        const response = await fetch(`${seceretKey}api/Rown/editCompanyDetail?company_id=${e.company_id}`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization:token
          },
          body: JSON.stringify(e),
        });
        const data = await response.json();
        return data;
      } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    }
  );
const CompanieSlice = createSlice({
    name: "Companies",
    initialState: {
        CompaniesData: null,
        loading: false,
        error: null,
        patchData:null,
    },
    extraReducers: (builder) => {
        builder.addCase(CompaniesFetch.pending, (state, action) => {
            state.loading = true;
            state.CompaniesData = null;
            state.error = null;
        });
        builder.addCase(CompaniesFetch.fulfilled, (state, action) => {
            state.loading = false;
            state.CompaniesData = action.payload;
            state.error = null;
        });
        builder.addCase(CompaniesFetch.rejected, (state, action) => {
            state.loading = false;
            state.CompaniesData = null;
            state.error = action.error.message;
        });
        builder.addCase(companiesPatch.pending, (state, action) => {
            state.patchData = null;
            state.loading = true;

        });
        builder.addCase(companiesPatch.fulfilled, (state, action) => {
            state.loading = false;

            state.patchData = action.payload;
        });
        builder.addCase(companiesPatch.rejected, (state, action) => {
            state.patchData = null;
            state.loading = false;

            // state.error = action.error.message;
        });
    }
});

export default CompanieSlice.reducer;
