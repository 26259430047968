import React, { useState, useEffect } from "react";
import TagInput from "./TagInput";
import { useDispatch, useSelector } from "react-redux";
import { addPostData } from "../utils/BlogSlices/PostDetailSlice";
import TextEditor from "./customEditor";
import DropDown from "./DropDown";
import { categoriesData } from "../utils/Categories/MainCategorieSlice";
import { subcategoriesData } from "../utils/Categories/SubCategorieSlice";
const BlogForm = () => {
  const dispatch = useDispatch();
  
  const primaryKeyword = useSelector((store) => store.PostData.primaryKeyword);
  const secondaryKeyword = useSelector(
    (store) => store.PostData.secondaryKeyword
  );
  const [textAreas, setTextAreas] = useState([
    {
      contentType: "",
      contentImage: null,
      contentImageText: "",
    },
  ]);
  const [formData, setFormData] = useState({
    title: "",
    image: null,
    content: textAreas,
    primaryKeyword: [],
    secondaryKeyword: "",
  });
  useEffect(() => {
    setFormData({
      ...formData,
      content: textAreas,
    });
  }, [textAreas]);
  useEffect(() => {
    dispatch(categoriesData());
    dispatch(subcategoriesData());
  }, [dispatch]);

  useEffect(() => {
    setFormData({
      ...formData,
      secondaryKeyword: secondaryKeyword,
    });
  }, [secondaryKeyword]);

  useEffect(() => {
    setFormData({
      ...formData,
      primaryKeyword: primaryKeyword,
    });
  }, [primaryKeyword]);
  const categorieData = useSelector(
    (store) => store.mainCategories.mainCategories?.data
  );
  const subCategorieData = useSelector(
    (store) => store.subCategories.subCategories?.data
  );

  const [title, setTitle] = useState("");

  const [selectedImage, setSelectedImage] = useState(null);
  const [contentImage, setContentImage] = useState([]);
  // Initial state with onye text area

  const handleInputChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  function handleEditSubmit(e, index) {
    let randTextarea = textAreas;
    randTextarea[index]["contentType"] = e;
    setTextAreas(randTextarea);
  }
  function handleEditImage(e, index) {
    let randTextarea = textAreas;
    randTextarea[index]["contentImage"] = e.target.files[0];
    setTextAreas(randTextarea);
  }


  const handleTextAreaChange = (index, value) => {
    const newTextAreas = [...formData.textAreas];
    newTextAreas[index] = value;
    setFormData({
      ...formData,
      primaryKeyword: newTextAreas,
    });
  };

  const handleAddTextArea = () => {
    setTextAreas([
      ...textAreas,
      {
        contentType: "",
        contentImage: null,
        contentImageText: "",
      },
    ]); // Add an empty text area to the state array
  };
  const handleRemoveTextArea = () => {
    const newTextAreaList = [...textAreas];
    newTextAreaList.splice(0, 1); // Remove the text area at the specified index
    setTextAreas(newTextAreaList); // Add an empty text area to the state array
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log(formData);
    // Here you can add your logic to handle the form submission, such as sending the data to a server or saving it locally

    // dispatch(addPostData(formData));
    // console.log("Content:", content);
  };

  return (
    <div className="max-w-4xl mx-auto p-4">
      <h2 className="text-2xl font-semibold mb-4">Post Details</h2>
      <form className="space-y-4" onSubmit={handleSubmit}>
        <div className="flex items-center justify-between">
          <label htmlFor="title" className=" w-[10%] ">
            Title:
          </label>
          <input
            type="text"
            id="title"
            className=" border w-[90%] border-gray-300 rounded px-4 py-2"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
              handleInputChange("title", e.target.value);
            }}
          />
        </div>
        <div className="flex items-center justify-between">
          <label htmlFor="image" className=" w-[10%] ">
            Image:
          </label>
          <div className="w-[90%]">
            <label className="block bg-gray-200 text-gray-700 cursor-pointer rounded p-1 border border-gray-300">
              <input
                type="file"
                onChange={(e) => {
                  const file = e.target.files[0];
                  setSelectedImage(file);
                  handleInputChange("image", file);
                }}
                accept="image/*"
                className="hidden"
              />
              <span className="text-sm">Select Image</span>
            </label>
            {selectedImage && <p>Selected Image: {selectedImage.name}</p>}
          </div>
        </div>

        {textAreas.map((text, index) => (
          <div key={index} className="flex flex-col mb-4">
            <label htmlFor={`content-${index}`} className="text-sm mb-2">
              Content {index + 1}:
            </label>
            <div className="flex items-center">
              <TextEditor
                setContentType={(e) => {
                  handleEditSubmit(e, index);
                }}
              />
              <div className="flex flex-col justify-between">
                <label className="block bg-gray-200 text-gray-700 cursor-pointer rounded p-1 border border-gray-300 mb-2">
                  <input
                    type="file"
                    onChange={(e) =>
                     handleEditImage(e,index)
                    }
                    accept="image/*"
                    className="hidden"
                  />
                  <span className="text-sm">Select Image</span>
                </label>
                <input
                  type="text"
                  className="border-2"
                  name=""
                  id=""
                  placeholder="Alt Text"
                />

                {contentImage && (
                  <p>
                    Selected Image:{" "}
                    {contentImage[index]?.name
                      ? contentImage[index].name
                      : "Image not found"}
                  </p>
                )}
                <button
                  type="submit"
                  className="py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-600"
                >
                  Upload Image
                </button>
              </div>
                <div className="m-5 cursor-pointer">
                  X
                </div>
            </div>
          </div>
        ))}
        <div className="flex justify-center items-center ">
          <div className="my-6 text-center m-2" onClick={handleAddTextArea}>
            <button className="border border-gray-300 rounded px-4 py-2">
              Add content
            </button>
          </div>
          <div className="my-6 text-center" onClick={handleRemoveTextArea}>
            <button className="border border-gray-300 rounded px-4 py-2">
              Remove Content
            </button>
          </div>
        </div>
        <div className="flex justify-between  ">
          <div>
            <div className="flex items-center ">
              <label htmlFor="primary-keyword" className="w-[20%]">
                Primary Keyword:
              </label>
              <TagInput
                type="primary"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    primaryKeyword: primaryKeyword,
                  });
                }}
              />
            </div>

            <div className="flex items-center">
              <label htmlFor="secondary-keyword" className="w-[20%]">
                Secondary Keyword
              </label>
              <TagInput
                type="secondary"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    secondaryKeyword: secondaryKeyword,
                  });
                }}
              />
            </div>
          </div>
          <div class="border-2 w-[40%] text-sm  p-5">
            <h1 class="">Categories</h1>

            <div class="flex flex-col mt-3">
              <label for="sub-category" class="mb-1">
                Main Category
              </label>
              <DropDown
                data={categorieData?.map((item) => ({
                  label: item.categoryName,
                  value: item.categoryId,
                }))}
              />
            </div>
            <div class="flex  flex-col mt-3">
              <label for="sub-category" class="mb-1">
                Sub Category
              </label>
              <DropDown
                data={subCategorieData?.map((item) => ({
                  label: item.subCategoryName,
                  value: item.subCategoryId,
                }))}
              />
            </div>
          </div>
        </div>

        <button className="w-full border-2 py-2 px-4  bg-blue-500 hover:bg-blue-400">
          Submit
        </button>
      </form>
    </div>
  );
};

export default BlogForm;
