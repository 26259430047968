import React from 'react';
import { Select, Tag } from 'antd';
import { useSelector } from 'react-redux';
import { redirect } from 'react-router-dom';

// const categorieData = useSelector((store) => store.mainCategories.mainCategories?.data);
const tagRender = (props) => {
  const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      color={value}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{
        marginRight: 3,
        color:'blue',
        border:"2px solid black"
      }}
    >
      {label}
    </Tag>
  );
};
const DropDown = ({data}) => (
  
  <Select
    mode="multiple"
    tagRender={tagRender}
    
    style={{
      width: '100%',
    }}
    options={data}
  />
);
export default DropDown;