import React, { useState, useEffect } from "react";
import TagInput from "../../../components/TagInput";
import { useDispatch, useSelector } from "react-redux";
import { SEOData } from "../../../utils/BlogSlices/config/SEODocCreatorSlice";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { json } from "react-router-dom";
const SeoDocCreator = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});

  
  const handleConvertToPDF = () => {
    const input = document.getElementById('content-to-convert');

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      pdf.addImage(imgData, 'PNG', 0, 0);      
      pdf.save('converted.pdf');
    });
  };

  // Call downloadAsPDF function with the desired element ID

  const handleInputChange = (field, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
  };
  const primaryKeyword = useSelector((store) => store.PostData.primaryKeyword);
  const secondaryKeyword = useSelector(
    (store) => store.PostData.secondaryKeyword
  );
  useEffect(() => {
    setFormData({
      ...formData,
      secondaryKeyword: secondaryKeyword,
    });
  }, [secondaryKeyword]);
  useEffect(() => {
    setFormData({
      ...formData,
      primaryKeyword: primaryKeyword,
    });
  }, [primaryKeyword]);
  const [title, setTitle] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("formData", formData);
    const obj = {
      title: formData?.title,
      primaryKeyword: formData?.primaryKeyword?.map((item) => ({
        primaryText: item,
      })),
      secondaryKeyword: formData?.secondaryKeyword?.map((item) => ({
        secondaryText: item,
      })),
    };
    console.log("objh", obj);
    dispatch(SEOData(obj));
  };
  return (
    <div id="elementId" className="flex justify-between ">
      <form onSubmit={handleSubmit} className="flex items-center flex-col ">
        <div className="w-full flex justify-between items-center">
          <div id="title" className="p-2">
            <div className="title">Blog Title</div>
          </div>
          <input
            type="text"
            className="p-2 border-2 w-[250px]"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
              handleInputChange("title", e.target.value);
            }}
            placeholder="Title"
          />
        </div>
        <div className="w-full flex my-2 justify-between items-center">
          <div className="title">Primary Keyword</div>
          <TagInput
            type="primary"
            onChange={(e) => {
              setFormData({
                ...formData,
                primaryKeyword: primaryKeyword,
              });
            }}
          />
        </div>
        <div className="w-full flex justify-between items-center">
          <div className="title">Secondary Keyword</div>
          <TagInput
            type="secondary"
            onChange={(e) => {
              setFormData({
                ...formData,
                secondaryKeyword: secondaryKeyword,
              });
            }}
          />
        </div>
        <button  onClick={handleConvertToPDF} className="rounded border-2 py-2 px-4  bg-blue-500 hover:bg-blue-400">
          Download
        </button>

      </form>
      <div className="border-2">
        <div id="content-to-convert" className="h-[247mm] w-[160mm] p-5">
          {/* Your content goes here */}
          <div>
            <p className="font-bold">Title:</p>
            <p>{title}</p>
            <p className="font-bold">Primary Keyword</p>
            <div className="text-wrap h-30  flex">
            <ul className="pl-4" style={{ listStyleType: 'disc' }} >{formData.primaryKeyword?.map((item)=> <li>{item}</li>)}</ul>
            </div>
            <p className="font-bold">Secondary Keyword</p>
            <ul className="pl-4" style={{ listStyleType: 'disc' }} >{formData.secondaryKeyword?.map((item)=> <li>{item}</li>)}</ul>
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default SeoDocCreator;
