// loginSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { REACT_APP_API_URL } from "../../secrets";
const seceretKey = process.env.REACT_APP_BASE_URL
const token =localStorage.getItem('token');
export const userFetch = createAsyncThunk("ROwn/user", async (paginationAndQueryData) => {
    const response = await fetch(`${seceretKey}api/Rown/getUserGroup?Keyword=${paginationAndQueryData.searchQuery}&page=${paginationAndQueryData.currentPage}`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "mode":'no-cors',
            Authorization:token
        }    });
    return await response.json();
});
export const userPatch = createAsyncThunk(
    'R-Own/userPatch',
    async(e, thunkAPI) => {
      try {
        const response = await fetch(`${seceretKey}api/Rown/editUserGroup?creatorID=${e.creatorID}`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization:token
          },
          body: JSON.stringify(e),
        });
        const data = await response.json();
        return data;
      } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    }
  );

const userSlice = createSlice({
    name: "user",
    initialState: {
        userData: null,
        loading: false,
        error: null,
        patchData:null
    },
    extraReducers: (builder) => {
        builder.addCase(userFetch.pending, (state, action) => {
            state.loading = true;
            state.userData = null;
            state.error = null;
        });
        builder.addCase(userFetch.fulfilled, (state, action) => {
            state.loading = false;
            state.userData = action.payload;
            state.error = null;
        });
        builder.addCase(userFetch.rejected, (state, action) => {
            state.loading = false;
            state.userData = null;
            state.error = action.error.message;
        });
        builder.addCase(userPatch.fulfilled, (state, action) => {
            state.loading = false;
            state.patchData = action.payload;
        });
        builder.addCase(userPatch.rejected, (state, action) => {
            state.patchData = null;
            state.loading = false;
            // state.error = action.error.message;
        });
        builder.addCase(userPatch.pending, (state, action) => {
            state.loading = true;
            // state.error = action.error.message;
        });
    }
});

export default userSlice.reducer;
