import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Space, Skeleton, Table } from "antd";
import { propertiesData } from "../../../utils/ProductsSlices/RateX/propertiesSlice";
import Model from "../../../components/Model";
import { propertiePatch } from "../../../utils/ProductsSlices/RateX/propertiesSlice";
import { toast } from "react-toastify";
import { Input } from "antd";
const { Search } = Input;
const RateXProperties = () => {
  const dispatch = useDispatch();
  const params=useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [readOnly, setReadOnly] = useState(true);
  const propertyData = useSelector((store) => store.RateXProperty);
  const access = localStorage.getItem("access");
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const handlePageChange = (page) => {
    setCurrentPage(page);    
  };
  let paginationAndQueryData = {
    searchQuery: searchQuery ? searchQuery : "",
    currentPage: currentPage,
  };

  useEffect(() => {
    paginationAndQueryData = {
      searchQuery: searchQuery ? searchQuery : "",
      currentPage: currentPage,
    };
    dispatch(propertiesData(paginationAndQueryData));
  }, [searchQuery, currentPage]);

  const handleSearch = () => {
    dispatch(propertiesData(paginationAndQueryData));
  };

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };
  function handleEditSubmit(e) {
    dispatch(propertiePatch(e)).then((res) => {
      dispatch(propertiesData(paginationAndQueryData));
      setIsOpen(false);
      if (res.payload.code === 200) {
        toast.success("Updated Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error(res.payload.message);
      }
    });
  }

  const columns = [
    {
      title: "Property Type",
      dataIndex: "propertyType",
      key: "propertyType",
    },
    {
      title: "Property Name",
      dataIndex: "propertyName",
      key: "propertyName",
    },
    {
      title: "City Code",
      dataIndex: "cityCode",
      key: "cityCode",
    },
    {
      title: "Total Rooms",
      key: "totalRooms",
      dataIndex: "totalRooms",
    },
    {
      title: "isRetvens",
      key: "isRetvens",
      render: (_, record) => (
        <Space size="middle">
          <a> {record.isRetvens ? "True" : "False"}</a>
        </Space>
      ),
    },
    {
      title: "city",
      key: "city",
      render: (_, record) => (
        <Space size="middle">
          <a> {record.address.city}</a>
        </Space>
      ),
    },
    {
      title: "View/Edit",
      render: (_, record) => (
        <Space size="middle">
          <p> {}</p>

          <div>
            <a
              className="text-blue-700"
              onClick={() => {
                setSelectedRecord(record);
                handleOpen();
                setReadOnly(true);
              }}
            >
              View
            </a>
            {access == "true" && (
              <a
                className="p-2 text-red-950"
                onClick={() => {
                  setSelectedRecord(record);
                  handleOpen();
                  setReadOnly(false);
                }}
              >
                Edit
              </a>
            )}
          </div>
        </Space>
      ),
      key: "email",
    },
  ];
  useEffect(() => {
    dispatch(propertiesData(paginationAndQueryData));
  }, [dispatch]);
  return (
    <div>
      <Search
        onChange={(e) => {
          setSearchQuery(e.target.value);
          
        }}
        value={searchQuery}
        placeholder="input search text"
        enterButton="Search"
        size="large"
        className="m-2 rounded"
        onSearch={handleSearch}
      />

      {isOpen && (
        <Model
          data={selectedRecord}
          editable={readOnly}
          onSubmitt={handleEditSubmit}
          onClose={() => setIsOpen(false)}
        />
      )}
      {propertyData.loading ? (
        <Skeleton active />
      ) : (
        <Table
          columns={columns}
          pagination={{
            total: propertyData?.propertyData?.totalCount,
            current: currentPage,
            onChange: handlePageChange,
          }}
          dataSource={propertyData?.propertyData?.data}
        />
      )}
    </div>
  );
};

export default RateXProperties;
