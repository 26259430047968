import React,{useEffect,useState} from 'react'
import { useSelector ,useDispatch} from "react-redux";
import { Space, Table,Skeleton } from "antd";
import Model from '../../../components/Model';
import { DesignationFetch ,designationPatch} from '../../../utils/ProductsSlices/ROwn/DesignationSlice';
import { Input } from "antd";
const { Search } = Input;

const DestinationData= () => {
  const dispatch = useDispatch();
  
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [readOnly, setReadOnly] = useState(true);
  const data = useSelector((store) => store.DesignationSlice  );
  const access= localStorage.getItem('access');
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState(""); 
   let paginationAndQueryData = {
      searchQuery: searchQuery ? searchQuery : "",
      currentPage: currentPage,
    };
  
    useEffect(() => {
      paginationAndQueryData = {
        searchQuery: searchQuery ? searchQuery : "",
        currentPage: currentPage,
      };
      dispatch(DesignationFetch(paginationAndQueryData));
    }, [searchQuery, currentPage]);
    const handleSearch = () => {
      dispatch(DesignationFetch(paginationAndQueryData));
    };
     
  
  const handlePageChange = (page) => {
    setCurrentPage(page);
    dispatch(DesignationFetch(paginationAndQueryData))
  };

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };
  function handleEditSubmit(e){
    
    dispatch(designationPatch(e)).then((res) => {
        dispatch(DesignationFetch(paginationAndQueryData));
        setIsOpen(false)
      });
  }

  const columns = [
    {
      title: 'display_status',
      dataIndex: 'display_status',
      key: 'display_status',
      
    },
    {
      title: '_id',
      dataIndex: '_id',
      key: '_id',
    },
    {
      title: 'designation_name',
      dataIndex: 'designation_name',
      key: 'designation_name',
    },
    {
      title: "View/Edit",
      render: (_, record) => (
        <Space size="middle">
          <p> {}</p>

          <div>
            <a className="text-blue-700"
              onClick={() => {
                setSelectedRecord(record);
                handleOpen();
                setReadOnly(true);
              }}
            >
              View
            </a>
            {access=='true' &&  
            <a className="p-2 text-red-950"
              onClick={() => {
                setSelectedRecord(record);
                handleOpen();
                setReadOnly(false);
              }}
            >
              Edit
            </a>}
          </div>
        </Space>
      ),
      key: "email",
    }

  ];
  useEffect(() => {
    dispatch(DesignationFetch(paginationAndQueryData));
  }, [dispatch]);

  
  return (

    <div>
        
 <Search
        onChange={(e) => {
          setSearchQuery(e.target.value);
          
        }}
        value={searchQuery}
        placeholder="input search text"
        enterButton="Search"
        size="large"
        className="m-2 rounded"
        onSearch={handleSearch}
      />

      {isOpen &&( <Model  data={selectedRecord} editable={readOnly}  onSubmitt={handleEditSubmit}  onClose={()=>setIsOpen(false)}/>)}
      { data.loading ? <Skeleton active /> : 
    <Table columns={columns}   
    pagination={{
          total: data?.DestinationData?.totalCount,
          current: currentPage,
          onChange: handlePageChange,
        }} dataSource={data?.DestinationData?.data} />}
    </div>
  )
}

export default DestinationData