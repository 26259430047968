import React,{useEffect,useState} from 'react'
import { propertiesData,propertiesPatch } from '../../../utils/ProductsSlices/HoteIntelligence/PropertiesSlice'
import { useSelector ,useDispatch} from "react-redux";
import { Space, Table,Skeleton } from "antd";
import Model from '../../../components/Model';
const HotelIntelligenceProperties = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [readOnly, setReadOnly] = useState(true);
  const data = useSelector((store) => store.HIpropertys);
  const access= localStorage.getItem('access');
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    dispatch(propertiesData(page))
  };

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };
  
  function handleEditSubmit(e){
    dispatch(propertiesPatch(e)).then((res) => {
      dispatch(propertiesData(currentPage));
      setIsOpen(false)
    })
  }

  const columns = [
    {
      title: 'isActive',
      render: (_, record) => (
        <Space size="middle">
          <p> {record.isActive?"true":"false"}</p>
          
        </Space>
        ),
      key: 'isActive',
      
    },
    {
      title: 'isCorrect',
      render: (_, record) => (
        <Space size="middle">
          <p> {record.isCorrect?"true":"false"}</p>
          
        </Space>
        ),
      key: 'isCorrect',
    },
    {
      title: 'Hotel Code',
      dataIndex: 'hotelCode',
      key: 'hotelCode',
    },
    {
      title: 'cmid',
      key: 'cmid',
      dataIndex: 'cmid',
    },
    {
      key: 'cmcred',
      render: (_, record) => (
        <Space size="middle">
          <p> {record.cmcred.Username}</p>
          
        </Space>
        ),
    },
    {
      title: "View/Edit",
      render: (_, record) => (
        <Space size="middle">

          <div>
            <a className="text-blue-700"
              onClick={() => {
                setSelectedRecord(record);
                handleOpen();
                setReadOnly(true);
              }}
            >
              View
            </a>
            {access=='true' &&
            <a className="p-2 text-red-950"
              onClick={() => {
                setSelectedRecord(record);
                handleOpen();
                setReadOnly(false);
              }}
            >
              Edit
            </a>}
          </div>
        </Space>
      ),
      key: "email",
    }
  ];
  useEffect(() => {
    dispatch(propertiesData(currentPage));
  }, [dispatch]);

  return (
    <div>
      
 {isOpen &&( <Model  data={selectedRecord} editable={readOnly}   onSubmitt={handleEditSubmit}  onClose={()=>setIsOpen(false)}/>)}
 { data.loading ? <Skeleton active /> : 
    <Table columns={columns}   
    pagination={{
          total: data?.propertiesData?.totalCount,
          current: currentPage,
          onChange: handlePageChange,
        }}
     dataSource={data?.propertyData?.data} />}
    </div>
  )
}

export default HotelIntelligenceProperties