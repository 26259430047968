// loginSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
const seceretKey = process.env.REACT_APP_BASE_URL
const token =localStorage.getItem('token');
export const BlogPost = createAsyncThunk(
  "Blog/blogPost",
  async (e, thunkAPI) => {
    try {
      const response = await fetch(
        `${seceretKey}api/adminPannel/addBlog?userId=${e.userId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization:token
          },
          body: JSON.stringify(e),
        }
      );
      const data = await response.json();
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
const BlogSlice = createSlice({
  name: "Dashboard",
  initialState: {
    postData: null,
    loading: false,
    error: null,
    blogPara:[],
  },
  extraReducers: (builder) => {
    builder.addCase(BlogPost.pending, (state, action) => {
      state.loading = true;
      state.postData = null;
      state.error = null;
    });
    builder.addCase(BlogPost.fulfilled, (state, action) => {
      state.loading = false;
      state.postData = action.payload;
      state.error = null;
    });
    builder.addCase(BlogPost.rejected, (state, action) => {
      state.loading = false;
      state.postData = null;
      state.error = action.error.message;
    });
  },
  reducer:{
    addArea(state,action){
      state.blogPara.push(action.payload)
    }
  }
});

export const {addArea}= BlogSlice.actions

export default BlogSlice.reducer;
