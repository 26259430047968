import React,{useState,useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { DomainDetails } from '../../../utils/BlogSlices/DropDownSlice/DropDownSlice';
import { categoriesData } from "../../../utils/Categories/MainCategorieSlice";
import { subcategoriesData } from "../../../utils/Categories/SubCategorieSlice";
import {categoriesDataPost} from '../../../utils/BlogSlices/config/CategoriesSlice';

import { toast } from "react-toastify";
const Categories = () => {
  const dispatch=useDispatch()
  const [img,setImg]=useState();
  const [formData, setFormData]=useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [finalArray, setFinalArray] = useState([]);
  const domaindrop = useSelector((store) => store.dropDownSlice?.Domain?.data);
  const categorieData = useSelector(
    (store) => store.mainCategories.mainCategories?.data
  );
  const subCategorieData = useSelector(
    (store) => store.subCategories.subCategories?.data
  );
  // console.log(domaindrop,"domainDrop")
  useEffect(() => {
    dispatch(categoriesData());
    dispatch(subcategoriesData());
    dispatch(DomainDetails());
    
  }, []);
  const optionsCategories = [];
  categorieData?.map((item) =>
  optionsCategories.push({ value: item.categoryName, label: item.categoryName })
  );
  const optionsSubCategories = [];
  subCategorieData?.map((item) =>
  optionsSubCategories.push({ value: item.subCategoryName, label: item.subCategoryName })
  );



  const handleInputChange = (field, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
  };
  const handleChangeCategories = (selectedItems) => {

    // const labelsArray = selectedItems.map(item => item.label);
    // console.log("Selected Labels:", labelsArray);
    // setFinalArray(labelsArray)
    // setSelectedOptions(selectedItems);
  };
  const handleChangeSubCategories=(selectedItems)=>{
    setFormData()
  }
  const handleSubmit=(e)=>{
    e.preventDefault();
dispatch(categoriesDataPost(formData)).then((res)=>{
  if (res.payload.code === 200) {
    toast.success("Updated Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } else {
    toast.error(res.payload.message);
  }
})
    console.log(formData,"formData")
  }
  return (
    <div> 
      <form onSubmit={handleSubmit}>

       <div className="title flex w-1/3 items-center justify-between">
      <h1>
        Select Image</h1>
    <input
      type="file"
      className="p-2 border-2 w-56"
      onChange={(e) => {
        const file = e.target.files[0];
        setImg(file);
        handleInputChange("categoryImg", file);
      }}
      />
    </div>

    <div className="flex my-4 w-1/3  items-center  justify-between ">
              <div className="title">Categories</div> 
              <Select
      options={optionsCategories}
      isMulti
      value={selectedOptions}
      onChange={(selectedItems)=>{ setFormData((prevFormData) => ({
        ...prevFormData,
        category: selectedItems[0].label,
      }));}}
      className='w-56'
      />
            </div>

         <div className="flex my-4 w-1/3  items-center  justify-between ">
              <div className="title">Category Type</div>
              <Select
                options={optionsSubCategories}
                isMulti
                value={formData.subCategory}
                onChange={(selectedItems)=>{ setFormData((prevFormData) => ({
                  ...prevFormData,
                  subCategory: selectedItems[0].label,
                }));}}
                className='w-56'  
                />
             
            </div>

            <button className='rounded border-2 py-2 px-4  bg-blue-500 hover:bg-blue-400'>
              Submit
            </button>
                </form>
  </div>
  
  )
}

export default Categories