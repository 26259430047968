// loginSlice.js
// import dotenv from 'dotenv'
import {REACT_APP_API_URL} from '../../secrets'
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// const api= process.env.REACT_APP_API_URL;
const seceretKey = process.env.REACT_APP_BASE_URL
const token =localStorage.getItem('token');
export const fetchUser = createAsyncThunk("ratePulse/fetchUser", async (paginationAndQueryData) => {

    const response = await fetch(`${seceretKey}api/rateshopper/getRateShopperUser?Keyword=${paginationAndQueryData.searchQuery}&page=${paginationAndQueryData.currentPage}`,{
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "mode":'no-cors',
             Authorization:token,
        }});
 
    return await response.json();
});
export const patchUser = createAsyncThunk(
    'Ratex/PatchUser',
    async (e, thunkAPI) => {
        
      try {
        const response = await fetch(`${seceretKey}api/rateshopper/editRateShopperUsers?email=${e.email}`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization:token
          },
          body: JSON.stringify(e),
        });
        const data = await response.json();
        return data;
      } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    }
  );
const userSlice = createSlice({
    name: "ratePulseUser",
    initialState: {
        data: [], // Change this to an empty array
        loading: false,
        error: null,
        patchData:null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchUser.pending, (state) => {
                state.loading = true;
                state.error = null;
                
            })
            .addCase(fetchUser.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.data = action.payload;
                // console.log(state.data.data) // Update the data state with the fetched data
            })
            .addCase(fetchUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                
                // console.log(state.error)
            });
            builder.addCase(patchUser.pending, (state, action) => {
                state.patchData = null;
              
            });
            builder.addCase(patchUser.fulfilled, (state, action) => {
                
                state.patchData = action.payload;
            });
            builder.addCase(patchUser.rejected, (state, action) => {
                state.patchData = null;
                // state.error = action.error.message;
            });
    },
});

export default userSlice.reducer;
