import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Space, Table, Skeleton } from "antd";
import {
  ReportData,
  reportPatch,
} from "../../../utils/ProductsSlices/HotelAudit/ReportIssueSlice";
import Model from "../../../components/Model";
import { Input } from "antd";
const { Search } = Input; 

const ReportIssues = () => {
  const dispatch = useDispatch();
  const access= localStorage.getItem('access');

  const data = useSelector((store) => store.HAIssue);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [readOnly, setReadOnly] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  let paginationAndQueryData = {
     searchQuery: searchQuery ? searchQuery : "",
     currentPage: currentPage,
   };
  const handleSearch = () => {
     dispatch(ReportData(paginationAndQueryData));
   };
   useEffect(() => {
     paginationAndQueryData = {
       searchQuery: searchQuery ? searchQuery : "",
       currentPage: currentPage,
     };
     dispatch(ReportData(paginationAndQueryData));
   }, [searchQuery, currentPage]);
 
  const handlePageChange = (page) => {
    setCurrentPage(page);
    dispatch(ReportData(paginationAndQueryData))
  };

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  function handleEditSubmit(e) {
    dispatch(reportPatch(e)).then((res) => {
      dispatch(ReportData(paginationAndQueryData));
      setIsOpen(false);
    });
  }
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Hotel Name",
      dataIndex: "hotelName",
      key: "hotelName",
    },
    {
      title: "Hotel City",
      dataIndex: "hotelCity",
      key: "hotelCity",
    },
    {
      title: "CreatedAt",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "UpdatedAt",
      dataIndex: "updatedAt",
      key: "updatedAt",
    },
    {
      title: "View/Edit",
      render: (_, record) => (
        <Space size="middle">
          <p> {}</p>

          <div>
            <a
              className="text-blue-700"
              onClick={() => {
                setSelectedRecord(record);
                handleOpen();
                setReadOnly(true);
              }}
            >
              View
            </a>
            {access=='true' && (
              <a
                className="p-2 text-red-950"
                onClick={() => {
                  setSelectedRecord(record);
                  handleOpen();
                  setReadOnly(false);
                }}
              >
                Edit
              </a>
            )}
          </div>
        </Space>
      ),
      key: "email",
    },
  ];

  useEffect(() => {
    dispatch(ReportData(paginationAndQueryData));
  }, [dispatch]);
  console.log(data?.data);
  return (
    <div>
      <Search
        onChange={(e) => {
          setSearchQuery(e.target.value);
          
        }}
        value={searchQuery}
        placeholder="input search text"
        enterButton="Search"
        size="large"
        className="m-2 rounded-2xl"
        onSearch={handleSearch}
      />

      {isOpen && (
        <Model
          data={selectedRecord}
          editable={readOnly}
          onSubmitt={handleEditSubmit}
          onClose={()=>setIsOpen(false)}
        />
      )}

      <div className=" text-sm animate-pulse z-50 w-full h-full bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200 rounded-lg" />
      {data.loading ? (
        <Skeleton active />
      ) : (
        <Table columns={columns}   
        
        pagination={{
              total: data?.issue?.totalCount,
              current: currentPage,
              onChange: handlePageChange,
            }} dataSource={data?.issue?.data} />
      )}
    </div>
  );
};

export default ReportIssues;
