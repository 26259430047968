// loginSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
const seceretKey = process.env.REACT_APP_BASE_URL

const token =localStorage.getItem('token');
export const StatusData = createAsyncThunk("HotelAudit/status", async (paginationAndQueryData) => {
    const response = await fetch(`${seceretKey}api/auditReportRoutes/getUserStatus?Keyword=${paginationAndQueryData.searchQuery}&page=${paginationAndQueryData.currentPage}`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "mode":'no-cors',
            Authorization:token,
        }
    });
    return await response.json();
});

const userStatusSlice = createSlice({
    name: "User Status",
    initialState: {
        status: null,
        loading: false,
        error: null
    },
    extraReducers: (builder) => {
        builder.addCase(StatusData.pending, (state, action) => {
            state.loading = true;
            state.status = null;
            state.error = null;
        });
        builder.addCase(StatusData.fulfilled, (state, action) => {
            state.loading = false;
            state.status = action.payload;
            state.error = null;
        });
        builder.addCase(StatusData.rejected, (state, action) => {
            state.loading = false;
            state.status = null;
            state.error = action.error.message;
        });
    }
});

export default userStatusSlice.reducer;
