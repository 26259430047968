// loginSlice.js// loginSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
const seceretKey = process.env.REACT_APP_BASE_URL 
const token =localStorage.getItem('token');
export const coursePost = createAsyncThunk("course/coursePost", async (info) => {
console.log('final data',info)
    
    const response = await fetch(`${seceretKey}api/R-job/addCourse`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            "mode":'no-cors',
        },
        body:JSON.stringify(info)  
    });
    return await response.json();
});
export const modulePost = createAsyncThunk("course/modulePost", async (obj) => {
    // console.log('final data',info)
        
        const response = await fetch(`${seceretKey}api/R-job/addModule?courseId=${obj.id}`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "mode":'no-cors',
            },
            body:JSON.stringify(obj.formData)  
        });
        return await response.json();
    });
    export const contentPost = createAsyncThunk("course/contentPost", async (info) => {
        console.log('final data',info)
            
            const response = await fetch(`${seceretKey}api/R-job/addContent?courseId=${info.courseId}&moduleId=${info.moduleId}`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "mode":'no-cors',
                },
                body:JSON.stringify(info.formData)  
            });
            return await response.json();
        });
export const courseGet = createAsyncThunk("course/courseGet", async (info) => {
    console.log('final data',info)
        
        const response = await fetch(`${seceretKey}api/R-job/getCourse`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "mode":'no-cors',
            },
            body:JSON.stringify(info)  
        });
        return await response.json();
    });
    
    export const moduleGet = createAsyncThunk("module/moduleGet", async (page) => {
        const response = await fetch(`${seceretKey}api/R-job/getModule?courseId=${page}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "mode":'no-cors',
            }
        });
        return await response.json();
    });
    export const contentGet = createAsyncThunk("module/contentGet", async (page) => {
        const response = await fetch(`${seceretKey}api/R-job/getContent?moduleId=${page}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "mode":'no-cors',
            }
        });
        return await response.json();
    });
const courseSlice = createSlice({
    name: "BlogTask",
    initialState: {
        getData: null,
        loading: false,
        error: null,
        Data:null,
        moduleData:null,
        contentData:null,

    },
    extraReducers: (builder) => {
        builder.addCase(courseGet.pending, (state, action) => {
            state.loading = true;
            state.getData = null;
            state.error = null;

        });
        builder.addCase(courseGet.fulfilled, (state, action) => {
            state.loading = false;
            state.getData = action.payload;
            state.error = null;
        });
        builder.addCase(courseGet.rejected, (state, action) => {
            state.loading = false;
            state.getData = null;
            state.error = action.error.message;
        });
        builder.addCase(moduleGet.pending, (state, action) => {
            state.loading = true;
            state.moduleData = null;
            state.error = null;
            console.log("pending")

        });
        builder.addCase(moduleGet.fulfilled, (state, action) => {
            state.loading = false;
            state.moduleData = action.payload;
            state.error = null;
        });
        builder.addCase(moduleGet.rejected, (state, action) => {
            state.loading = false;
            state.moduleData = null;
            state.error = action.error.message;
        });
        builder.addCase(contentGet.pending, (state, action) => {
            state.loading = true;
            state.contentData = null;
            state.error = null;
            console.log("pending")

        });
        builder.addCase(contentGet.fulfilled, (state, action) => {
            state.loading = false;
            state.contentData = action.payload;
            state.error = null;
        });
        builder.addCase(contentGet.rejected, (state, action) => {
            state.loading = false;
            state.contentData = null;
            state.error = action.error.message;
        });
    }
});

export default courseSlice.reducer;
