import React, { useEffect,useState} from 'react'
import { useSelector ,useDispatch} from "react-redux";
import { Space, Table,Skeleton  } from "antd";
import { fetchUser } from '../../../utils/ProductsSlices/RatePulse/RpUserSlice'
import Model from '../../../components/Model';
import { patchUser } from '../../../utils/ProductsSlices/RatePulse/RpUserSlice';
import {  toast } from "react-toastify";
import { Input } from "antd";
const { Search } = Input; 

const RatePulseUsers = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [readOnly, setReadOnly] = useState(true);
  const userdata = useSelector((store) => store.ratePulseUser);
  const access= localStorage.getItem('access');
  const [currentPage, setCurrentPage] = useState(1);
  let paginationAndQueryData = {
    searchQuery: searchQuery ? searchQuery : "",
    currentPage: currentPage,
  };

  useEffect(() => {
    paginationAndQueryData = {
      searchQuery: searchQuery ? searchQuery : "",
      currentPage: currentPage,
    };
    dispatch(fetchUser(paginationAndQueryData));
  }, [searchQuery, currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    dispatch(fetchUser(paginationAndQueryData))
  };
  const handleSearch = () => {
    dispatch(fetchUser(paginationAndQueryData));
  };
  const handleOpen = () => {
    setIsOpen(!isOpen);
  };


  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      
    },
    {
      title: 'Mobile Number',
      dataIndex: 'mobileNumber',
      key: 'mobileNumber',
    },
    {
      title: '_id',
      dataIndex: '_id',
      key: '_id',
    },
    {
      title: 'email',
      key: 'email',
      dataIndex: 'email',
    },
    {
      title: 'Added_by',
      key: 'added_by',
      dataIndex: 'added_by',
    },,
    {
      title: "View/Edit",
      render: (_, record) => (
        <Space size="middle">
          <p> {}</p>

          <div>
            <a className="text-blue-700"
              onClick={() => {
                setSelectedRecord(record);
                handleOpen();
                setReadOnly(true);
              }}
            >
              View
            </a>
            {access=='true'&&  
            <a className="p-2 text-red-950"
              onClick={() => {
                setSelectedRecord(record);
                handleOpen();
                setReadOnly(false);
              }}
            >
              Edit
            </a>}
          </div>
        </Space>
      ),
      key: "email",
    }


  ];
  function handleEditSubmit(e){
    // const { userId, ...dataToUpdate } = e;
    // console.log('dispatch')
    // console.log(dataToUpdate)
    console.log(e);
    dispatch(patchUser(e)).then((res) => {
      dispatch(fetchUser(paginationAndQueryData));
      setIsOpen(false)
      if(res.payload.code===200){
        toast.success('Updated Successfully', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          
          });
        }else{
          toast.error(res.payload.message)
        }
    });; 
  }
  useEffect(() => {
    dispatch(fetchUser(paginationAndQueryData));
  }, [dispatch]);


  return (
    <div>
           <Search
        onChange={(e) => {
          setSearchQuery(e.target.value);
          
        }}
        value={searchQuery}
        placeholder="input search text"
        enterButton="Search"
        size="large"
        className="m-2 rounded"
        onSearch={handleSearch}
      />
      {isOpen &&( <Model  data={selectedRecord} editable={readOnly} onSubmitt={handleEditSubmit}  onClose={()=>setIsOpen(false)} />)}
      { userdata.loading ? <Skeleton active /> : 
      <div>
  

    <Table columns={columns}
     pagination={{
      total: userdata?.data?.totalCount,
      current: currentPage,
      onChange: handlePageChange,
    }}
    dataSource={userdata?.data?.data} />
    
    </div>
  }
    </div>
  );
}

export default RatePulseUsers;
