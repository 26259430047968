import React from "react";
import { Route, Navigate } from "react-router-dom";
import LayoutMain from '../../components/Layout/LayoutStyle'

const LoginPrivate = ({ Component: Component, isAuthenticated, ...rest }) => {
  return isAuthenticated ? (
    <Navigate to="/dashboard" />
  ) : (
    <div>
    <Navigate to="/" />
    <Component/>
    </div>
  );
};

export default LoginPrivate;
