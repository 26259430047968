// loginSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import JobsCategories from "../../../pages/dashboard/Jobs/JobsCategories";
// import {REACT_APP_API_URL} from '../secrets'
const seceretKey = process.env.REACT_APP_BASE_URL
export const Assigned = createAsyncThunk("Blogs/assigned", async (credentials) => {
    const response = await fetch(`${seceretKey}api/adminPannel/getAssignedDetail`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "mode":'no-cors'
        },
        body: JSON.stringify(credentials)
    });
    return await response.json();
});
export const jobProfileGet = createAsyncThunk("jobs/jobProfile", async (credentials) => {
    const response = await fetch(`${seceretKey}api/R-job/getJobProfile`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "mode":'no-cors'
        },
        body: JSON.stringify(credentials)
    });
    return await response.json();
});
export const AssignedTo = createAsyncThunk("Blogs/assignedTo", async (credentials) => {
    const response = await fetch(`${seceretKey}api/adminPannel/getAssignedToDetail`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "mode":'no-cors'
        },
        body: JSON.stringify(credentials)
    });
    return await response.json();
});
export const CourseCategoryGet = createAsyncThunk("course/category", async (credentials) => {
    const response = await fetch(`${seceretKey}api/R-job/getCourseCategory`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "mode":'no-cors'
        },
        body: JSON.stringify(credentials)
    });
    return await response.json();
});
export const jobRemove = createAsyncThunk("Blogs/blogTaskGet", async (credentials) => {
    const response = await fetch(`${seceretKey}api/R-job/editJobisActive?jobId=${credentials}`, {
        method: 'PATCH',
        headers: {
        },
    });
    return await response.json();
});

export const skills = createAsyncThunk("jobs/skills", async (credentials) => {
    const response = await fetch(`${seceretKey}api/R-job/getSkill`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "mode":'no-cors'
        },
        body: JSON.stringify(credentials)
    });
    return await response.json();
});
export const DomainDetails = createAsyncThunk("Blogs/domain", async (credentials) => {
    const response = await fetch(`${seceretKey}api/adminPannel/getDomainDetail`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "mode":'no-cors'
        },
        body: JSON.stringify(credentials)
    });
    return await response.json();
});
export const fetchjobCategory = createAsyncThunk("jobs/jobsCategory", async (credentials) => {
    const response = await fetch(`${seceretKey}api/R-job/getJobCategories`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "mode":'no-cors'
        },
        body: JSON.stringify(credentials)
    });
    return await response.json();
});
export const fetchjobSubCategory = createAsyncThunk("jobs/jobsSubCategory", async (credentials) => {
    const response = await fetch(`${seceretKey}api/R-job/getSubJobCategories`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "mode":'no-cors'
        },
        body: JSON.stringify(credentials)
    });
    return await response.json();
});
const dropDownSlice = createSlice({
    name: "subCategories",
    initialState: {
        assigned: null,
        loading: false,
        error: null,
        assignedTo:null,
        Domain:null,
        jobProfile:null,
        skill:null,
        jobCategory:null,
        jobSubCategory:null,
        courseCategory:null,
    },
    extraReducers: (builder) => {
        builder.addCase(Assigned.pending, (state, action) => {
            state.loading = true;
            state.assigned = null;
            state.error = null;

        });
        builder.addCase(Assigned.fulfilled, (state, action) => {
            state.loading = false;
            state.assigned = action.payload;
            state.error = null;
        });
        builder.addCase(Assigned.rejected, (state, action) => {
            state.loading = false;
            state.assigned = null;
            state.error = action.error.message;
        });
        builder.addCase(AssignedTo.pending, (state, action) => {
            state.loading = true;
            state.assignedTo = null;
            state.error = null;

        });
        builder.addCase(AssignedTo.fulfilled, (state, action) => {
            state.loading = false;
            state.assignedTo = action.payload;
            state.error = null;
        });
        builder.addCase(AssignedTo.rejected, (state, action) => {
            state.loading = false;
            state.assignedTo = null;
            state.error = action.error.message;
        });
        builder.addCase(DomainDetails.pending, (state, action) => {
            state.loading = true;
            state.Domain = null;
            state.error = null;

        });
        builder.addCase(DomainDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.Domain = action.payload;
            state.error = null;
        });
        builder.addCase(DomainDetails.rejected, (state, action) => {
            state.loading = false;
            state.Domain = null;
            state.error = action.error.message;
        });
        builder.addCase(jobProfileGet.pending, (state, action) => {
            state.loading = true;
            state.jobProfile = null;
            state.error = null;

        });
        builder.addCase(jobProfileGet.fulfilled, (state, action) => {
            state.loading = false;
            state.jobProfile = action.payload;
            state.error = null;
        });
        builder.addCase(jobProfileGet.rejected, (state, action) => {
            state.loading = false;
            state.jobProfile = null;
            state.error = action.error.message;
        });
        builder.addCase(skills.pending, (state, action) => {
            state.loading = true;
            state.skill = null;
            state.error = null;

        });
        builder.addCase(skills.fulfilled, (state, action) => {
            state.loading = false;
            state.skill = action.payload;
            state.error = null;
        });
        builder.addCase(skills.rejected, (state, action) => {
            state.loading = false;
            state.skill = null;
            state.error = action.error.message;
        });
        
        builder.addCase(fetchjobCategory.pending, (state, action) => {
            state.loading = true;
            state.jobCategory = null;
            state.error = null;

        });
        builder.addCase(fetchjobCategory.fulfilled, (state, action) => {
            state.loading = false;
            state.jobCategory = action.payload;
            state.error = null;
        });
        builder.addCase(fetchjobCategory.rejected, (state, action) => {
            state.loading = false;
            state.jobCategory = null;
            state.error = action.error.message;
        });

        builder.addCase(fetchjobSubCategory.pending, (state, action) => {
            state.loading = true;
            state.jobSubCategory = null;
            state.error = null;

        });
        builder.addCase(fetchjobSubCategory.fulfilled, (state, action) => {
            state.loading = false;
            state.jobSubCategory = action.payload;
            state.error = null;
        });
        builder.addCase(fetchjobSubCategory.rejected, (state, action) => {
            state.loading = false;
            state.jobSubCategory = null;
            state.error = action.error.message;
        });
        builder.addCase(CourseCategoryGet.pending, (state, action) => {
            state.loading = true;
            state.courseCategory = null;
            state.error = null;

        });
        builder.addCase(CourseCategoryGet.fulfilled, (state, action) => {
            state.loading = false;
            state.courseCategory = action.payload;
            state.error = null;
        });
        builder.addCase(CourseCategoryGet.rejected, (state, action) => {
            state.loading = false;
            state.courseCategory = null;
            state.error = action.error.message;
        });
    }
});

export default dropDownSlice.reducer;
