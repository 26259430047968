import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import TagInput from "../../../components/TagInput";
import ReactTable from "react-table";
import Model from "../../../components/Model";
import {
  jobPost,
  jobDetailGet,
} from "../../../utils/ProductsSlices/jobSlice/jobDetail";
import { Skeleton } from "antd";

import { Space, Table } from "antd";

import { toast } from "react-toastify";
import {
  jobProfileGet,
  skills,
  jobRemove,
} from "../../../utils/BlogSlices/DropDownSlice/DropDownSlice";
import { Radio } from "antd";

const JobDetail = () => {
  const [selectedOptions, setSelectedOptions] = useState();
  const [opportunity, setOpportunity] = useState("");
  const [isReferal, setIsReferal] = useState("");

  const [internshipDuration, setInternshipDuration] = useState();
  const [numberOfOpening, setNumberOfOpening] = useState();
  const [title, setTitle] = useState("");
  const [des, setDes] = useState();
  const [company, setCompany] = useState();
  const [designation, setDesignation] = useState();
  const [city, setCity] = useState();
  const [selectedExperience, setSelectedExperience] = useState();
  const [stipend, setStipend] = useState();
  const [jobType, setJobType] = useState();
  const [companyLink,setCompanyLink]=useState();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(jobProfileGet());
    dispatch(jobDetailGet());

    dispatch(skills());
  }, []);
  const columns = [
    {
      title: "jobId",
      dataIndex: "jobId",
      key: "jobId",
    },{
      title: "jobTitle",
      dataIndex: "jobTitle",
      key: "jobTitle",
    },{
      title: "experienceRequired",
      dataIndex: "experienceRequired",
      key: "experienceRequired",
    },{
      title: "jobDescription",
      dataIndex: "jobDescription",
      key: "jobDescription",
    },
    {
      title: "opportunityType",
      dataIndex: "opportunityType",
      key: "opportunityType",
    },
    {
      title: "jobProfile",
      dataIndex: "jobProfile",
      key: "jobProfile",
    },
    {
      title: "jobType",
      key: "jobType",
      dataIndex: "jobType",
    },
    {
      title: "numberOfOpening",
      key: "numberOfOpening",
      dataIndex: "numberOfOpening",
    },
    ,
    {
      title: "Action",
      render: (_, record) => (
        <Space size="middle">
          {console.log(record, "record")}
          <div>
            <a
              className="text-blue-700"
              onClick={() => {
                dispatch(jobRemove(record.jobId));
              }}
            >
              Remove
            </a>
          </div>
        </Space>
      ),
    },

    // },
    // {
    //   title: "is_verified",
    //   key: "is_verified",
    //   render: (_, record) => (
    //     <Space size="middle">
    //       <p> {record.is_verified ? "true" : "false"}</p>
    //     </Space>
    //   ),
    // },
    // {
    //   title: "is_email_Verified",
    //   key: "is_email_Verified",
    //   render: (_, record) => (
    //     <Space size="middle">
    //       <p> {record.is_email_Verified ? "true" : "false"}</p>
    //     </Space>
    //   ),
    // },
    // {
    //   title: "View/Edit",
    //   render: (_, record) => (
    //     <Space size="middle">

    //       <div>
    //         <a
    //           className="text-blue-700"
    //           onClick={() => {

    //           }}
    //         >
    //           View
    //         </a>

    //       </div>
    //     </Space>
    //   ),
    //   key: "email",
    // },
  ];
  //   const assigned = useSelector((store) => store.dropDownSlice?.assigned?.data);
  const domaindrop = useSelector((store) => store.dropDownSlice?.Domain?.data);
  const skillData = useSelector((store) => store.dropDownSlice?.skill?.data);
  const jobProfileData = useSelector(
    (store) => store.dropDownSlice?.jobProfile?.data
  );
  const blogData = useSelector((store) => store.BlogTask);
  const jobTable = useSelector((store) => store.jobDetails);
  const [imageErrors, setImageErrors] = useState([]);

  const handleImageError = (index) => {
    setImageErrors((prevErrors) => [...prevErrors, index]);
  };
  const [finalArray, setFinalArray] = useState([]);

  const [isOpen, setIsOpen] = useState(false);
  const Domain = useSelector((store) => store.PostData.domain);
  const [handleAddTask, setHanddleAddTask] = useState(false);

  const [jobProfile, setJobProfile] = useState();

  const handleInputChange = (field, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
  };
  const [formData, setFormData] = useState({});

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      skill: finalArray?.map((d) => ({ skillRequired: d })),
    }));
  }, [finalArray]);
  const handleSubmit = (e) => {
    e.preventDefault();
    setHanddleAddTask(false);

    dispatch(jobPost(formData)).then((res) => {
    
      if (res.payload.code === 200) {
        dispatch(jobProfileGet());
        toast.success("Uploaded Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error(res.payload.message);
      }
    });
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };
  const options = [];
  skillData?.map((item) =>
    options.push({ value: item.skill, label: item.skill })
  );
  console.log(formData)
  const experienceOption = [
    { Value: "0-1 Years", label: "0-1 Years" },
    { Value: "1-3 Years", label: "1-3 Years" },
    { Value: "3-5 Years", label: "3-5 Years" },
    { Value: "5-8 Years", label: "5-8 Years" },
    { Value: "8-10 Years", label: "8-10 Years" },
    { Value: "10+ Years", label: "10+ Years" },
  ];

  const handleChange = (selectedItems) => {
    const labelsArray = selectedItems?.map((item) => item.label);
    // console.log("Selected Labels:", labelsArray);
    setFinalArray(labelsArray);
    setSelectedOptions(selectedItems);
  };
  const handleExperience = (selectedItems) => {
    setSelectedExperience(selectedItems);
    handleInputChange("experienceRequired", selectedItems.Value);
  };
  console.log(formData);
  const onChange = (e) => {
    setOpportunity(e.target.value);
    handleInputChange("opportunityType", e.target.value);
  };

  const handleIsReferal = (e) => {
    setIsReferal(e.target.value);
    handleInputChange("isReferal", e.target.value);
  };
  //   console.log(formData, "BlogData");

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="flex w-full justify-between">
        <div className="title p-2">Job Details</div>
        <div
          onClick={() => {
            setHanddleAddTask(!handleAddTask);
          }}
          className="p-2 bg-blue-700 text-white cursor-pointer "
        >
          {handleAddTask ? "See Jobs" : "Add Jobs"}
        </div>
      </div>
      {handleAddTask ? (
        <div className=" border-2   flex rounded flex-col   w-1/3 ">
          <div className="bg-slate-700 h-8 flex items-center pl-2 rounded-b-none text-white">
            <h1>Upload Job Description</h1>
          </div>
          <form
            onSubmit={handleSubmit}
            className="p-2"
            onKeyDown={handleKeyDown}
          >
            <div className="flex my-4  items-center justify-between ">
              <div>Opportunity Type</div>
              <Radio.Group
                className="flex w-56  "
                onChange={onChange}
                value={opportunity}
              >
                <Radio value={"Internship"}>Internship</Radio>
                <Radio value={"Job"}>Job</Radio>
              </Radio.Group>
            </div>
            <div className="flex my-4   items-center justify-between">
              <div className="title">Company</div>
              <input
                type="text"
                className="p-2 border-2 w-56"
                value={company}
                onChange={(e) => {
                  setCompany(e.target.value);
                  handleInputChange("companyName", e.target.value);
                }}
                placeholder="Company Name"
              />
            </div>
            <div className="flex my-4  items-center justify-between ">
              <div>Is Referal</div>
              <Radio.Group
                className="flex w-56  "
                onChange={handleIsReferal}
                value={isReferal}
              >
                <Radio value={"true"}>True</Radio>
                <Radio value={"false"}>False</Radio>
              </Radio.Group>
            </div>
            <div className="flex my-4   items-center justify-between">
              <div className="title">Company Link</div>
              <input
                type="text"
                className="p-2 border-2 w-56"
                value={companyLink}
                onChange={(e) => {
                  setCompanyLink(e.target.value);
                  handleInputChange("companyLink", e.target.value);
                }}
                placeholder="Company Link"
              />
            </div>
            <div className="flex my-4  p items-center justify-between">
              <div className="title">Job Profile</div>
              <select
                id="selectOption"
                className="w-56 p-2 align-middle border-2"
                value={jobProfile}
                onChange={(e) => {
                  setJobProfile(e.target.value);
                  handleInputChange("jobProfile", e.target.value);
                }}
              >
                <option value="">Select...</option>
                {jobProfileData?.map((item) => (
                  <option key={item.assignedToId} value={item.id}>
                    {item.jobProfile}
                  </option>
                ))}
              </select>
            </div>

            <div className="flex my-4   items-center justify-between">
              <div className="title">Job title</div>
              <input
                type="text"
                className="p-2 border-2 w-56"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                  handleInputChange("jobTitle", e.target.value);
                }}
                placeholder="Title"
              />
            </div>
            <div className="flex my-4   items-center justify-between">
              <div className="title">Job city</div>
              <input
                type="text"
                className="p-2 border-2 w-56"
                value={city}
                onChange={(e) => {
                  setCity(e.target.value);
                  handleInputChange("city", e.target.value);
                }}
                placeholder="city"
              />
            </div>
            <div className="flex my-4   items-center justify-between">
              <div className="title">Job Description</div>
              <textarea
                type="password"
                className="p-2 border-2 w-56"
                value={des}
                onChange={(e) => {
                  setDes(e.target.value);
                  handleInputChange("jobDescription", e.target.value);
                }}
                placeholder="Description"
              />
            </div>
            <div className="flex my-4   items-center justify-between">
              <div className="title">Designation</div>
              <input
                type="text"
                className="p-2 border-2 w-56"
                value={designation}
                onChange={(e) => {
                  setDesignation(e.target.value);
                  handleInputChange("designation", e.target.value);
                }}
                placeholder="Designation"
              />
            </div>
            <div className="flex my-4 items-center  justify-between gap-5">
              <div className="title">Skills Required</div>
              <Select
                className="w-56"
                options={options}
                isMulti
                value={selectedOptions}
                onChange={handleChange}
              />
            </div>
            <div className="flex my-4 items-center  justify-between gap-5">
              <div className="title">Experience Required</div>
              <Select
                className="w-56"
                options={experienceOption}
    
                value={selectedExperience}
                onChange={handleExperience}
              />
            </div>
            <div className="flex my-4   items-center justify-between">
              <div className="title">Number of Opening</div>
              <input
                type="text"
                className="p-2 border-2 w-56"
                value={numberOfOpening}
                onChange={(e) => {
                  setNumberOfOpening(e.target.value);
                  handleInputChange("numberOfOpening", e.target.value);
                }}
                placeholder="Enter number of opening"
              />
            </div>

            {opportunity === "Internship" && (
              <div className="flex my-4   items-center justify-between">
                <div className="title">Internship Duration</div>
                <input
                  type="text"
                  className="p-2 border-2 w-56"
                  value={internshipDuration}
                  onChange={(e) => {
                    setInternshipDuration(e.target.value);
                    handleInputChange("internshipDuration", e.target.value);
                  }}
                  placeholder="Internship Duration"
                />
              </div>
            )}

            <div className="flex my-4 items-center justify-between">
              <div>Job Type</div>
              <Radio.Group
                size="small"
                className="w-56 "
                onChange={(e) => {
                  setJobType(e.target.value);
                  handleInputChange("jobType", e.target.value);
                }}
                value={jobType}
              >
                <Radio value={"onsite"}>On-site</Radio>
                <Radio value={"Hybrid"}>Hybrid</Radio>
                <Radio value={"Remote"}>Remote</Radio>
              </Radio.Group>
            </div>
            <div className="flex my-4   items-center justify-between">
              <div className="title">
                {opportunity === "Internship" ? "Stipend" : "Salary"}
              </div>
              <input
                type="text"
                className="p-2 border-2 w-56"
                value={stipend}
                onChange={(e) => {
                  setStipend(e.target.value);
                  handleInputChange("stipend", e.target.value);
                }}
                placeholder="Title"
              />
            </div>
            <div className="w-full font-medium text-slate-50 p-2 cursor-pointer bg-blue-400 flex justify-center rounded">
              <button onClick={() => {}} className="w-full">
                Submit
              </button>
            </div>
          </form>
        </div>
      ) : (
        <div className="w-full border-2 my-4">
          <Table
            columns={columns}
            pagination={
              {
                // total: userData?.userData?.totalCount,
                // current: currentPage,
                // onChange: handlePageChange,
              }
            }
            dataSource={jobTable?.Data?.data}
          />
        </div>
      )}
    </div>
  );
};

export default JobDetail;
