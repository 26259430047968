import React,{useEffect,useState} from 'react'
import { useSelector ,useDispatch} from "react-redux";
import { Space, Table,Skeleton} from "antd";
import { userFetch, userPatch } from '../../../utils/ProductsSlices/ROwn/UserSlice';
import Model from '../../../components/Model';
import { toast } from 'react-toastify';
import { Input } from "antd";
const { Search } = Input;
const ROwnUsers = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [readOnly, setReadOnly] = useState(true);
  const data = useSelector((store) => store.ROwnSliceUser);
  const access= localStorage.getItem('access');
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState(""); 
   let paginationAndQueryData = {
      searchQuery: searchQuery ? searchQuery : "",
      currentPage: currentPage,
    };
  
    useEffect(() => {
      paginationAndQueryData = {
        searchQuery: searchQuery ? searchQuery : "",
        currentPage: currentPage,
      };
      dispatch(userFetch(paginationAndQueryData));
    }, [searchQuery, currentPage]);
    const handleSearch = () => {
      dispatch(userFetch(paginationAndQueryData));
    };
     
  const handlePageChange = (page) => {
    setCurrentPage(page);
    dispatch(userFetch(paginationAndQueryData))
  };

  useEffect(() => {
    dispatch(userFetch(paginationAndQueryData));
  }, [dispatch]);
  function handleEditSubmit(e){
    dispatch(userPatch(e)).then((res) => {
      dispatch(userFetch(currentPage));
      setIsOpen(false)
      if(res.payload.code===200){
        toast.success('Updated Successfully', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          
          });
        }else{
          toast.error(res.payload.message)
        }
    });;
  }
    
  const handleOpen = () => {
    setIsOpen(!isOpen);
  };
  const columns = [
    {
      title: 'Creator Name',
      dataIndex: 'creator_name',
      key: 'creator_name',
      
    },
    {
      title: 'Group name',
      dataIndex: 'group_name',
      key: 'group_name',
    },
    {
      title: 'CreatorID',
      dataIndex: 'creatorID',
      key: 'creatorID',
    },
    {
      title: 'Description',
      key: 'description',
      dataIndex: 'description',
    },
    {
      title: 'Location',
      key: 'location',
      dataIndex: 'location',
    },
    {
      title: "View/Edit",
      render: (_, record) => (
        <Space size="middle">
          <p> {}</p>

          <div>
            <a className="text-blue-700"
              onClick={() => {
                setSelectedRecord(record);
                handleOpen();
                setReadOnly(true);
              }}
            >
              View
            </a>
            {access=='true' &&  
            <a className="p-2 text-red-950"
              onClick={() => {
                setSelectedRecord(record);
                handleOpen();
                setReadOnly(false);
              }}
            >
              Edit
            </a>}
          </div>
        </Space>
      ),
      key: "email",
    }

  ];
  return (
<div>
<Search
        onChange={(e) => {
          setSearchQuery(e.target.value);
          
        }}
        value={searchQuery}
        placeholder="input search text"
        enterButton="Search"
        size="large"
        className="m-2 rounded"
        onSearch={handleSearch}
      />
      {isOpen &&( <Model  data={selectedRecord} editable={readOnly}  onSubmitt={handleEditSubmit} onClose={()=>setIsOpen(false)}/>)}
      { data.loading ? <Skeleton active /> :
         <Table columns={columns}   
         pagination={{
               total: data?.userData?.totalCount,
               current: currentPage,
               onChange: handlePageChange,
             }} dataSource={data?.userData?.data} />}
         </div>
  )
}

export default ROwnUsers