import { configureStore } from "@reduxjs/toolkit";
import userSlices from "./userSlices";
import RpUserSlice from "./ProductsSlices/RatePulse/RpUserSlice";
import loginSlice from './loginSlice'
import RpPropertiesSlice from "./ProductsSlices/RatePulse/RpPropertiesSlice";
import SubscriberSlice from "./ProductsSlices/RateX/SubscriberSlice";
import propertyData from '../utils/ProductsSlices/RateX/propertiesSlice'
import RXuserData from '../utils/ProductsSlices/RateX/UserSlice'
import CRMUserSlice from '../utils/ProductsSlices/CRM/UserSlice'
import HIUser from '../utils/ProductsSlices/HoteIntelligence/UserSlice'
import HIproperty from '../utils/ProductsSlices/HoteIntelligence/PropertiesSlice'
import HAuser from '../utils/ProductsSlices/HotelAudit/UserSlice'
import HApayment from '../utils/ProductsSlices/HotelAudit/PaymentSlice'
import HAUserStatus from '../utils/ProductsSlices/HotelAudit/UserStatus'
import Auth from '../utils/authSlice'
import HAReportIssue from '../utils/ProductsSlices/HotelAudit/ReportIssueSlice'
import PostData from '../utils/BlogSlices/PostDetailSlice'
import MainCategorieSlice from "./Categories/MainCategorieSlice";
import SubCategorieSlice from "./Categories/SubCategorieSlice";
import RownSliceuser from './ProductsSlices/ROwn/UserSlice'
import RownVarification from './ProductsSlices/ROwn/RVarificationSlice'
import RspottedBugSlice from "./ProductsSlices/ROwn/RspottedBugSlice";
import getServiceSlice from "./ProductsSlices/ROwn/getServiceSlice";
import varificationSlice from './ProductsSlices/ROwn/ServiceName'
import profileSlice from './ProductsSlices/ROwn/ProfileSlice'
import jobRequestSlice from './ProductsSlices/ROwn/jobRequest'
import interestDataSlice from'./ProductsSlices/ROwn/InterestDetailsSlice'
import hotelDataSlice from './ProductsSlices/ROwn/HotelDetails'
import FAQSlice from './ProductsSlices/ROwn/FAQSlice'
import DesignationSlice from './ProductsSlices/ROwn/DesignationSlice'
import CompanieSlice from './ProductsSlices/ROwn/CompaniesSlice'
import BlogSlice from "./BlogSlices/Blog/BlogSlice";
import settingSlice from "./settingSlice";
import PostDetailSlice from "../utils/BlogSlices/PostDetailSlice";
import dropDownSlice from "./BlogSlices/DropDownSlice/DropDownSlice";
import BlogTask from './BlogSlices/Blog/BlogTaskSlice'
import SEOData  from "./BlogSlices/config/SEODocCreatorSlice";
import DomainSlice from "./BlogSlices/config/DomainSlice";
import CategoriesSlice from '../utils/BlogSlices/config/CategoriesSlice'
import jobDetailsSlice from '../utils/ProductsSlices/jobSlice/jobDetail';
import CourseSlice from "./ProductsSlices/CourseSlice/CourseSlice";
const store= configureStore({
reducer:{
    user:userSlices,
    loginData:loginSlice,
    ratePulseUser:RpUserSlice,
    ratePulseProperty:RpPropertiesSlice,
    RateXSubscriber:SubscriberSlice,
    RateXProperty:propertyData,
    RateXuser:RXuserData,
    CRMuser:CRMUserSlice,
    HIUsers:HIUser,
    HIpropertys:HIproperty,
    HAusers:HAuser,
    HApayments:HApayment,
    Auth:Auth,
    HAIssue:HAReportIssue,
    HAUserStatus:HAUserStatus,
    PostData:PostData,
    mainCategories:MainCategorieSlice,
    subCategories:SubCategorieSlice,
    ROwnSliceUser:RownSliceuser,
    RownVarification:RownVarification,
    RspottedBugSlice:RspottedBugSlice,
    getServiceSlice:getServiceSlice,
    varificationSlice:varificationSlice,
    profileSlice:profileSlice,
    jobRequestSlice:jobRequestSlice,
    interestDataSlice:interestDataSlice,
    hotelDataSlice:hotelDataSlice,
    FAQSlice:FAQSlice,
    DesignationSlice:DesignationSlice,
    CompanieSlice:CompanieSlice,
    BlogSlice:BlogSlice,
    settingSlice:settingSlice,
    PostDetailSlice:PostDetailSlice,
    dropDownSlice:dropDownSlice,
    BlogTask:BlogTask,
    SEOData:SEOData,
    DomainSlice:DomainSlice,
    CategoriesSlice:CategoriesSlice,
    jobDetails: jobDetailsSlice,
    CourseSlice:CourseSlice
},
})

export default store;