import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import TextEditor from "../../components/customEditor";
import { CourseCategoryGet } from "../../utils/BlogSlices/DropDownSlice/DropDownSlice";
import Select from "react-select";
import {
  coursePost,
  courseGet,
  modulePost,
  moduleGet,
  contentPost,
  contentGet,
} from "../../utils/ProductsSlices/CourseSlice/CourseSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { categoriesData } from "../../utils/Categories/MainCategorieSlice";
const Courses = () => {
  const [handleAddCourse, setHandleAddCourse] = useState(false);
  const [courseName, setCourseName] = useState("");
  const [formData, setFormData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const [price, setPrice] = useState();
  const [moduleName, setModuleName] = useState("");
  const [lessonCount, setLessonCount] = useState();
  const [hourCount, setHourCount] = useState();
  const [record, setRecord] = useState();
  const [videoUrl, setVideoUrl] = useState();
  const [contentName, setContentName] = useState();
  const [contentDesc, setContentDesc] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [textAreas, setTextAreas] = useState([
    {
      contentType: "",
      contentImage: null,
      contentImageText: "",
    },
  ]);
  const dispatch = useDispatch();
  const [course, setCourse] = useState(true);
  const [module, setModule] = useState(false);
  const [content, setContent] = useState(false);
  useEffect(() => {
    setFormData({
      ...formData,
      contentDescription: textAreas,
    });
  }, [textAreas]);
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(coursePost(formData)).then((result) => {
      if (result.payload) {
        if (result.payload.code === 200) {
          toast.success("Login Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });

          setHandleAddCourse(!handleAddCourse);
          dispatch(courseGet());
        } else {
          toast.error(result.payload.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      }
    });
    setCourseName("");
    setPrice("");
  };

  const handleModuleSubmit = (e) => {
    e.preventDefault();
    const obj = {
      id: record.courseId,
      formData: formData,
    };
    dispatch(modulePost(obj)).then((result) => {
      if (result.payload) {
        if (result.payload.code === 200) {
          toast.success("Login Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });

          setHandleAddCourse(!handleAddCourse);
          dispatch(moduleGet(obj.id));
        } else {
          toast.error(result.payload.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      }
    });
    setModuleName("");
    setLessonCount("");
    setHourCount("");
  };

  const contentSubmit = (e) => {
    e.preventDefault();
    const obj = {
      courseId: record.courseId,
      moduleId: record.moduleId,
      formData: formData,
    };
    dispatch(contentPost(obj)).then((result) => {
      if (result.payload) {
        if (result.payload.code === 200) {
          toast.success("Login Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });

          setHandleAddCourse(!handleAddCourse);
          dispatch(contentGet(obj.moduleId));
        } else {
          toast.error(result.payload.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      }
    });
    setContentName("");
    setContentDesc("");
    setImageUrl("");
    setVideoUrl(" ");
  };
  const handleCategory = (selectedItems) => {
    setSelectedCategory(selectedItems);

    handleInputChange("courseCategory", selectedItems.value);
  };
  useEffect(() => {
    dispatch(CourseCategoryGet());
    dispatch(courseGet());
  }, []);

  const categorieData = useSelector(
    (store) => store.dropDownSlice?.courseCategory?.data
  );
  const courseData = useSelector((store) => store.CourseSlice?.getData?.data);
  const moduleData = useSelector(
    (store) => store.CourseSlice?.moduleData?.data
  );
  const contentData = useSelector(
    (store) => store.CourseSlice?.contentData?.data
  );

  // console.log(categorieData, "categorieData");
  const optionsCategories = [];
  categorieData?.map((item) => {
    optionsCategories.push({
      value: item.courseCategoryName,
      label: item.courseCategoryName,
      id: item.jobCategoriesId,
    });
  });
  // console.log(formData, "formData");
  const handleView = (record) => {
    if (course) {
      setModule(true);
      setCourse(false);
      // setContent(false);
      console.log("You are inside Module now");
      dispatch(moduleGet(record.courseId));
    } else if (module) {
      setModule(false);
      setCourse(false);
      setContent(true);
      console.log(record, "recorddddddddddd");
      dispatch(contentGet(record.moduleId));
    } else if (content) {
    }
    setRecord(record);
  };
  const handleBack = () => {
    if (course) {
    } else if (module) {
      setModule(false);
      setCourse(true);
      setContent(false);
    } else if (content) {
      setModule(true);
      setCourse(false);
      setContent(false);
    }
  };
  // console.log(record,"recorddddddddddddddddd")
  const handleInputChange = (field, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
  };
  function handleEditSubmit(e, index) {
    // handleInputChange("contentDescription", e);
    setTextAreas(e);
  }
  // console.log(,"textAreas")
  const columns = [
    {
      title: "courseId",
      dataIndex: "courseId",
      key: "courseId",
    },
    {
      title: "courseName",
      dataIndex: "courseName",
      key: "courseName",
    },
    {
      title: "category",
      dataIndex: "courseCategory",
      key: "category",
    },

    {
      title: "price",
      dataIndex: "coursePrice",
      key: "price",
    },

    {
      title: "Action",
      key: "Action",
      render: (_, record) => (
        <div>
          <a
            onClick={() => {
              handleView(record);
            }}
          >
            view
          </a>
        </div>
      ),
    },
  ];
  const moduleColums = [
    {
      title: "courseId",
      dataIndex: "courseId",
      key: "courseId",
    },
    {
      title: "moduleId",
      dataIndex: "moduleId",
      key: "moduleId",
    },
    {
      title: "moduleName",
      dataIndex: "moduleName",
      key: "moduleName",
    },

    {
      title: "lessonCount",
      dataIndex: "lessonCount",
      key: "lessonCount",
    },
    {
      title: "hourCount",
      dataIndex: "hoursCount",
      key: "hourCount",
    },
    {
      title: "Action",
      key: "Action",
      render: (_, record) => (
        <div>
          <a
            onClick={() => {
              handleView(record);
            }}
          >
            {" "}
            view
          </a>
        </div>
      ),
    },
  ];
  const contentColums = [
    {
      title: "courseId",
      dataIndex: "courseId",
      key: "courseId",
    },
    {
      title: "moduleId",
      dataIndex: "moduleId",
      key: "moduleId",
    },
    {
      title: "contentId",
      dataIndex: "contentId",
      key: "contentId",
    },

    {
      title: "contentName",
      dataIndex: "contentName",
      key: "contentName",
    },
    {
      title: "contentDescription",
      dataIndex: "contentDescription",
      key: "contentDescription",
    },
    {
      title: "contentImage",
      dataIndex: "contentImage",
      key: "contentImage",
    },
    {
      title: "videoLink",
      dataIndex: "videoLink",
      key: "videoLink",
    },
  ];
  console.log(formData);
  return (
    <div>
      {course && (
        <div className="flex flex-col items-center justify-center">
          <div className="flex w-full justify-between">
            <div className="flex">
              <div onClick={handleBack} className="title p-2">
                course Details
              </div>
            </div>
            <div
              onClick={() => {
                setHandleAddCourse(!handleAddCourse);
              }}
              className="p-2 bg-blue-700 text-white cursor-pointer "
            >
              {handleAddCourse ? "See Courses" : "Add Courses"}
            </div>
          </div>
          {handleAddCourse ? (
            <div className=" border-2   flex rounded flex-col   w-1/3 ">
              <div className="bg-slate-700 h-8 flex items-center pl-2 rounded-b-none text-white">
                <h1>Upload Job Description</h1>
              </div>
              <form className="p-2" onSubmit={""}>
                <div className="flex my-4   items-center justify-between">
                  <div className="title">Course Name</div>
                  <input
                    type="text"
                    className="p-2 border-2 w-56"
                    value={courseName}
                    onChange={(e) => {
                      setCourseName(e.target.value);
                      handleInputChange("courseName", e.target.value);
                    }}
                    placeholder="Course Name"
                  />
                </div>
                <div className="flex my-4 items-center  justify-between gap-5">
                  <div className="title">Category</div>
                  <Select
                    className="w-56"
                    options={optionsCategories}
                    value={selectedCategory}
                    onChange={handleCategory}
                  />
                </div>
                <div className="flex my-4   items-center justify-between">
                  <div className="title">Price</div>
                  <input
                    type="text"
                    className="p-2 border-2 w-56"
                    value={price}
                    onChange={(e) => {
                      setPrice(e.target.value);
                      handleInputChange("coursePrice", e.target.value);
                    }}
                    placeholder="Price"
                  />
                </div>
                <div className="w-full font-medium text-slate-50 p-2 cursor-pointer bg-blue-400 flex justify-center rounded">
                  <button onClick={handleSubmit} className="w-full">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          ) : (
            <div className="w-full my-4">
              <Table dataSource={courseData} columns={columns} />;
            </div>
          )}
        </div>
      )}
      {module && (
        <div className="flex flex-col items-center justify-center">
          <div className="flex w-full justify-between">
            <div className="flex">
              {!handleAddCourse && (
                <div
                  onClick={handleBack}
                  className="title p-2 bg-blue-700 text-white cursor-pointer "
                >
                  Back
                </div>
              )}
              <div className="title p-2">Module Details</div>
            </div>
            <div
              onClick={() => {
                setHandleAddCourse(!handleAddCourse);
              }}
              className="p-2 bg-blue-700 text-white cursor-pointer "
            >
              {handleAddCourse ? "See Modules" : "Add Modules"}
            </div>
          </div>
          {handleAddCourse ? (
            <div className=" border-2   flex rounded flex-col   w-1/3 ">
              <div className="bg-slate-700 h-8 flex items-center pl-2 rounded-b-none text-white">
                <h1>Upload Module Description</h1>
              </div>
              <form className="p-2" onSubmit={""}>
                <div className="flex my-4   items-center justify-between">
                  <div className="title">Module Name</div>
                  <input
                    type="text"
                    className="p-2 border-2 w-56"
                    value={moduleName}
                    onChange={(e) => {
                      setModuleName(e.target.value);
                      handleInputChange("moduleName", e.target.value);
                    }}
                    placeholder="Course Name"
                  />
                </div>
                <div className="flex my-4   items-center justify-between">
                  <div className="title">Lesson count</div>
                  <input
                    type="text"
                    className="p-2 border-2 w-56"
                    value={lessonCount}
                    onChange={(e) => {
                      setLessonCount(e.target.value);
                      handleInputChange("lessonCount", e.target.value);
                    }}
                    placeholder="Lesson Count"
                  />
                </div>
                <div className="flex my-4   items-center justify-between">
                  <div className="title">Hour count</div>
                  <input
                    type="text"
                    className="p-2 border-2 w-56"
                    value={hourCount}
                    onChange={(e) => {
                      setHourCount(e.target.value);
                      handleInputChange("hoursCount", e.target.value);
                    }}
                    placeholder="hour Count"
                  />
                </div>
                <div className="w-full font-medium text-slate-50 p-2 cursor-pointer bg-blue-400 flex justify-center rounded">
                  <button onClick={handleModuleSubmit} className="w-full">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          ) : (
            <div className="w-full my-4">
              <Table dataSource={moduleData} columns={moduleColums} />;
            </div>
          )}
        </div>
      )}

      {content && (
        <div className="flex flex-col items-center justify-center">
          <div className="flex w-full justify-between">
            <div className="flex">
              {!handleAddCourse && (
                <div
                  onClick={handleBack}
                  className="title p-2 bg-blue-400 cursor-pointer rounded"
                >
                  Back
                </div>
              )}
              <div className="title p-2">Content Details</div>
            </div>
            <div
              onClick={() => {
                setHandleAddCourse(!handleAddCourse);
              }}
              className="p-2 bg-blue-700 text-white cursor-pointer "
            >
              {handleAddCourse ? "See Content" : "Add Content"}
            </div>
          </div>
          {handleAddCourse ? (
            <div className=" border-2   flex rounded flex-col   w-1/2 ">
              <div className="bg-slate-700 h-8 flex items-center pl-2 rounded-b-none text-white">
                <h1>Upload Content Description</h1>
              </div>
              <form className="p-2" onSubmit={""}>
                <div className="flex my-4   items-center justify-between">
                  <div className="title">Content Name</div>
                  <input
                    type="text"
                    className="p-2 border-2 w-80"
                    value={contentName}
                    onChange={(e) => {
                      setContentName(e.target.value);
                      handleInputChange("contentName", e.target.value);
                    }}
                    placeholder="Content Name"
                  />
                </div>

                <div className="flex my-4  p-2  items-center justify-between">
                  <div className="title">Description</div>
                  <div className="w-80">

                  <TextEditor
                    className={"w-full"}
                    setContentType={(e) => {
                      handleEditSubmit(e);
                    }}
                  />
                    </ div>
                </div>
                <div className="flex my-4   items-center justify-between">
                  <div className="title">Image URL</div>
                  <input
                    type="text"
                    className="p-2 border-2 w-80"
                    value={imageUrl}
                    onChange={(e) => {
                      setImageUrl(e.target.value);
                      handleInputChange("contentImage", e.target.value);
                    }}
                    placeholder="image Url"
                  />
                </div>
                <div className="flex my-4   items-center justify-between">
                  <div className="title">Video Url</div>
                  <input
                    type="text"
                    className="p-2 border-2 w-80"
                    value={videoUrl}
                    onChange={(e) => {
                      setVideoUrl(e.target.value);
                      handleInputChange("videoLink", e.target.value);
                    }}
                    placeholder="Price"
                  />
                </div>
                <div className="w-full font-medium text-slate-50 p-2 cursor-pointer bg-blue-400 flex justify-center rounded">
                  <button onClick={contentSubmit} className="w-full">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          ) : (
            <div className="w-full my-4">
              <Table dataSource={contentData} columns={contentColums} />;
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Courses;
