// loginSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { REACT_APP_API_URL } from "../../secrets";
const seceretKey = process.env.REACT_APP_BASE_URL

const token =localStorage.getItem('token');
export const interestDetailFetch = createAsyncThunk("ROwn/interestDetails", async (paginationAndQueryData) => {
    const response = await fetch(`${seceretKey}api/Rown/getInterestDetail?Keyword=${paginationAndQueryData.searchQuery}&page=${paginationAndQueryData.currentPage}`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "mode":'no-cors',
            Authorization:token
        }
    });
    return await response.json();
});
export const InterestPatch = createAsyncThunk(
    'R-Own/Interest Patch',
    async(e, thunkAPI) => {
        
      try {
        const response = await fetch(`${seceretKey}api/Rown/editInterest?id=${e.id}`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization:token
          },
          body: JSON.stringify(e),
        });
        const data = await response.json();
        return data;
      } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    });
const interestDataSlice = createSlice({
    name: "iNTEREST",
    initialState: {
        interestData: null,
        loading: false,
        error: null,
        patchData:null,
    },
    extraReducers: (builder) => {
        builder.addCase(interestDetailFetch.pending, (state, action) => {
            state.loading = true;
            state.interestData = null;
            state.error = null;
        });
        builder.addCase(interestDetailFetch.fulfilled, (state, action) => {
            state.loading = false;
            state.interestData = action.payload;
            state.error = null;
        });
        builder.addCase(interestDetailFetch.rejected, (state, action) => {
            state.loading = false;
            state.interestData = null;
            state.error = action.error.message;
        });
        builder.addCase(InterestPatch.pending, (state, action) => {
            state.patchData = null;
            state.loading = true;

          
        });
        builder.addCase(InterestPatch.fulfilled, (state, action) => {
            state.loading = false;

            state.patchData = action.payload;
        });
        builder.addCase(InterestPatch.rejected, (state, action) => {
            state.patchData = null;
            state.loading = false;

            // state.error = action.error.message;
        });
    }
});

export default interestDataSlice.reducer;
