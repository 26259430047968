// loginSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
const seceretKey = process.env.REACT_APP_BASE_URL
export const loginUser = createAsyncThunk("login/loginUser", async (credentials) => {
   
    // console.log(apiKey)
    const response = await fetch(`${seceretKey}api/adminPannel/login`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            "mode":'no-cors' 
        },
        body: JSON.stringify(credentials)
    });
    return await response.json();
});

const loginSlice = createSlice({
    name: "login",
    initialState: {
        loginData: null,
        loading: false,
        error: null,
    },
    reducers:{
        addisAuth:(state,action)=>{
            state.isAuth=action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loginUser.pending, (state, action) => {
            state.loading = true;
            state.loginData = null;
            state.error = null;
        });
        builder.addCase(loginUser.fulfilled, (state, action) => {
            state.loading = false;
            state.loginData = action.payload;
            state.error = null;
            
        });
        builder.addCase(loginUser.rejected, (state, action) => {
            state.loading = false;
            state.loginData = null;
            state.error = action.error.message;
        });
    }
});

export default loginSlice.reducer;
