import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import TagInput from "../../../components/TagInput";
import ReactTable from "react-table";
import Model from '../../../components/Model'
import { BlogTaskPost,BlogTaskGet } from "../../../utils/BlogSlices/Blog/BlogTaskSlice";
import { Skeleton } from "antd";
import { BlogTaskPatch } from "../../../utils/BlogSlices/Blog/BlogTaskSlice";
import { toast } from "react-toastify";
import {
  Assigned,
  AssignedTo,
  DomainDetails
} from "../../../utils/BlogSlices/DropDownSlice/DropDownSlice";
const BlogTask = () => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(Assigned());
    dispatch(AssignedTo());
    dispatch(DomainDetails());
    dispatch(BlogTaskGet());
    dispatch(BlogTaskGet())
  }, []);
  const assigned = useSelector((store) => store.dropDownSlice?.assigned?.data);
  const domaindrop = useSelector((store) => store.dropDownSlice?.Domain?.data);
  const blogData = useSelector((store) => store.BlogTask);
  const [imageErrors, setImageErrors] = useState([]);

  const handleImageError = (index) => {
    setImageErrors((prevErrors) => [...prevErrors, index]);
  };
  const [finalArray,setFinalArray]=useState([]);
  // domaindrop?.map((item)=> console.log(item.domainName))

  const assignedTo = useSelector(
    (store) => store.dropDownSlice?.courseCategory?.data
    );

  const [title, setTitle] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const Domain = useSelector((store) => store.PostData.domain);
  const [handleAddTask, setHanddleAddTask] = useState(false);
  const [selectedRecord,setSelectedRecord]=useState();
  const [readOnly, setReadOnly] = useState(false);
  const [keywordDoc, setKeywordDoc] = useState();
  const [assignee, setAssignee] = useState();
  const [assignTo, setAssignTo] = useState();
  const handleInputChange = (field, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
  };
  const [formData, setFormData] = useState({});

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      domain: finalArray?.map((d) => ({ domainName: d })),
    }));
  }, [Domain]);
  const handleSubmit = (e) => {
    e.preventDefault();
    setHanddleAddTask(false)

    dispatch(BlogTaskPost(formData)).then((res)=>{
      
  if (res.payload.code === 200) {
    dispatch(BlogTaskGet());
    toast.success("Updated Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } else {
    toast.error(res.payload.message);
  }
});
    // console.log(formData);

    // Here you can add your logic to handle the form submission, such as sending the data to a server or saving it locally

    // dispatch(addPostData(formData));
    // console.log("Content:", content);
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };
  const options = [
  ];
  domaindrop?.map((item)=> options.push({value:item.domainName, label:item.domainName}))

 const handleEdit=(val)=>{

dispatch(BlogTaskPatch(val)).then((res) => {
  dispatch(BlogTaskGet());
  setIsOpen(false);
  if (res.payload?.code === 200) {
    toast.success("Updated Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } else {
    toast.error(res.payload.message);
  }
});
 }
  const handleChange = (selectedItems) => {
    const labelsArray = selectedItems?.map(item => item.label);
    console.log("Selected Labels:", labelsArray);
    setFinalArray(labelsArray)
    setSelectedOptions(selectedItems);
  };
  console.log(blogData,"BlogData")

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="flex w-full justify-between">
        <div className="title p-2">Blog Tasks</div>
        <div
          onClick={() => {
            setHanddleAddTask(!handleAddTask);
          }}
          className="p-2 bg-blue-700 text-white cursor-pointer "
        >
          {handleAddTask ? "See Tasks" : "Add Task"}
        </div>
      </div>
      {handleAddTask ? (
        <div className=" border-2   flex rounded flex-col   w-1/3 ">
          <div className="bg-slate-700 h-8 p-1 rounded text-white">
            <h1>Create A task</h1>
          </div>
          <form
            onSubmit={handleSubmit}
            className="p-2"
            onKeyDown={handleKeyDown}
          >
            <div className="flex my-4   items-center justify-between">
              <div className="title">Blog Title</div>
              <input
                type="text"
                className="p-2 border-2 w-56"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                  handleInputChange("blogTitle", e.target.value);
                }}
                placeholder="Title"
              />
            </div>
            <div className="flex items-center  justify-between my-2 gap-5">
              <div className="title">Keyword Doc</div>
              <input
                type="file"
                className="p-2 border-2 w-56"
                onChange={(e) => {
                  const file = e.target.files[0];
                  setKeywordDoc(file);
                  handleInputChange("keywordDoc", file);
                }}
              />
            </div>
            <div className="flex my-4  items-center  justify-between gap-5">
              <div className="title">Assignee</div>
              <select
                id="selectOption"
                className="w-56 align-middle border-2"
                value={assignee}
                onChange={(e) => {
                  setAssignee(e.target.value);
                  handleInputChange("assigned", e.target.value);
                }}
              >
                <option value="">Select...</option> {/* Default option */}
                
                {assigned?.map((item) => (
                  <option key={item.assignedToId} value={item.id}>
                    {item.assignedName}
                  </option>
                ))}
              </select>
              {/* <input
              type="text"
              className="p-2 border-2 w-56"
              value={assignee}
              onChange={(e) => {
                setAssignee(e.target.value);
                handleInputChange("assigned", e.target.value);
              }}
            /> */}
            </div>
            <div className="flex my-4  items-center  justify-between gap-5">
              <div className="title">Assign To</div>

              <select
                id="selectOption"
                className="w-56 align-middle border-2"
                
                value={assignTo}
                onChange={(e) => {
                  setAssignTo(e.target.value);
                  handleInputChange("assignedTo", e.target.value);
                }}
              >
                <option value="">Select...</option> {/* Default option */}
                {assignedTo?.map((item) => (
                  <option key={item.assignedToId} value={item.id}>
                    {item.assignedToName}
                  </option>
                ))}
              </select>
              {/* <input
                type="text"
                className="p-2 border-2 w-56"
                value={assignTo}
                onChange={(e) => {
                  setAssignTo(e.target.value);
                  handleInputChange("assignedTo", e.target.value);
                }}
                placeholder="Assgin To"
              /> */}
            </div>
            <div className="flex my-4 items-center  justify-between gap-5">
              <div className="title">Domain</div>
              <Select
      options={options}
      isMulti
      value={selectedOptions}
      onChange={handleChange}
    />
              {/* <TagInput
                type="domain"
                onChange={(e) => {
                  handleInputChange("domain", e);
                }}
              /> */}
            </div>
            <div className="w-full cursor-pointer bg-blue-400 flex justify-center rounded">
              <button onClick={()=>{}} className="w-full">Submit</button>
            </div>
          </form>
        </div>
      ) : (
        <div className="w-full border-2 my-4">
        <table className="w-full" style={{ borderCollapse: 'collapse', width: '100%', marginTop: '20px' }}>
  <thead>
    <tr>
      <th style={{ border: '1px solid #ddd', padding: '8px' }}>userId</th>
      <th style={{ border: '1px solid #ddd', padding: '8px' }}>blogTitle</th>
      <th style={{ border: '1px solid #ddd', padding: '8px' }}>assigned</th>
      <th style={{ border: '1px solid #ddd', padding: '8px' }}>assignedTo</th>
      <th style={{ border: '1px solid #ddd', padding: '8px' }}>Action</th>
    </tr>
  </thead>
  <tbody>
    {blogData?.loading  ? <Skeleton active />:
    blogData?.blogData?.data?.map((val, index) => (
      <tr key={index}>
        <td style={{ border: '1px solid #ddd', padding: '8px' }}>{val.userId}</td>
        <td style={{ border: '1px solid #ddd', padding: '8px' }}>
          <div className="flex gap-2">
          {imageErrors.includes(index) ? (
                  <div className="flex items-center">
                    <p className=" border-2 p-1 w-9 m-4 rounded bg-blue-500 hover:bg-blue-400">pdf</p> 
                    <p>{val.blogTitle}</p>
                  </div>
                ) : (
                  <div className="flex items-center ">
                    <img className="h-9 m-4" src={val.keywordDoc} onError={() => handleImageError(index)} alt={val.blogTitle} />
                    {val.blogTitle}
                  </div>
                )}
        
          </div>
         </td>
        <td style={{ border: '1px solid #ddd', padding: '8px' }}>{val.assigned}</td>
        <td style={{ border: '1px solid #ddd', padding: '8px' }}>{val.assignedTo}</td>
        <td className=" w-32"><div  onClick={()=>{setIsOpen(true);
setSelectedRecord(val);}} className="bg-blue-800 w-12 ml-10 text-white p-2 flex items-center ">
        Edit
        </div>
          
         </td>
      </tr>
    ))}
  </tbody>
</table>
{isOpen && (
        <Model
          data={selectedRecord}
          editable={readOnly}
          onSubmitt={handleEdit}
          onClose={()=>setIsOpen(false)}
        />
      )}

        </div>
      )}
    </div>
  );
};

export default BlogTask;
