// loginSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import {REACT_APP_API_URL} from '../secrets'
const seceretKey = process.env.REACT_APP_BASE_URL
export const categoriesData = createAsyncThunk("Blogs/Categories", async (credentials) => {
    const response = await fetch(`${seceretKey}api/adminPannel/getCategory`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "mode":'no-cors'
        },
        body: JSON.stringify(credentials)
    });
    return await response.json();
});

const mainCategorieSlice = createSlice({
    name: "mainCategories",
    initialState: {
        mainCategories: null,
        loading: false,
        error: null
    },
    extraReducers: (builder) => {
        builder.addCase(categoriesData.pending, (state, action) => {
            state.loading = true;
            state.mainCategories = null;
            state.error = null;
        });
        builder.addCase(categoriesData.fulfilled, (state, action) => {
            state.loading = false;
            state.mainCategories = action.payload;
            state.error = null;
        });
        builder.addCase(categoriesData.rejected, (state, action) => {
            state.loading = false;
            state.mainCategories = null;
            state.error = action.error.message;
        });
    }
});

export default mainCategorieSlice.reducer;
