import React,{useEffect,useState} from 'react'
import { user,userPatch } from '../../../utils/ProductsSlices/HoteIntelligence/UserSlice'
import { useSelector ,useDispatch} from "react-redux";
import { Space,Table, Skeleton  } from "antd";
import Model from '../../../components/Model';
const HotelIntelligenceUsers= () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [readOnly, setReadOnly] = useState(true);
  const userData = useSelector((store) => store.HIUsers);
  const access= localStorage.getItem('access');
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    dispatch(user(page))
  };

  function handleEditSubmit(e){
    dispatch(userPatch(e)).then((res) => {
      dispatch(user(currentPage));
      setIsOpen(false)
    })
  }
  const handleOpen = () => {
    setIsOpen(!isOpen);
  };
  const columns = [
    {
      title: '_id',
      dataIndex: '_id',
      key: '_id',
      
    },
    {
      title: 'email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'mobileNumber',
      dataIndex: 'mobileNumber',
      key: 'mobileNumber',
    },
    {
      title: 'is_password_active',
      key: 'is_password_active',
      render: (_, record) => (
        <Space size="middle">
          <p> {record.is_password_active?"true":"false"}</p>
          
        </Space>
        ),
    },
    {
      title: 'is_correct',
      key: 'is_correct',
      render: (_, record) => (
        <Space size="middle">
          <p> {record.is_correct?"true":"false"}</p>
          
        </Space>
        ),
    },
    {
      title: "View/Edit",
      render: (_, record) => (
        <Space size="middle">
          <div>
            <a className="text-blue-700"
              onClick={() => {
                setSelectedRecord(record);
                handleOpen();
                setReadOnly(true);
              }}
            >
              View
            </a>
            {access=='true'&& 
            <a className="p-2 text-red-950"
              onClick={() => {
                setSelectedRecord(record);
                handleOpen();
                setReadOnly(false);
              }}
            >
              Edit
            </a>}
          </div>
        </Space>
      ),
      key: "email",
    }

  ];
  useEffect(() => {
    dispatch(user(currentPage));
  }, [dispatch]);
  
  return (
    <div>
{isOpen &&( <Model  data={selectedRecord} editable={readOnly}  onSubmitt={handleEditSubmit}  onClose={()=>setIsOpen(false)}/>)}

    <Table columns={columns}   
pagination={{
      total: userData?.userData?.totalCount,
      current: currentPage,
      onChange: handlePageChange,
    }} dataSource={userData?.userData?.data} />
  
    </ div>
  )
}

export default HotelIntelligenceUsers