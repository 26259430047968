// loginSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const seceretKey = process.env.REACT_APP_BASE_URL

const token =localStorage.getItem('token');

export const user = createAsyncThunk("HotelAudit/user", async (paginationAndQueryData) => {
    const response = await fetch(`${seceretKey}api/auditReportRoutes/getAduitReportUsers?Keyword=${paginationAndQueryData.searchQuery}&page=${paginationAndQueryData.currentPage}`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "mode":'no-cors',
            Authorization:token?token:"dhruv",
        }
    });
    return await response.json();
});
export const userPatch = createAsyncThunk(
    'R-Own/HotelAudit patch',
    async(e, thunkAPI) => {
        
      try {
        const response = await fetch(`${seceretKey}api/auditReportRoutes/editAuditReportUser?customerID=${e.customerID}`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization:token
          },
          body: JSON.stringify(e),
        });
        const data = await response.json();
        return data;
      } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    });

const userSlice = createSlice({
    name: "user",
    initialState: {
        userData: null,
        loading: false,
        error: null,
        patchData:null,
    },
    extraReducers: (builder) => {
        builder.addCase(user.pending, (state, action) => {
            state.loading = true;
            state.userData = null;
            state.error = null;
        });
        builder.addCase(user.fulfilled, (state, action) => {
            state.loading = false;
            state.userData = action.payload;
            state.error = null;
        });
        builder.addCase(user.rejected, (state, action) => {
            state.loading = false;
            state.userData = null;
            state.error = action.error.message;
        });
        builder.addCase(userPatch.pending, (state, action) => {
          state.patchData = null;
        
      });
      builder.addCase(userPatch.fulfilled, (state, action) => {
          
          state.patchData = action.payload;
      });
      builder.addCase(userPatch.rejected, (state, action) => {
          state.patchData = null;
          // state.error = action.error.message;
      });
    }
});

export default userSlice.reducer;
