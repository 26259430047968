import React, { useEffect, useState } from "react";
import { user } from "../../../utils/ProductsSlices/RateX/UserSlice";
import { useSelector, useDispatch } from "react-redux";
import { Space, Skeleton , Table  } from "antd";
import Model from "../../../components/Model";
import { patchUser } from "../../../utils/ProductsSlices/RateX/UserSlice";
import { ToastContainer, toast } from "react-toastify";
import { Input } from "antd";
const { Search } = Input;
const RateXUsers = () => {
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [readOnly, setReadOnly] = useState(true);
  const userData = useSelector((store) => store.RateXuser);
  const access= localStorage.getItem('access');
  const [currentPage, setCurrentPage] = useState(1);
  let paginationAndQueryData = {
    searchQuery: searchQuery ? searchQuery : "",
    currentPage: currentPage,
  };

  useEffect(() => {
    paginationAndQueryData = {
      searchQuery: searchQuery ? searchQuery : "",
      currentPage: currentPage,
    };
    dispatch(user(paginationAndQueryData));
  }, [searchQuery, currentPage]);
  const handleSearch = () => {
    dispatch(user(paginationAndQueryData));
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    dispatch(user(paginationAndQueryData))
  };

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Bussiness Name",
      dataIndex: "bussinessName",
      key: "bussinessName",
    },
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
    },
    {
      title: "Phone Number",
      key: "phoneNumber",
      dataIndex: "phoneNumber",
    },
    {
      title: "User Type",
      key: "userType",
      dataIndex: "userType",
    },
    {
      title: "View/Edit",
      render: (_, record) => (
        <Space size="middle">
          <p> {}</p>

          <div>
            <a
              className="text-blue-700"
              onClick={() => {
                console.log(record);
                setSelectedRecord(record);
                handleOpen();
                setReadOnly(true);
              }}
            >
              View
            </a>
            {access=='true'&&  
            <a
              className="p-2 text-red-950"
              onClick={() => {
                setSelectedRecord(record);
                handleOpen();
                setReadOnly(false);
              }}
            >
              Edit
            </a>}
          </div>
        </Space>
      ),
      key: "email",
    },
  ];
  function handleEditSubmit(e) {
  
    dispatch(patchUser(e)).then((res) => {
      dispatch(user(paginationAndQueryData));
      setIsOpen(false)
      if(res.payload.code===200){
        toast.success('Updated Successfully', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          
          });
        }else{
          toast.error(res.payload.message)
        }
    });
  }
  useEffect(() => {
    dispatch(user(paginationAndQueryData));
  }, [dispatch]);
  const [apiData, setAPIData] = useState();
  useEffect(() => {
    console.log(userData, "userrr");
    setAPIData(userData?.data);
  }, [userData]);


  return (
    <div>
      
 <Search
        onChange={(e) => {
          setSearchQuery(e.target.value);
          
        }}
        value={searchQuery}
        placeholder="input search text"
        enterButton="Search"
        size="large"
        className="m-2 rounded"
        onSearch={handleSearch}
      />

      {isOpen && (
        <Model
          data={selectedRecord}
          editable={readOnly}
          onSubmitt={handleEditSubmit}  onClose={()=>setIsOpen(false)}
        />
      )}
      { userData.loading ? <Skeleton active /> : 
      <Table columns={columns}  className="rounded" 
      pagination={{
            total: userData?.data?.totalCount,
            current: currentPage,
            onChange: handlePageChange,
          }} dataSource={userData?.userData?.data} />}
    </div>
  );
};

export default RateXUsers;
