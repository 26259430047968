import React, { useEffect, useState } from "react";
import Dropdown from "../../../components/Drop";
import { useDispatch, useSelector } from "react-redux";
import { domainData } from "../../../utils/BlogSlices/config/DomainSlice";
import { DomainGet } from "../../../utils/BlogSlices/config/DomainSlice";
import { toast } from "react-toastify";
const Domain = () => {
  const [domainName, setDomainName] = useState("");
  const [domainUrl, setDomainUrl] = useState("");
  const [formData, setFormData] = useState([]);
  const [handleAddTask, setHanddleAddTask] = useState(false);
  const dispatch = useDispatch();
  const DomainData = useSelector((store) => store.DomainSlice?.domainGet?.data);
  const handleInputChange = (field, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
  };
  useEffect(() => {
    dispatch(DomainGet());
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(domainData(formData)).then((result) => {
      if (result.payload) {
        if (result.payload.code === 200) {
          toast.success('Updated Successfully', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setHanddleAddTask(true);
          dispatch(DomainGet());
        } else {
          toast.error(result.payload.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      }
    });;

    console.log(formData);
  };
  return (
    <div>
       <div className="flex w-full justify-between">
        <div className="title p-2">Blog Tasks</div>
        <div
          onClick={() => {
            setHanddleAddTask(!handleAddTask);
          }}
          className="p-2 bg-blue-700 text-white cursor-pointer "
          >
          {handleAddTask ? "View Domain" : "Add Domain"}
        </div>
      </div>
          {handleAddTask? <form onSubmit={handleSubmit} className="p-2 w-1/3">
        <div className="flex my-4   items-center justify-between">
          <div className="title">Domain Name</div>
          <input
            type="text"
            className="p-2 border-2 w-56"
            value={domainName}
            onChange={(e) => {
              setDomainName(e.target.value);
              handleInputChange("domainName", e.target.value);
            }}
            placeholder="Title"
          />
        </div>
        <div className="flex my-4   items-center justify-between">
          <div className="title">Domain Url</div>
          <input
            type="text"
            className="p-2 border-2 w-56"
            value={domainUrl}
            onChange={(e) => {
              setDomainUrl(e.target.value);
              handleInputChange("domainUrl", e.target.value);
            }}
            placeholder="url"
          />
        </div>
        <button className="rounded border-2 py-2 px-4  bg-blue-500 hover:bg-blue-400">
          Submit
        </button>
      </form>:<table
        className="w-full"
        style={{ borderCollapse: "collapse", width: "100%", marginTop: "20px" }}
      >
        <thead>
          <tr>
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>
              domainId
            </th>
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>
              domainName
            </th>
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>
              domainUrl
            </th>
          </tr>
        </thead>
        <tbody>
          {DomainData?.map((item, key) => (
            <tr key={key}>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                {item.domainId}
              </td>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                {" "}
                {item.domainName}
              </td>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                {item.domainUrl}
              </td>
            </tr>
          ))}
        </tbody>
      </table>}
      

     
    </div>
  );
};

export default Domain;
