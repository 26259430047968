import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { DomainDetails } from "../../../utils/BlogSlices/DropDownSlice/DropDownSlice";
import { fetchjobCategory } from "../../../utils/BlogSlices/DropDownSlice/DropDownSlice";
import { fetchjobSubCategory } from "../../../utils/BlogSlices/DropDownSlice/DropDownSlice";
// import {categoriesDataPost} from '../../../utils/BlogSlices/DropDownSlice/DropDownSlice';
import { jobCategoryPost,jobSubCategoryPost } from "../../../utils/ProductsSlices/jobSlice/jobDetail";
import { Radio } from "antd";
import { toast } from "react-toastify";
const JobsCategories = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [categoryBoolean,setCategoryBoolean]=useState(false)
  const [selectedOptions2, setSelectedOptions2] = useState([]);
  const [category,setCategory]=useState();
  const [subCategory,setSubCategory]=useState('')
  const [categoryType, setCategoryType] = useState('Category');
  const categorieData = useSelector(
    (store) => store.dropDownSlice?.jobCategory?.data
  );
  const subCategorieData = useSelector(
    (store) => store.dropDownSlice?.jobSubCategory?.data
  );
  useEffect(() => {
    dispatch(fetchjobCategory());
    dispatch(fetchjobSubCategory());
  }, []);
  const [finalArray, setFinalArray] = useState([]);
  const optionsCategories = [];
  categorieData?.map((item) =>
    optionsCategories.push({
      value: item.jobCategoriesName,
      label: item.jobCategoriesName,
      id: item.jobCategoriesId
    })
  );
  const optionsSubCategories = [];
  subCategorieData?.map((item) =>
    optionsSubCategories.push({
      value: item.subCategoriesName,
      label: item.subCategoriesName,
    })
  );

  const handleInputChange = (field, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
  };
  const handleCategory = (selectedItems) => {
    // const labelsArray = selectedItems?.map((item) => item.label);
    // console.log("Selected Labels:", labelsArray);labelsArray
    // setFinalArray(labelsArray);
    
    setSelectedOptions(selectedItems);
    setFormData((prevFormData) => ({
      ...prevFormData,
      jobCategories: { jobCategoriesName: selectedItems.value , jobCategoriesId:selectedItems.id},
    }));
  };

  useEffect(()=>{
    dispatch(fetchjobCategory());  },[categoryType])

  // console.log(formData);
useEffect(()=>{
  setFormData((prevFormData) => ({
    ...prevFormData,
    subJobCategories:[ { subCategoriesName: subCategory }],
  }));
},[subCategory])
  const handleSubmit = (e) => {
    e.preventDefault();

    if(categoryType==='Category')
    dispatch(jobCategoryPost({"jobCategoriesName":category})).then((res) => {
    
      if (res.payload.code === 200) {
        toast.success("Category Updated", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error(res.payload.message);
      }
    });
    
    else{
    console.log(formData)
     dispatch(jobSubCategoryPost(formData)).then((res) => {
    
      if (res.payload.code === 200) {
        toast.success("Sub Category Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error(res.payload.message);
      }
    });
    //   // console.log(jobSubCategoryPost(formData))
   }
   
  };

  const onChange = (e) => {
    setCategoryType(e.target.value);
    
  };
  return (
    <div>
          <div className="flex w-full justify-between">
        <div className="title p-2">Categories</div>
        <div
          onClick={() => {
            // setHanddleAddTask(!handleAddTask);
            setCategoryBoolean(!categoryBoolean)
          }}
          className="p-2 bg-blue-700 text-white cursor-pointer "
        >
          {categoryBoolean ? "See Categories " : "Add Categories"}
        </div>
      </div>
{!categoryBoolean ? <div></div>:


      <form onSubmit={handleSubmit}>
        <div className="flex-col my-4 w-1/3  items-center  justify-between ">
    
      
        <div className="flex my-4 items-center justify-between ">
              <div>Category Type</div>
              <Radio.Group className="flex w-80  "  defaultValue={"Category"} onChange={onChange}>
                <Radio value={"Category"}>Category</Radio>
                <Radio value={"Subcategories"}>Sub categories</Radio>
              </Radio.Group>
        </div>
{categoryType==='Subcategories' ?
<div>
  <div className="w-80 flex items-center justify-between">

          <div className="title">Categories</div>
          <Select
            options={optionsCategories}
          
            value={selectedOptions}
            onChange={handleCategory}
            className="w-56"
            />
            </div>
        

            <div className="w-80 flex items-center  mt-5 justify-between">
          <div className="title">Sub Category</div>
          <input type="text" value={subCategory} onChange={(e)=>{setSubCategory(e.target.value)}} className="w-56 h-10 p-2 border-2" />
          {/* <Select
            options={optionsSubCategories}
            isMulti
            value={selectedOptions2}
            onChange={handleSubCategory}
            className="w-56"
          /> */}
        </div></div> :<div>
          
          
          
        <div className="w-80 flex items-center  mt-5 justify-between">
          <div className="title">Category</div>
          <input type="text" onChange={(e)=>setCategory(e.target.value)} value={category} className="w-56 h-8 p-2 border-2" />
          {/* <Select
            options={optionsSubCategories}
            isMulti
            value={selectedOptions2}
            onChange={handleSubCategory}
            className="w-56"
          /> */}
        </div>
          
          </div>}

        <button className="rounded border-2 py-2 px-4  bg-blue-500 hover:bg-blue-400">
          Submit
        </button>
        </div>
      </form>}
    </div>
  );
};

export default JobsCategories;
