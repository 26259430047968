import { addAuth } from "../../utils/authSlice";
import React, { useState, useRef, useEffect } from "react";
// import Header from "./Header";
import { isEmailValid } from "../../utils/Validate";
import { loginUser } from "../../utils/loginSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../../utils/icons/loginScreen.svg";
const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error } = useSelector((state) => state.loginData);
  const [userName, setUserName] = useState(useSelector((store) => store.loginData?.data?.name) ? "" : "User");
  const [token, setToken] = useState(useSelector((store) => store.loginData?.data?.token) );

  // console.log(userName)
  // const [isSignIn, setIsSignIn] = useState(false);
  const email = useRef(null);
  const password = useRef(null);
  const [errorMessage, setErrorMessage] = useState();
  useEffect(() => {
    if (userName) {
      localStorage.setItem('name', userName);
    }
  }, [userName]);
  useEffect(() => {
    if (token) {
      localStorage.setItem('token', token);
    }
  }, [token]);
  const handleSinginForm = () => {

    // console.log(password.current.value)
    setErrorMessage(isEmailValid(email.current.value, password.current.value));
    const body = {
      email: email.current.value,
      password: password.current.value,
    };
    dispatch(loginUser(body)).then((result) => {
      if (result.payload) {
        if (result.payload.code === 200) {
          toast.success('Login Successfully', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",

          });
          dispatch(addAuth());
          console.log(result.payload.data?.action)
          localStorage.setItem('access', result.payload.data?.action)
          localStorage.setItem('userId', result.payload.data.userId);
          localStorage.setItem('token', result.payload.data?.token);
          console.log(result.payload.data,"userPayload")
          navigate("/dashboard",{
            state:{
              token: result.payload.data?.token
            }
          });
        } else {
          setErrorMessage(result.payload.message);
          toast.error(result.payload.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",

          });
        }
      }
    });
  };
  return (
    <div className="flex-col mt-5 gap-14 sm:h-screen flex sm:flex-row">
      <div className=" w-full sm:w-1/2 flex items-center justify-center bg-slate-710">
        <img className="h-36 sm:h-96 sm:ml-28 z-70" src={logo} alt="failed to load image" />
      </div>
      <div className=" w-full sm:w-1/2 flex items-center justify-center bg-white">
        <div className=" ">
          <div className="text-sm sm:text-2xl ">


            <h1 className="text-3xl mb-5 pb-2 font-bold">Login To Admin Panel</h1>
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
              className="w-72  sm:align-middle sm:w-96 justify-center"
            >
              <input
                type="text"
                className="
                text-base my-2 w-full bg-light-800 p-3 h-16 rounded-lg border-b-2 border-black text-black focus:outline-none focus:ring-0 "
                ref={email}
                placeholder="Email Address"
              ></input>

              <input
                type="password"
                ref={password}
                className="my-2 w-full p-3 rounded-lg text-base bg-light-800 h-16 border-b-2 border-black text-black  focus:outline-none focus:ring-0"
                placeholder="Password"
              ></input>
              <p
                className="text-red-700 font-bold  text-sm "
              >
                {errorMessage}
              </p>
              <br />
              <button
                onClick={handleSinginForm}
                className="text-white my-2 w-full bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 focus:outline-none dark:focus:ring-red-800"
              >
                 {loading ? "Loading..." : "Login"}
              </button>
             
              {error && <div className="alert alert-danger"></div>}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
