// loginSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";


const postDetailsSlice = createSlice({
    name: "Dashboard",
    initialState: {
        postData: {},
        loading: false,
        error: null,
        primaryKeyword:null,
        secondaryKeyword:null,
        domain:null
    },
    reducers:{
    addPrimaryKeyword:(state,action)=>{
        state.primaryKeyword=action.payload;
    },
    addSecondaryKeyword:(state,action)=>{
        state.secondaryKeyword=action.payload;
    },
    addDomain:(state,action )=>{
        state.domain=action.payload;
    }
  }
});

export default postDetailsSlice.reducer;
export const {addPrimaryKeyword,addSecondaryKeyword, addDomain} =postDetailsSlice.actions;
