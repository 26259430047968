// loginSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { REACT_APP_API_URL } from "../../secrets";
const seceretKey = process.env.REACT_APP_BASE_URL
const token =localStorage.getItem('token');

export const HotelFetch = createAsyncThunk("ROwn/hotel", async (paginationAndQueryData) => {
    const response = await fetch(`${seceretKey}api/Rown/getHotelDetail?Keyword=${paginationAndQueryData.searchQuery}&page=${paginationAndQueryData.currentPage}`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "mode":'no-cors',
            Authorization:token
        }
    });
    return await response.json();
});

export const HotelPatch = createAsyncThunk(
    'R-Own/Hotel Patch',
    async(e, thunkAPI) => {
        
      try {
        const response = await fetch(`${seceretKey}api/Rown/editHotelDetail?hotel_id=${e.hotel_id}`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization:token
          },
          body: JSON.stringify(e),
        });
        const data = await response.json();
        return data;
      } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    });

const hotelDataSlice = createSlice({
    name: "Hotel",
    initialState: {
        HotelData: null,
        loading: false,
        error: null,
        patchData:null,
    },
    extraReducers: (builder) => {
        builder.addCase(HotelFetch.pending, (state, action) => {
            state.loading = true;
            state.HotelData = null;
            state.error = null;
        });
        builder.addCase(HotelFetch.fulfilled, (state, action) => {
            state.loading = false;
            state.HotelData = action.payload;
            state.error = null;
        });
        builder.addCase(HotelFetch.rejected, (state, action) => {
            state.loading = false;
            state.HotelData = null;
            state.error = action.error.message;
        });
        builder.addCase(HotelPatch.pending, (state, action) => {
            state.patchData = null;
            state.loading = true;

          
        });
        builder.addCase(HotelPatch.fulfilled, (state, action) => {
            state.loading = false;

            state.patchData = action.payload;
        });
        builder.addCase(HotelPatch.rejected, (state, action) => {
            state.patchData = null;
            state.loading = false;

            // state.error = action.error.message;
        });
    }
});

export default hotelDataSlice.reducer;
