// loginSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {REACT_APP_API_URL} from '../../../utils/secrets'
const seceretKey = process.env.REACT_APP_BASE_URL

const token =localStorage.getItem('token');
export const PaymentsData = createAsyncThunk("HotelAudit/Payment", async (paginationAndQueryData) => {
    const response = await fetch(`${seceretKey}api/auditReportRoutes/getAduitPayment?Keyword=${paginationAndQueryData.searchQuery}&page=${paginationAndQueryData.currentPage}`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "mode":'no-cors',
            Authorization:token,
        }
    });
    return await response.json();
});
export const paymentPatch = createAsyncThunk(
    'R-Own/paymentPatch',
    async(e, thunkAPI) => {
        console.log(e.userId)
      try {
        const response = await fetch(`${seceretKey}api/auditReportRoutes/editAuditPayment?customerID=${e.customerID}`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization:token
          },
          body: JSON.stringify(e),
        });
        const data = await response.json();
        return data;
      } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    }
  );
const paymentSlice = createSlice({
    name: "payment",
    initialState: {
        payment: null,
        loading: false,
        error: null,
        patchData:null
    },
    extraReducers: (builder) => {
        builder.addCase(PaymentsData.pending, (state, action) => {
            state.loading = true;
            state.payment = null;
            state.error = null;
        });
        builder.addCase(PaymentsData.fulfilled, (state, action) => {
            state.loading = false;
            state.payment = action.payload;
            state.error = null;
        });
        builder.addCase(PaymentsData.rejected, (state, action) => {
            state.loading = false;
            state.payment = null;
            state.error = action.error.message;
        });
        builder.addCase(paymentPatch.pending, (state, action) => {
            state.patchData = null;
          
        });
        builder.addCase(paymentPatch.fulfilled, (state, action) => {
            
            state.patchData = action.payload;
        });
        builder.addCase(paymentPatch.rejected, (state, action) => {
            state.patchData = null;
            // state.error = action.error.message;
        });
    }
});

export default paymentSlice.reducer;
