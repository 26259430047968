// loginSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {REACT_APP_API_URL} from '../../secrets'
const seceretKey = process.env.REACT_APP_BASE_URL
const token =localStorage.getItem('token');
export const propertiesData = createAsyncThunk("ratePulse/Properties", async (paginationAndQueryData) => {
    // console.log(credentials)
    const response = await fetch(`${seceretKey}api/rateshopper/getRateShopperProperty?Keyword=${paginationAndQueryData.searchQuery}&page=${paginationAndQueryData.currentPage}`,{
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "mode":'no-cors',
            Authorization:token,
        }});
        // body: JSON.stringify(credentials)
    
    return await response.json();
});
export const patchProperty = createAsyncThunk(
    'Ratex/patchProperty',
    async (e, thunkAPI) => {
        
      try {
        const response = await fetch(`${seceretKey}api/rateshopper/editPropertyDetail?hId=${e.hId}`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization:token
          },
          body: JSON.stringify(e),
        });
        const data = await response.json();
        return data;
      } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    }
  );

const propertiesSlice = createSlice({
    name: "properties",
    initialState: {
        data: [],
        loading: false,
        error: null,
        patchData:null
    },
    extraReducers: (builder) => {
        builder.addCase(propertiesData.pending, (state, action) => {
            state.loading = true;
            state.loginData = null;
            state.error = null;
        });
        builder.addCase(propertiesData.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
            state.error = null;
        });
        builder.addCase(propertiesData.rejected, (state, action) => {
            state.loading = false;
            state.data = null;
            state.error = action.error.message;
        });
        builder.addCase(patchProperty.pending, (state, action) => {
            state.patchData = null;
          
        });
        builder.addCase(patchProperty.fulfilled, (state, action) => {
            
            state.patchData = action.payload;
        });
        builder.addCase(patchProperty.rejected, (state, action) => {
            state.patchData = null;
            // state.error = action.error.message;
        });
    }
});

export default propertiesSlice.reducer;
