import React, { useEffect, useState } from "react";
import { subsciberData } from "../../../utils/ProductsSlices/RateX/SubscriberSlice";
import { useSelector, useDispatch } from "react-redux";
import { Space,  Table,Skeleton  } from "antd";
import Model from "../../../components/Model";
import {patchSubscriber} from '../../../utils/ProductsSlices/RateX/SubscriberSlice'
import { toast } from "react-toastify";
import { Input } from "antd";
const { Search } = Input;
const RateXSubscribers = () => {
  const dispatch = useDispatch();
  
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [readOnly, setReadOnly] = useState(true);
  const subData = useSelector((store) => store.RateXSubscriber);
  const access= localStorage.getItem('access');
 const [currentPage, setCurrentPage] = useState(1);
 const [searchQuery, setSearchQuery] = useState(""); 
  let paginationAndQueryData = {
     searchQuery: searchQuery ? searchQuery : "",
     currentPage: currentPage,
   };
 
   useEffect(() => {
     paginationAndQueryData = {
       searchQuery: searchQuery ? searchQuery : "",
       currentPage: currentPage,
     };
     dispatch(subsciberData(paginationAndQueryData));
   }, [searchQuery, currentPage]);
   const handleSearch = () => {
     dispatch(subsciberData(paginationAndQueryData));
   };
    
  const handlePageChange = (page) => {
    setCurrentPage(page);
    dispatch(subsciberData(paginationAndQueryData))
  };


  const handleOpen = () => {
    setIsOpen(!isOpen);
  };
  function handleEditSubmit(e){
    console.log(e)
    console.log('dispatch')
    dispatch(patchSubscriber(e)).then((res) => {
      dispatch(subsciberData(paginationAndQueryData));
      setIsOpen(false)
      if(res.payload.code===200){
        toast.success('Updated Successfully', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          
          });
        }else{
          toast.error(res.payload.message)
        }
    });
  }

  const columns = [
    {
      title: "User Id",
      dataIndex: "userId",
      key: "userId",
    },
    {
      title: "Subscription Type",
      dataIndex: "subscriptionType",
      key: "subscriptionType",
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
    },
    {
      title: "End Date",
      key: "endDate",
      dataIndex: "endDate",
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
    },
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "View/Edit",
      render: (_, record) => (
        <Space size="middle">
      

          <div>
            <a
              className="text-blue-700"
              onClick={() => {
                setSelectedRecord(record);
                handleOpen();
                setReadOnly(true);
              }}
            >
              View
            </a>
            {access=='true' &&  
            <a
              className="p-2 text-red-950"
              onClick={() => {
                setSelectedRecord(record);
                handleOpen();
                setReadOnly(false);
              }}
            >
              Edit
            </a>}
          </div>
        </Space>
      ),
      key: "email",
    },
  ];
  useEffect(() => {
    dispatch(subsciberData(paginationAndQueryData));
  }, [dispatch]);

  return (
    <div>
       <Search
        onChange={(e) => {
          setSearchQuery(e.target.value);
          
        }}
        value={searchQuery}
        placeholder="input search text"
        enterButton="Search"
        size="large"
        className="m-2 rounded"
        onSearch={handleSearch}
      />
      {isOpen && <Model data={selectedRecord} editable={readOnly} onSubmitt={handleEditSubmit}  onClose={()=>setIsOpen(false)} />}
      { subData.loading ? <Skeleton active /> :
      <Table columns={columns} className="rounded"   
      pagination={{
            total: subData?.data?.totalCount,
            current: currentPage,
            onChange: handlePageChange,
          }} bordered style={{ backgroundColor: '#f0f2f5' ,textAlign: 'center' }} dataSource={subData?.data?.data} />}
    </div>
  );
};

export default RateXSubscribers;
