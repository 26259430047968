import React from 'react'
import BlogForm from '../../../components/BlogForm'
const BlogWriter = () => {
  return (
    // <TextEditor/>
    <div>
    <BlogForm/>
    </div>
  )
}

export default BlogWriter