import React, { Component } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import { addArea } from "../utils/BlogSlices/Blog/BlogSlice";
import { useDispatch } from "react-redux";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";

export default class TextEditor extends Component {

  state = {
    editorState: EditorState.createEmpty(),
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  render() {
    
    const { editorState } = this.state;
    this.props.setContentType(draftToHtml(convertToRaw(editorState.getCurrentContent())))
    // console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    return (
      <div className="border-2 m-2 p-2 w-full">
        <Editor
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={this.onEditorStateChange}
        />

      </div>
    );
  }
}