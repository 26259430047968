// loginSlice.js// loginSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
const seceretKey = process.env.REACT_APP_BASE_URL 
const token =localStorage.getItem('token');
export const jobPost = createAsyncThunk("jobs/jobDetail", async (info) => {
console.log('final data',info)
    
    const response = await fetch(`${seceretKey}api/R-job/addJobs`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            "mode":'no-cors',
        },
        body:JSON.stringify(info)  
    });
    return await response.json();
});
export const jobDetailGet = createAsyncThunk("jobs/jobDetailsGet", async (credentials) => {
    const response = await fetch(`${seceretKey}api/R-job/getAllJobs`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "mode":'no-cors',
        },
        body: JSON.stringify(credentials)
    });
    return await response.json();
});

export const  jobCategoryPost   = createAsyncThunk("jobs/jobsPost", async (info) => {
    const response = await fetch(`${seceretKey}api/R-job/addJobCategories`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            "mode":'no-cors',
        },
        body: JSON.stringify(info)
    });
    return await response.json();
});

export const  jobSubCategoryPost   = createAsyncThunk("jobs/SubCategoryPost", async (info) => {
    console.log(info)
    const response = await fetch(`${seceretKey}api/R-job/addSubJobCategories?jobCategoriesId=${info.jobCategories?.jobCategoriesId}`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            "mode":'no-cors',
        },
        body: JSON.stringify( info)
    });
    return await response.json();
});
export const accountGet = createAsyncThunk("jobs/accountGet", async (credentials) => {
    const response = await fetch(`${seceretKey}api/R-job/getAllUserAccount`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "mode":'no-cors',
        },
        body: JSON.stringify(credentials)
    });
    return await response.json();
});


const jobDetailsSlice = createSlice({
    name: "BlogTask",
    initialState: {
        PostData: null,
        loading: false,
        error: null,
        Data:null,
        accountData:null,

    },
    extraReducers: (builder) => {
        builder.addCase(jobPost.pending, (state, action) => {
            state.loading = true;
            state.PostData = null;
            state.error = null;
        });
        builder.addCase(jobPost.fulfilled, (state, action) => {
            state.loading = false;
            state.PostData = action.payload;
            state.error = null;
        });
        builder.addCase(jobPost.rejected, (state, action) => {
            state.loading = false;
            state.PostData = null;
            state.error = action.error.message;
        });
        builder.addCase(jobDetailGet.pending, (state, action) => {
            state.loading = true;
            state.Data = null;
            state.error = null;
        });
        builder.addCase(jobDetailGet.fulfilled, (state, action) => {
            state.loading = false;
            state.Data = action.payload;
            state.error = null;
        });
        builder.addCase(jobDetailGet.rejected, (state, action) => {
            state.loading = false;
            state.Data = null;
            state.error = action.error.message;
        });
        builder.addCase(accountGet.pending, (state, action) => {
            state.loading = true;
            state.accountData = null;
            state.error = null;
        });
        builder.addCase(accountGet.fulfilled, (state, action) => {
            state.loading = false;
            state.accountData = action.payload;
            state.error = null;
        });
        builder.addCase(accountGet.rejected, (state, action) => {
            state.loading = false;
            state.accountData = null;
            state.error = action.error.message;
        });
    }
});

export default jobDetailsSlice.reducer;
