import React,{useEffect,useState} from 'react'
import { useSelector ,useDispatch} from "react-redux";
import { Space, Table,Skeleton } from "antd";
import Model from '../../../components/Model';
import { jobRequestFetch ,jobRequestPatch} from '../../../utils/ProductsSlices/ROwn/jobRequest';
import { toast } from "react-toastify";
import { Input } from "antd";
const { Search } = Input;
const ROwnJobRequest= () => {
  const dispatch = useDispatch();
  
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [readOnly, setReadOnly] = useState(true);
  const data = useSelector((store) => store.jobRequestSlice  );
  const access= localStorage.getItem('access');

  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState(""); 
   let paginationAndQueryData = {
      searchQuery: searchQuery ? searchQuery : "",
      currentPage: currentPage,
    };
  
    useEffect(() => {
      paginationAndQueryData = {
        searchQuery: searchQuery ? searchQuery : "",
        currentPage: currentPage,
      };
      dispatch(jobRequestFetch(paginationAndQueryData));
    }, [searchQuery, currentPage]);
    const handleSearch = () => {
      dispatch(jobRequestFetch(paginationAndQueryData));
    };
     
  
  const handlePageChange = (page) => {
    setCurrentPage(page);
    dispatch(jobRequestFetch(paginationAndQueryData))
  };

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };
  function handleEditSubmit(e){
    dispatch(jobRequestPatch(e)).then((res) => {
      dispatch(jobRequestFetch(paginationAndQueryData));
      setIsOpen(false)
      if(res.payload.code===200){
        toast.success('Updated Successfully', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          
          });
        }else{
          toast.error(res.payload.message)
        }
    });;
  }
  const columns = [
    {
      title: 'designationType',
      dataIndex: 'designationType',
      key: 'designationType',
      
    },
    {
      title: 'noticePeriod',
      dataIndex: 'noticePeriod',
      key: 'noticePeriod',
    },
    {
      title: 'department',
      dataIndex: 'department',
      key: 'department',
    },
    {
      title: "View/Edit",
      render: (_, record) => (
        <Space size="middle">
          <p> {}</p>

          <div>
            <a className="text-blue-700"
              onClick={() => {
                setSelectedRecord(record);
                handleOpen();
                setReadOnly(true);
              }}
            >
              View
            </a>
            {access=='true' &&   
            <a className="p-2 text-red-950"
              onClick={() => {
                setSelectedRecord(record);
                handleOpen();
                setReadOnly(false);
              }}
            >
              Edit
            </a>}
          </div>
        </Space>
      ),
      key: "email",
    }

  ];
  useEffect(() => {
    dispatch(jobRequestFetch(paginationAndQueryData));
  }, [dispatch]);
  
  return (
    <div>
       <Search
        onChange={(e) => {
          setSearchQuery(e.target.value);
          
        }}
        value={searchQuery}
        placeholder="input search text"
        enterButton="Search"
        size="large"
        className="m-2 rounded"
        onSearch={handleSearch}
      />
      {isOpen &&( <Model  data={selectedRecord} editable={readOnly}  onSubmitt={handleEditSubmit}  onClose={()=>setIsOpen(false)}/>)}
      { data.loading ? <Skeleton active /> : 
    <Table columns={columns}   
    pagination={{
          total: data?.jobRequestData?.totalCount,
          current: currentPage,
          onChange: handlePageChange,
        }} dataSource={data?.jobRequestData?.data} />}
    </div>
  )
}

export default ROwnJobRequest