import { createSlice } from "@reduxjs/toolkit";
const authSlice =createSlice({
    name:" Auth",
    initialState:{
      isAuth:localStorage.getItem('isAuth') === 'true'
    },
    reducers:{
        addAuth:(state,action)=>{
            state.isAuth=!state.isAuth;
            localStorage.setItem('isAuth', state.isAuth); 
        },
       
    }
})

export default authSlice.reducer
export const{addAuth} =authSlice.actions;