import React ,{useEffect,useState}from 'react'
import { useSelector,useDispatch } from "react-redux";
import { Space, Skeleton , Table} from "antd";
import { StatusData } from '../../../utils/ProductsSlices/HotelAudit/UserStatus';
import Model from '../../../components/Model';
import { Input } from "antd";
const { Search } = Input; 


const UserStatus = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [readOnly, setReadOnly] = useState(true);
  const data = useSelector((store) => store.HAUserStatus);
  const access=useSelector((store)=>store.loginData?.loginData?.data)
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  let paginationAndQueryData = {
     searchQuery: searchQuery ? searchQuery : "",
     currentPage: currentPage,
   };
  const handleSearch = () => {
     dispatch(StatusData(paginationAndQueryData));
   };
   useEffect(() => {
     paginationAndQueryData = {
       searchQuery: searchQuery ? searchQuery : "",
       currentPage: currentPage,
     };
     dispatch(StatusData(paginationAndQueryData));
   }, [searchQuery, currentPage]);
 
  const handlePageChange = (page) => {
    setCurrentPage(page);
    dispatch(StatusData(paginationAndQueryData))
  };

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };
  const handleClose = () => {
    setIsOpen(false);
    setSelectedRecord(null);
  };
  
  const columns = [
    {
      title: 'Hotel Name',
      dataIndex: 'hotelName',
      key: 'hotelName',
    }, {
      title: 'Hotel Built Year',
      dataIndex: 'hotelBuiltYear',
      key: 'hotelBuiltYear',
    }, {
      title: 'Owner Ship Type',
      dataIndex: 'ownerShipType',
      key: 'ownerShipType',
    }, {
      title: 'FullName',
      dataIndex: 'fullName',
      key: 'fullName',
    }
    , {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    }, {
      title: 'Step',
      dataIndex: 'step',
      key: 'step',
    },
    {
      title: "View/Edit",
      render: (_, record) => (
        <Space size="middle">
          <p> {}</p>

          <div>
            <a className="text-blue-700"
              onClick={() => {
                setSelectedRecord(record);
                handleOpen();
                setReadOnly(true);
              }}
            >
              View
            </a>
            {access=='true' && 
            <a className="p-2 text-red-950"
              onClick={() => {
                setSelectedRecord(record);
                handleOpen();
                setReadOnly(false);
                
              }}
            >
              Edit
            </a>}
          </div>
        </Space>
      ),
      key: "email",
    }


  ];

  useEffect(() => {
    dispatch(StatusData(paginationAndQueryData));
  }, [dispatch]);
    console.log(data?.status?.totalCount)

  return (
    <div>
      <Search
        onChange={(e) => {
          setSearchQuery(e.target.value);
          
        }}
        value={searchQuery}
        placeholder="input search text"
        enterButton="Search"
        size="large"
        className="m-2 rounded"
        onSearch={handleSearch}
      />

 {isOpen &&( <Model  data={selectedRecord} editable={readOnly}  onClose={()=>setIsOpen(false)} />)}
 { data.loading ? <Skeleton active /> :
    <Table columns={columns}   
    pagination={{
          total: data?.status?.totalCount,
          current: currentPage,
          onChange: handlePageChange,
        }} dataSource={data?.status?.data}  />}
    </div>
  )
}

export default UserStatus