import React, { useState } from 'react';
import { Input, Tag } from 'antd';
import { addPrimaryKeyword ,addSecondaryKeyword, addDomain} from "../utils/BlogSlices/PostDetailSlice";
import { useDispatch } from 'react-redux';

// import 'antd/dist/antd.css';

const { CheckableTag } = Tag;
<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/antd/dist/antd.css" />

const TagInput = ({type}) => {
  const dispatch=useDispatch();
  const [inputValue, setInputValue] = useState('');
  const [tags, setTags] = useState([]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  
  const handleInputConfirm = () => {
    if (inputValue && !tags.includes(inputValue)) {
      const updatedTags = [...tags, inputValue];
      setTags(updatedTags);
      // console.log(updatedTags);
      if(type=='primary') // Log the updated tags array
      dispatch(addPrimaryKeyword(updatedTags));
      else if(type=='secondary'){
        dispatch(addSecondaryKeyword(updatedTags))
      }else if(type=='domain'){
        dispatch(addDomain(updatedTags))
      }
      setInputValue('');
    } 
  };
  
  const handleTagClose = (removedTag) => {
    const newTags = tags.filter(tag => tag !== removedTag);
    setTags(newTags);
    if(type=='primary') {// Log the updated tags array
    dispatch(addPrimaryKeyword(newTags));
    }
    else{
      dispatch(addSecondaryKeyword(newTags))
    }
   
    
  };
  

  return (
    <div>
      <Input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onPressEnter={handleInputConfirm}
        onBlur={handleInputConfirm}
        placeholder="Type and press Enter to add a tag"
        style={{ width: '250px', marginBottom: '10px' }}
      />
      <div className='m-2'>
        {tags.map(tag => (
          <CheckableTag 
            key={tag}
            style={{margin:'2px'}}
            checked={true}
            onClose={() => handleTagClose(tag)}
          >
            {tag}
            <span style={{ marginLeft: 8 }} onClick={(e) => { e.stopPropagation(); handleTagClose(tag); }}>×</span>
          </CheckableTag>
        ))}
      </div>
    </div>
  );
};

export default TagInput;
