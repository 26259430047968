import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Space, Table, Skeleton } from "antd";
import {
  user,
  userPatch,
} from "../../../utils/ProductsSlices/HotelAudit/UserSlice";
import Model from "../../../components/Model";
import { toast } from "react-toastify";
import { Input } from "antd";
import axios from "axios";

const seceretKey = process.env.REACT_APP_BASE_URL

const { Search } = Input;

const Users = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const data = useSelector((store) => store.HAusers);

  const access = localStorage.getItem("access");
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  let paginationAndQueryData = {
    searchQuery: searchQuery ? searchQuery : "",
    currentPage: currentPage,
  };
  const handleSearch = () => {
    dispatch(user(paginationAndQueryData));
  };
  useEffect(() => {
    paginationAndQueryData = {
      searchQuery: searchQuery ? searchQuery : "",
      currentPage: currentPage,
    };
    dispatch(user(paginationAndQueryData));
  }, [searchQuery, currentPage]);
  const handlePageChange = (page) => {
    setCurrentPage(page);
    dispatch(user(paginationAndQueryData));
  };

  function handleEditSubmit(e) {
    console.log(e);
    dispatch(userPatch(e)).then((res) => {
      dispatch(user(paginationAndQueryData));
      setIsOpen(false);
      if (res.payload.code === 200) {
        toast.success("Updated Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error(res.payload.message);
      }
    });
  }

  const columns = [
    {
      title: "Hotel Name",
      dataIndex: "hotelName",
      key: "hotelName",
    },
    {
      title: "Hotel Built Year",
      dataIndex: "hotelBuiltYear",
      key: "hotelBuiltYear",
    },
    {
      title: "Owner Ship Type",
      dataIndex: "ownerShipType",
      key: "ownerShipType",
    },
    {
      title: "no. Of Rooms",
      dataIndex: "noOfRooms",
      key: "noOfRooms",
    },
    {
      title: "FullName",
      dataIndex: "fullName",
      key: "fullName",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Actions",
      render: (_, record) => (
        <Space size="middle">
          <div className="flex flex-col">
            <div className="flex flex-row justify-center items-center">
              <a
                className="text-blue-700"
                onClick={() => {
                  setSelectedRecord(record);
                  handleOpen();
                  setReadOnly(true);
                }}
              >
                View
              </a>
              {access === "true" && (
                <a
                  className="p-2 text-red-950"
                  onClick={() => {
                    setSelectedRecord(record);
                    handleOpen();
                    setReadOnly(false);
                  }}
                >
                  Edit
                </a>
              )}
            </div>

            {access === "true" && (
              <a
                className="p-2 text-red-950"
                onClick={async () => {
                  console.log(record, "rec");

                  try {
                    const response = await axios.post(
                      `${seceretKey}api/auditReportRoutes/regenerateAuditReport`,
                      {record},
                      {
                        headers: { "Content-Type": "application/json" },
                      }
                    );
                    toast("Audit Update Request Completed!");
                    console.log(response); // Handle successful response here
                  } catch (error) {
                    toast.error("Couldn't Process Audit Update Request! Try Again!");
                    console.error(error); // Handle errors here
                  }
                }}
              >
                Regenerate
              </a>
            )}
          </div>
        </Space>
      ),
      key: "email",
    },
  ];
  useEffect(() => {
    dispatch(user(paginationAndQueryData));
  }, [dispatch]);

  const [readOnly, setReadOnly] = useState(true);
  const handleOpen = () => {
    setIsOpen(true);
  };
  return (
    <div className=" h-full w-full">
      <Search
        onChange={(e) => {
          setSearchQuery(e.target.value);
        }}
        value={searchQuery}
        placeholder="input search text"
        enterButton="Search"
        size="large"
        className="m-2 rounded"
        onSearch={handleSearch}
      />

      {isOpen && (
        <Model
          data={selectedRecord}
          editable={readOnly}
          onSubmitt={handleEditSubmit}
          onClose={() => setIsOpen(false)}
        />
      )}
      {data.loading ? (
        <Skeleton active />
      ) : (
        <Table
          columns={columns}
          pagination={{
            total: data?.userData?.totalCount,
            current: currentPage,
            onChange: handlePageChange,
          }}
          dataSource={data?.userData?.data}
        />
      )}
    </div>
  );
};
export default Users;
