// loginSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {REACT_APP_API_URL} from '../secrets'
const seceretKey = process.env.REACT_APP_BASE_URL
export const subcategoriesData = createAsyncThunk("Blogs/SubCategories", async (credentials) => {
    const response = await fetch(`${seceretKey}api/adminPannel/getSubCategory`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "mode":'no-cors'
        },
        body: JSON.stringify(credentials)
    });
    return await response.json();
});



// export const subcategoriesData = createAsyncThunk("Blogs/SubCategories", async (credentials) => {
//     const response = await fetch(`${seceretKey}api/adminPannel/getSubCategory`, {
//         method: 'GET',
//         headers: {
//             "Content-Type": "application/json",
//             "mode":'no-cors'
//         },
//         body: JSON.stringify(credentials)
//     });
//     return await response.json();
// });

const subCategorieSlice = createSlice({
    name: "subCategories",
    initialState: {
        subCategories: null,
        loading: false,
        error: null
    },
    extraReducers: (builder) => {
        builder.addCase(subcategoriesData.pending, (state, action) => {
            state.loading = true;
            state.subCategories = null;
            state.error = null;
        });
        builder.addCase(subcategoriesData.fulfilled, (state, action) => {
            state.loading = false;
            state.subCategories = action.payload;
            state.error = null;
        });
        builder.addCase(subcategoriesData.rejected, (state, action) => {
            state.loading = false;
            state.subCategories = null;
            state.error = action.error.message;
        });
    }
});

export default subCategorieSlice.reducer;
