import Users from '../../pages/dashboard/HotelAudit/Users'
import { useSelector } from "react-redux";
import React from "react";
import {  Layout,  theme } from "antd";
const { Content } = Layout;
const ContentStyle = ({children}) => {

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  return (
    <Layout
    className='layout'
      style={{
        padding: "20px",
        height: '85vh'
        
      }}
    >
      <Content
        style={{
         
        }}
      >
        <div 
        className= ' content w-full h-full text-xs p-4 bg-white rounded overflow-y-scroll'      >
         
          {children}
          
        </div>
      </Content>
    </Layout>
  );
};
export default ContentStyle;
