// loginSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {REACT_APP_API_URL} from '../../../utils/secrets'
const seceretKey = process.env.REACT_APP_BASE_URL
const token =localStorage.getItem('token');
export const user = createAsyncThunk("CRM/user", async (page) => {
    const response = await fetch(`${seceretKey}api/crm/getUserDetail?page=${page}`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "mode":'no-cors',
            Authorization:token,
        }
    });
    return await response.json();
});
export const crmPatch = createAsyncThunk(
    'R-Own/crm Patch',
    async(e, thunkAPI) => {
        
      try {
        const response = await fetch(`${REACT_APP_API_URL}api/crm/editUserDetail?email=${e.email}`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(e),
        });
        const data = await response.json();
        return data;
      } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    });
const userSlice = createSlice({
    name: "user",
    initialState: {
        userData: null,
        loading: false,
        error: null,
        patchData:null,
    },
    extraReducers: (builder) => {
        builder.addCase(user.pending, (state, action) => {
            state.loading = true;
            state.userData = null;
            state.error = null;
        });
        builder.addCase(user.fulfilled, (state, action) => {
            state.loading = false;
            state.userData = action.payload;
            state.error = null;
        });
        builder.addCase(user.rejected, (state, action) => {
            state.loading = false;
            state.userData = null;
            state.error = action.error.message;
        });
        builder.addCase(crmPatch.pending, (state, action) => {
            state.patchData = null;
          
        });
        builder.addCase(crmPatch.fulfilled, (state, action) => {
            
            state.patchData = action.payload;
        });
        builder.addCase(crmPatch.rejected, (state, action) => {
            state.patchData = null;
            // state.error = action.error.message;
        });
    }
});

export default userSlice.reducer;
