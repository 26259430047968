import { createSlice } from "@reduxjs/toolkit";
const userSlice = createSlice({
  name: "users",
  initialState: {
    data: [
      {
        _id: {
          $oid: "65e642f01acabbace05147cc",
        },
        customerID: "oDmRMHpPV",
        hotelName: "D V manor",
        hotelBuiltYear: "1980",
        ownerShipType: "Owner",
        rmExp: "No",
        noOfRooms: "80",
        fullName: "nam",
        email: "namjsa77@gmail.com",
        address:
          "Quality Hotel DV Manor, Revenue Colony Park, Sidhartha Nagar, Labbipet, Vijayawada, Andhra Pradesh, India",
        cityName: "Vijayawada",
        phoneNumber: "9390685157",
        scrappedLinks: {
          Booking: {
            otaPId: "733994",
            cityCode: "-2114438",
            url: "https://www.booking.com/hotel/in/quality-d-v-manor.en-gb.html",
          },
          MakeMyTrip: {
            otaPId: "200706111347574792",
            cityCode: "CTVGA",
            url: "https://www.makemytrip.com/hotels/hotel-details/?checkin=03052024&checkout=03062024&city=CTVGA&hotelId=200706111347574792",
          },
          Goibibo: {
            otaPId: "8096024914810015419",
            cityCode: "9125341922463979045",
            url: "https://www.goibibo.com/hotels/-8096024914810015419",
          },
          Agoda: {
            otaPId: "293015",
            cityCode: "88777",
            url: "https://www.agoda.com/search?hotel=293015&selectedproperty=293015&asq=u2qcKLxwzRU5NDuxJ0kOF3T91go8JoYYMxAgy8FkBH1BN0lGAtYH25sdXoy34qb9CccqsT0WRv1XpJtruGW7MavL4q5vPXLB4YIG7B3PRQv2C98hCqwHb9P%2FSG8pNWWQZwVbU01c48m6QGAjgE2X%2F9X%2BLTLQpFDnBugEEhZpLmIbq%2FT3XokmA869iZzABKate8DtGlawjY3F7Lx4t0TG2Wru0lxoGksUnF%2FF01osA24%3D&tick=638452114724",
          },
        },
        selectedCompsets: [
          {
            image_urls: [
              "//r1imghtlak.mmtcdn.com/f19fcd2ac91011eb88340242ac110002.jpg",
              "//r1imghtlak.mmtcdn.com/0326550ac91111eb863f0242ac110008.jpg",
              "//r1imghtlak.mmtcdn.com/257fab38c90c11eb97ce0242ac110008.jfif",
              "//r1imghtlak.mmtcdn.com/c7f915f6c91211eb8ef30242ac110002.jpg",
              "//r1imghtlak.mmtcdn.com/b8ee814ac91211eb8ef30242ac110002.jpg",
            ],
            _id: {
              $oid: "65e644601acabbace05147cd",
            },
            hotel_name:
              "Fortune Murali Park, Vijayawada-Member ITC Hotel Group",
            hotel_id: "20070122080253191",
            property_type: "Hotel",
            star_rating: 4,
            discount_price: 5580,
          },
          {
            image_urls: [
              "//r2imghtlak.mmtcdn.com/r2-mmt-htl-image/room-imgs/202108092146445060-2312-f19f792e447611ec91320a58a9feac02.jpg",
              "//r1imghtlak.mmtcdn.com/9986361e447c11eca2050a58a9feac02.jpeg",
              "//r2imghtlak.mmtcdn.com/r2-mmt-htl-image/room-imgs/202108092146445060-2312-16ecf35a45da11ecbc470a58a9feac02.jpg",
              "//r2imghtlak.mmtcdn.com/r2-mmt-htl-image/room-imgs/202108092146445060-2119-ea1d4a1a447511eca3800a58a9feac02.jpg",
              "//r1imghtlak.mmtcdn.com/fe38de1a447511ec9d8d0a58a9feac02.jpg",
            ],
            _id: {
              $oid: "65e644601acabbace05147ce",
            },
            hotel_name: "Jade Suites - Luxury Boutique Hotel",
            hotel_id: "202108092146445060",
            property_type: "Hotel",
            star_rating: 4,
            discount_price: 3655,
          },
          {
            image_urls: [
              "//r1imghtlak.mmtcdn.com/e5639b1a660711e7beba0a4cef95d023.jpg",
              "//r1imghtlak.mmtcdn.com/e55a451a660711e78101022a7a5c1e3f.jpg",
              "//r1imghtlak.mmtcdn.com/e5456d70660711e7b5c90a2b4e9b5835.jpg",
              "//r1imghtlak.mmtcdn.com/e542462c660711e7b8bf0294e04297a9.jpg",
              "//r1imghtlak.mmtcdn.com/e542947e660711e7b5e10a553a66de59.jpg",
            ],
            _id: {
              $oid: "65e644601acabbace05147cf",
            },
            hotel_name: "T Emperor Service Apartment - 4BHK",
            hotel_id: "201801241501507212",
            property_type: "Apartment",
            star_rating: 4,
            discount_price: 6375,
          },
        ],
        instagramUserName: "abcd",
        fbLink: "",
        linkedInLink: "",
        twitterUserName: "",
        websiteLink: "",
        createdAt: {
          $date: "2024-03-04T21:53:52.927Z",
        },
        updatedAt: {
          $date: "2024-03-04T22:04:07.393Z",
        },
        __v: 3,
        ourHotel: {
          hotel_name: "Quality Hotel DV Manor",
          hotel_id: "200706111347574792",
          property_type: "Hotel",
          distance_from_hero: null,
          star_rating: 4,
          discount_price: 4888,
          image_urls: [
            "//r1imghtlak.mmtcdn.com/af2b3288891111e78e650a4cef95d023.jpg",
            "//r1imghtlak.mmtcdn.com/b3a2026a891111e78e650a4cef95d023.jpg",
            "//r1imghtlak.mmtcdn.com/b7e3617a891111e78e650a4cef95d023.jpg",
            "//r1imghtlak.mmtcdn.com/6ca916e0891211e795c4025f77df004f.jpg",
            "//r1imghtlak.mmtcdn.com/f945ff4c04aa11ec93ff0a58a9feac02.png",
          ],
          distance: 0,
        },
      },
      {
        _id: {
          $oid: "65e640a61acabbace05147bf",
        },
        customerID: "i3_bZysuK",
        hotelName: "Amar Kothi",
        hotelBuiltYear: "2023",
        ownerShipType: "Owner",
        rmExp: "No",
        noOfRooms: "24",
        fullName: "Akshay Chauhan ",
        email: "akshuchauhan301@gmail.com",
        address:
          "Amar Kothi, Ashok Nagar Main Road, Opposite Tata Indicom Office, Ashok Nagar, Udaipur, Rajasthan, India",
        cityName: "Udaipur",
        phoneNumber: "9284147510",
        scrappedLinks: {
          Booking: {
            otaPId: "304969",
            cityCode: "-2113635",
            url: "https://www.booking.com/hotel/in/amar-kothi.en-gb.html",
          },
          MakeMyTrip: {
            otaPId: "312254274337401",
            cityCode: "CTUDR",
            url: "https://www.makemytrip.com/hotels/hotel-details/?checkin=03052024&checkout=03062024&city=CTUDR&hotelId=312254274337401",
          },
          Goibibo: {
            otaPId: "1979804176894827166",
            cityCode: "3369724356477798574",
            url: "https://www.goibibo.com/hotels/-1979804176894827166",
          },
          Agoda: {
            otaPId: "247799",
            cityCode: "3667",
            url: "https://www.agoda.com/search?hotel=247799&selectedproperty=247799&asq=u2qcKLxwzRU5NDuxJ0kOF3T91go8JoYYMxAgy8FkBH1BN0lGAtYH25sdXoy34qb9C6vSSdLmmsWtQPtMF6MQC%2FpOJF18I2z9kM8pXdZT33TGY7%2BI7gOaBvfyRHgK7bIBr6QDs48C6hOjLzuYUvlEgOm%2B3QacrQMDUE7JkJAfzu2a050TB7C6q%2BjH9R9UxT0FDDRVsMChwA8vHcVXkcYEzTUPvcEtMmPh92yjsDerZYY%3D&tick=638452106713",
          },
        },
        selectedCompsets: [
          {
            image_urls: [
              "//r1imghtlak.mmtcdn.com/36c6b0e0584911ecbfa90a58a9feac02.jpeg",
              "//r2imghtlak.mmtcdn.com/r2-mmt-htl-image/room-imgs/201402031454213944-41309-ac14054eaddc11ec9ede0a58a9feac02.jpg",
              "//r2imghtlak.mmtcdn.com/r2-mmt-htl-image/room-imgs/201402031454213944-41309-348055c45cb911ec85830a58a9feac02.jpg",
              "//r2imghtlak.mmtcdn.com/r2-mmt-htl-image/room-imgs/201402031454213944-41309-20de6660882311ec96ae0a58a9feac02.jpg",
              "//r2imghtlak.mmtcdn.com/r2-mmt-htl-image/room-imgs/201402031454213944-41309-ac23d172addc11ec8dca0a58a9feac02.jpg",
            ],
            _id: {
              $oid: "65e641f61acabbace05147c0",
            },
            hotel_name: "Hotel Royal Pratap Niwas",
            hotel_id: "201402031454213944",
            property_type: "Hotel",
            star_rating: 3,
            discount_price: 2003,
          },
          {
            image_urls: [
              "//r2imghtlak.mmtcdn.com/r2-mmt-htl-image/room-imgs/202111281501284540-13960-c90f6f5eaf5211ecab310a58a9feac02.jpg",
              "//r2imghtlak.mmtcdn.com/r2-mmt-htl-image/room-imgs/202111281501284540-13960-c8ebc91eaf5211ec82ef0a58a9feac02.jpg",
              "//r2imghtlak.mmtcdn.com/r2-mmt-htl-image/room-imgs/202111281501284540-13960-c843c5fcaf5211ec8c900a58a9feac02.jpg",
              "//r2imghtlak.mmtcdn.com/r2-mmt-htl-image/room-imgs/202111281501284540-1452-5b10e76ec07b11ec81eb0a58a9feac02.jpg",
              "//r2imghtlak.mmtcdn.com/r2-mmt-htl-image/room-imgs/202111281501284540-1452-5ac3841ac07b11ecb9000a58a9feac02.jpg",
            ],
            _id: {
              $oid: "65e641f61acabbace05147c1",
            },
            hotel_name: "AURA BOUTIQUE HOTEL",
            hotel_id: "202111281501284540",
            property_type: "Hotel",
            star_rating: 3,
            discount_price: 2265,
          },
          {
            image_urls: [
              "//r1imghtlak.mmtcdn.com/bfb78390efdb11edbedc0a58a9feac02.jpeg",
              "//r1imghtlak.mmtcdn.com/cdf05ac6efdc11ed90630a58a9feac02.jpeg",
              "//r2imghtlak.mmtcdn.com/r2-mmt-htl-image/room-imgs/202304131527125761-6968578-262c3216da2d11ed86560a58a9feac02.jpg",
              "//r1imghtlak.mmtcdn.com/55d73236efdb11ed9ef20a58a9feac02.jpeg",
              "//r1imghtlak.mmtcdn.com/563f5ebaefdb11ed934d0a58a9feac02.jpeg",
            ],
            _id: {
              $oid: "65e641f61acabbace05147c2",
            },
            hotel_name: "Hotel Bawa Udaipur",
            hotel_id: "202304131527125761",
            property_type: "Hotel",
            star_rating: 3,
            discount_price: 2500,
          },
          {
            image_urls: [
              "//r1imghtlak.mmtcdn.com/ed72fbae8dfc11ebb84d0242ac110002.png",
              "//r1imghtlak.mmtcdn.com/7cdbacb88df911ebb0450242ac110002.jpg",
              "//r1imghtlak.mmtcdn.com/fd2540a8816c11eba4660242ac110002.jpg",
              "//r1imghtlak.mmtcdn.com/7caba4788df911ebb6a20242ac110002.jpg",
              "//r1imghtlak.mmtcdn.com/2d2049328e0211ebaef00242ac110006.jpg",
            ],
            _id: {
              $oid: "65e641f61acabbace05147c3",
            },
            hotel_name: "Shahpura Dev Panache",
            hotel_id: "202101211616216283",
            property_type: "Hotel",
            star_rating: 3,
            discount_price: 2157,
          },
          {
            image_urls: [
              "//r2imghtlak.mmtcdn.com/r2-mmt-htl-image/room-imgs/202009151208005878-2811556-cf1e96085dd011eeb8db0a58a9feac02.jpg",
              "//r2imghtlak.mmtcdn.com/r2-mmt-htl-image/room-imgs/202009151208005878-2811556-32373a7cf81411eaba420242ac110003.jpg",
              "//r2imghtlak.mmtcdn.com/r2-mmt-htl-image/room-imgs/202009151208005878-2811556-cd56a05e5dd011eeb4f00a58a9feac02.jpg",
              "//r2imghtlak.mmtcdn.com/r2-mmt-htl-image/room-imgs/202009151208005878-2811556-d128a11e5dd011eea3d20a58a9feac02.jpg",
              "//r2imghtlak.mmtcdn.com/r2-mmt-htl-image/room-imgs/202009151208005878-2811556-d207cf9c5dd011ee8da10a58a9feac02.jpg",
            ],
            _id: {
              $oid: "65e641f61acabbace05147c4",
            },
            hotel_name: "ATITHI BHANWAR - A Boutique Hotel",
            hotel_id: "202009151208005878",
            property_type: "Hotel",
            star_rating: 3,
            discount_price: 1909,
          },
          {
            image_urls: [
              "//r1imghtlak.mmtcdn.com/2de12aa3-d725-498e-b22e-3488f319dd5e.jpg",
              "//r1imghtlak.mmtcdn.com/5b0a4fe1-78e5-4428-98c0-f61731134657.jpg",
              "//r1imghtlak.mmtcdn.com/1d3d479e-e7fb-40bc-a6df-e880d57945e0.jpg",
              "//r1imghtlak.mmtcdn.com/af53d53d-b6a8-4c50-8e65-0e2110f09d63.jpg",
              "//r1imghtlak.mmtcdn.com/37a255a1-e0de-4edb-ba0e-0b8a48377a4f.jpg",
            ],
            _id: {
              $oid: "65e641f61acabbace05147c5",
            },
            hotel_name: "Infinia Stays A Luxury Boutique Hotel",
            hotel_id: "202312061647287480",
            property_type: "Homestay",
            star_rating: 3,
            discount_price: 1977,
          },
        ],
        instagramUserName: "",
        fbLink: "",
        linkedInLink: "",
        twitterUserName: "",
        websiteLink: "",
        createdAt: {
          $date: "2024-03-04T21:44:06.701Z",
        },
        updatedAt: {
          $date: "2024-03-04T21:49:42.190Z",
        },
        __v: 1,
        ourHotel: {
          hotel_name: "Amar Kothi",
          hotel_id: "312254274337401",
          property_type: "Hotel",
          distance_from_hero: null,
          star_rating: 3,
          discount_price: 14560,
          image_urls: [
            "//r1imghtlak.mmtcdn.com/581938bc8e5e11eca2140a58a9feac02.jpg",
            "//r1imghtlak.mmtcdn.com/76dbc6fc8e5e11ec9d100a58a9feac02.jpg",
            "//r1imghtlak.mmtcdn.com/582a49548e5e11ec81790a58a9feac02.jpg",
            "//r1imghtlak.mmtcdn.com/576e442a8e5e11eca1f70a58a9feac02.jpg",
            "//r1imghtlak.mmtcdn.com/66e247c68e5e11ec930a0a58a9feac02.jpg",
          ],
          distance: 0,
        },
      },
      {
        _id: {
          $oid: "65e60f0f919d84f7cc6f2189",
        },
        customerID: "dKTAFjVbp",
        hotelName: "Hotel Sachin",
        hotelBuiltYear: "2010",
        ownerShipType: "General Manager",
        rmExp: "No",
        noOfRooms: "223",
        fullName: "Akshay",
        email: "akshay.chauhan@retvensservices.com",
        address:
          "Hotel Sachin Regency, Jail Road, near Rd, DattaMandir, Nashik Road, Nashik, Maharashtra, India",
        cityName: "Nashik",
        phoneNumber: "9284147511",
        scrappedLinks: {
          MakeMyTrip: {
            otaPId: "201904051917233579",
            cityCode: "CTISK",
            url: "https://www.makemytrip.com/hotels/hotel-details/?checkin=03042024&checkout=03052024&city=CTISK&hotelId=201904051917233579",
          },
          Goibibo: {
            otaPId: "4262189242577662981",
            cityCode: "7271871476923194551",
            url: "https://www.goibibo.com/hotels/-4262189242577662981",
          },
          Agoda: {
            otaPId: "7166926",
            cityCode: "17291",
            url: "https://www.agoda.com/search?hotel=7166926&selectedproperty=7166926&asq=u2qcKLxwzRU5NDuxJ0kOF3T91go8JoYYMxAgy8FkBH1BN0lGAtYH25sdXoy34qb9e2d1xqjUkyXj2NXuN0bXVPWLiTVfbQONiGZ1RBDu83gSWGHnTxQGmIy7%2ByW7wvomfgdT6%2Bn3Zsrch5YsFCn6zx%2FjqlVlqGfPCiyLg7IMztXv5Z7xZPa1puTs3Fwtb2%2F7iDhM5YQa7ULnGPlJPMZsrN2yp71KX7kwGa5dAfnfzkV79nGGnXBKOpzXwTJDnzuC&tick=638451983272",
          },
        },
        selectedCompsets: [
          {
            image_urls: [
              "//r1imghtlak.mmtcdn.com/a72b395cc31611e984a10ac254d2bca4.jpg",
              "//r1imghtlak.mmtcdn.com/a7095a12c31611e9bf9d0ac254d2bca4.jpg",
              "//r1imghtlak.mmtcdn.com/440c0f8e578e11e995f80242ac110002.jpg",
              "//r1imghtlak.mmtcdn.com/4de3b070578e11e9a8f80242ac110002.jpg",
              "//r1imghtlak.mmtcdn.com/d1ffde6e0efe11ebb32b0242ac110002.jpeg",
            ],
            _id: {
              $oid: "65e6110c6772e80648f72324",
            },
            hotel_name: "Hotel Vishal Palace",
            hotel_id: "201604121552553485",
            property_type: "Hotel",
            star_rating: 3,
            discount_price: 1670,
          },
          {
            image_urls: [
              "//r1imghtlak.mmtcdn.com/feb4dd543e6e11ee95f70a58a9feac02.jpg",
              "//r1imghtlak.mmtcdn.com/49199290125f11eea69f0a58a9feac02.jpg",
              "//r1imghtlak.mmtcdn.com/faa8e636c5a211e9b4500242ac110002.jpeg",
              "//r1imghtlak.mmtcdn.com/eaeaadb6125f11eeae270a58a9feac02.jpeg",
              "//r1imghtlak.mmtcdn.com/9fe4bc76125f11eeaabb0a58a9feac02.jpeg",
            ],
            _id: {
              $oid: "65e6110c6772e80648f72325",
            },
            hotel_name: "Hotel De Rajnigandha",
            hotel_id: "201108111953186344",
            property_type: "Hotel",
            star_rating: 0,
            discount_price: 2523,
          },
          {
            image_urls: [
              "//r1imghtlak.mmtcdn.com/c7d3da366b8111e7915e0a4cef95d023.jpg",
              "//r1imghtlak.mmtcdn.com/c896bbfa6b8111e7915e0a4cef95d023.jpg",
              "//r1imghtlak.mmtcdn.com/c927bc186b8111e7915e0a4cef95d023.jpg",
              "//r1imghtlak.mmtcdn.com/7b3b4c90a61311edaa020a58a9feac02.jfif",
              "//r2imghtlak.mmtcdn.com/r2-mmt-htl-image/room-imgs/201603101859581576-298-8151ef6ca61311ed8e800a58a9feac02.jpg",
            ],
            _id: {
              $oid: "65e6110c6772e80648f72326",
            },
            hotel_name: "Hotel Theem Plaaza",
            hotel_id: "201603101859581576",
            property_type: "Hotel",
            star_rating: 0,
            discount_price: 1944,
          },
          {
            image_urls: [
              "//r1imghtlak.mmtcdn.com/7d5db2682a2211ee98d40a58a9feac02.jpg",
              "//r1imghtlak.mmtcdn.com/7d8a715e2a2211ee92cb0a58a9feac02.jpg",
              "//r1imghtlak.mmtcdn.com/7d6e38402a2211eebb5c0a58a9feac02.jpg",
              "//r1imghtlak.mmtcdn.com/7de064922a2211ee9b780a58a9feac02.jpg",
              "//r1imghtlak.mmtcdn.com/7d494f082a2211eebb5e0a58a9feac02.jpg",
            ],
            _id: {
              $oid: "65e6110c6772e80648f72327",
            },
            hotel_name: "FabHotel The Address",
            hotel_id: "202307031620145468",
            property_type: "Hotel",
            star_rating: 3,
            discount_price: 1366,
          },
          {
            image_urls: [
              "//r2imghtlak.mmtcdn.com/r2-mmt-htl-image/room-imgs/202312091659085396-2312-419a7c5ea55911eeaff50a58a9feac02.jpg",
              "//r2imghtlak.mmtcdn.com/r2-mmt-htl-image/room-imgs/202312091659085396-2312-4172c380a55911ee984f0a58a9feac02.jpg",
              "//r2imghtlak.mmtcdn.com/r2-mmt-htl-image/room-imgs/202312091659085396-2312-414237baa55911ee98e80a58a9feac02.jpg",
              "//r2imghtlak.mmtcdn.com/r2-mmt-htl-image/room-imgs/202312091659085396-2312-418b0d1ea55911ee8fcd0a58a9feac02.jpg",
              "//r2imghtlak.mmtcdn.com/r2-mmt-htl-image/room-imgs/202312091659085396-2312-63183402a55911eeb0fb0a58a9feac02.jpg",
            ],
            _id: {
              $oid: "65e6110c6772e80648f72328",
            },
            hotel_name: "Itsy By Treebo - Mansi",
            hotel_id: "202312091659085396",
            property_type: "Hotel",
            star_rating: 3,
            discount_price: 1821,
          },
          {
            image_urls: [
              "//r1imghtlak.mmtcdn.com/675b72a6fd6311e998fb0242ac110002.jpg",
              "//r1imghtlak.mmtcdn.com/278af13ee78a11eb94620242ac110003.png",
              "//r1imghtlak.mmtcdn.com/28c56426e78a11eba9150242ac110006.png",
              "//r1imghtlak.mmtcdn.com/4932f106e78a11eb99b90242ac110006.png",
              "//r1imghtlak.mmtcdn.com/4bc51d40e78a11eba9150242ac110006.png",
            ],
            _id: {
              $oid: "65e6110c6772e80648f72329",
            },
            hotel_name: "Hotel Sai Leela",
            hotel_id: "201109201000368817",
            property_type: "Hotel",
            star_rating: 0,
            discount_price: 1412,
          },
        ],
        instagramUserName: "akshay_is_myth",
        fbLink: "akshay",
        linkedInLink: "",
        twitterUserName: "",
        websiteLink: "sdfghj",
        createdAt: {
          $date: "2024-03-04T18:12:31.650Z",
        },
        updatedAt: {
          $date: "2024-03-04T18:26:04.466Z",
        },
        __v: 5,
        ourHotel: {
          hotel_name: "OYO Flagship Sachin Regency",
          hotel_id: "201904051917233579",
          property_type: "Hotel",
          distance_from_hero: null,
          star_rating: 3,
          discount_price: 1257,
          image_urls: [
            "//r1imghtlak.mmtcdn.com/9164632c0dd011eb85810202c34c106a.jpg",
            "//r1imghtlak.mmtcdn.com/1253962e716f11ee91990a58a9feac02.jpg",
            "//r1imghtlak.mmtcdn.com/8e48a20c0dd011ebbfdd025f77df004f.jpg",
            "//r1imghtlak.mmtcdn.com/0dff960e716f11ee85910a58a9feac02.jpg",
            "//r1imghtlak.mmtcdn.com/8f2764240dd011eb883402b35a64fa92.jpg",
          ],
        },
      },
      {
        _id: {
          $oid: "65e600e8919d84f7cc6f2142",
        },
        customerID: "ApE-Hjcnw",
        hotelName: "Hotel Bridge",
        hotelBuiltYear: "2010",
        ownerShipType: "Owner",
        rmExp: "Yes",
        noOfRooms: "40",
        fullName: "Shivam Tiwari",
        email: "shivamtiwari.1527@gmail.com",
        address:
          "The Bridge Hotel, GT Road, Jareeb Chowki, Anwarganj, Darshan Purwa, Kanpur, Uttar Pradesh, India",
        cityName: "Kanpur",
        phoneNumber: "09452059368",
        scrappedLinks: {
          Booking: {},
          MakeMyTrip: {
            otaPId: "201612271337584297",
            cityCode: "CTKNU",
            url: "https://www.makemytrip.com/hotels/hotel-details/?checkin=03042024&checkout=03052024&city=CTKNU&hotelId=201612271337584297",
          },
          Goibibo: {
            otaPId: "2964009006302649035",
            cityCode: "8312659373678178248",
            url: "https://www.goibibo.com/hotels/-2964009006302649035",
          },
          Agoda: {
            otaPId: "36770487",
            cityCode: "21081",
            url: "https://www.agoda.com/search?hotel=36770487&selectedproperty=36770487&asq=u2qcKLxwzRU5NDuxJ0kOF3T91go8JoYYMxAgy8FkBH1BN0lGAtYH25sdXoy34qb9YrW9K85HfuZgXG4Sf03JuPpOJF18I2z9kM8pXdZT33TGY7%2BI7gOaBvfyRHgK7bIBr6QDs48C6hOjLzuYUvlEgOm%2B3QacrQMDUE7JkJAfzu1Eju1G3Drugq1iOj3pZHcBdKk6xKn2a5unJfhQ0JMXpV7Dkvmek8nqXp9ucQAnTlM%3D&tick=638451943786",
          },
        },
        selectedCompsets: [
          {
            image_urls: [
              "//r1imghtlak.mmtcdn.com/bc553810072a11eb923f0242ac110002.jpg",
              "//r1imghtlak.mmtcdn.com/9c3bb40eedc811e9aa4d0242ac110002.jpg",
              "//r1imghtlak.mmtcdn.com/0af2249e98a811e9a0b10242ac110003.jpg",
              "//r1imghtlak.mmtcdn.com/2819371498a911e9a8d70242ac11000e.jfif",
              "//r1imghtlak.mmtcdn.com/6f38a296be5211e998690242ac110003.jpg",
            ],
            _id: {
              $oid: "65e60145919d84f7cc6f2143",
            },
            hotel_name: "KINGSTON RESORT",
            hotel_id: "201906271219582805",
            property_type: "Hotel",
            star_rating: 3,
            discount_price: 2961,
          },
          {
            image_urls: [
              "//r1imghtlak.mmtcdn.com/f66e89a2fda111ebaba90a58a9feac02.jpg",
              "//r1imghtlak.mmtcdn.com/cca43284fda611eb8f590a58a9feac02.jpg",
              "//r1imghtlak.mmtcdn.com/ccd9cd40fda611eb8d330a58a9feac02.jpg",
              "//r1imghtlak.mmtcdn.com/106840fafda711eb8f590a58a9feac02.jpg",
              "//r1imghtlak.mmtcdn.com/273740bafda711eb92bf0a58a9feac02.jpg",
            ],
            _id: {
              $oid: "65e60145919d84f7cc6f2144",
            },
            hotel_name: "Hotel The Legend",
            hotel_id: "202108092030535103",
            property_type: "Hotel",
            star_rating: 3,
            discount_price: 2351,
          },
          {
            image_urls: [
              "//r1imghtlak.mmtcdn.com/786b7c4a63e911e88571022fd3fb960a.jpg",
              "//r2imghtlak.mmtcdn.com/r2-mmt-htl-image/room-imgs/201704252302358872-1452-8a76b146255f11ea8c8e0ac254d2bca4.jpg",
              "//r2imghtlak.mmtcdn.com/r2-mmt-htl-image/room-imgs/201704252302358872-1452-8af59d58255f11eaae890202c34c106a.jpg",
              "//r2imghtlak.mmtcdn.com/r2-mmt-htl-image/room-imgs/201704252302358872-1452-8c677698255f11eab5360202c34c106a.jpg",
              "//r2imghtlak.mmtcdn.com/r2-mmt-htl-image/room-imgs/201704252302358872-1452-8cf3760c255f11ea9afc0a4cef95d023.jpg",
            ],
            _id: {
              $oid: "65e60145919d84f7cc6f2145",
            },
            hotel_name: "Hotel The Meera",
            hotel_id: "201704252302358872",
            property_type: "Hotel",
            star_rating: 3,
            discount_price: 3008,
          },
          {
            image_urls: [
              "//r2imghtlak.mmtcdn.com/r2-mmt-htl-image/room-imgs/201802231621388236-3848-50805c2e725111e9a2df0242ac110002.jpg",
              "//r2imghtlak.mmtcdn.com/r2-mmt-htl-image/room-imgs/201802231621388236-3848-27e30658725211e997980242ac110003.jpg",
              "//r2imghtlak.mmtcdn.com/r2-mmt-htl-image/room-imgs/201802231621388236-3848-2ea31550725211e991550242ac110003.jpg",
              "//r1imghtlak.mmtcdn.com/d4371f9c046611eab1010242ac110005.jpg",
              "//r1imghtlak.mmtcdn.com/fdadb2c8046611eaa6f00242ac110007.jpg",
            ],
            _id: {
              $oid: "65e60145919d84f7cc6f2146",
            },
            hotel_name: "Hotel DNG GRAND",
            hotel_id: "201802231621388236",
            property_type: "Hotel",
            star_rating: 4,
            discount_price: 3782,
          },
          {
            image_urls: [
              "//r2imghtlak.mmtcdn.com/r2-mmt-htl-image/room-imgs/201702061703399302-23908-cb4771e6794411e9a67c0242ac110005.jpg",
              "//r2imghtlak.mmtcdn.com/r2-mmt-htl-image/room-imgs/201702061703399302-23908-d3f03e04794411e98a3f0242ac110003.jpg",
              "//r2imghtlak.mmtcdn.com/r2-mmt-htl-image/room-imgs/201702061703399302-23908-2ad2a1487ad111e987d40242ac110004.jpg",
              "//r1imghtlak.mmtcdn.com/68856f1646e311ed96070a58a9feac02.jpeg",
              "//r1imghtlak.mmtcdn.com/6896024a46e311edaf670a58a9feac02.jpeg",
            ],
            _id: {
              $oid: "65e60145919d84f7cc6f2147",
            },
            hotel_name: "Grand Geet Hotel",
            hotel_id: "201702061703399302",
            property_type: "Hotel",
            star_rating: 3,
            discount_price: 4000,
          },
          {
            image_urls: [
              "//r1imghtlak.mmtcdn.com/4e8d184eb43811ed82ac0a58a9feac02.jpeg",
              "//r1imghtlak.mmtcdn.com/81a43096b43811ed90100a58a9feac02.jpeg",
              "//r2imghtlak.mmtcdn.com/r2-mmt-htl-image/room-imgs/201509011910079274-6791896-28c7ff3442ae11ee9be90a58a9feac02.jpg",
              "//r2imghtlak.mmtcdn.com/r2-mmt-htl-image/room-imgs/201509011910079274-6791896-4983706442ae11ee87e30a58a9feac02.jpg",
              "//r2imghtlak.mmtcdn.com/r2-mmt-htl-image/room-imgs/201509011910079274-6791896-49f7bdde42ae11ee94290a58a9feac02.jpg",
            ],
            _id: {
              $oid: "65e60145919d84f7cc6f2148",
            },
            hotel_name: "Vijay Intercontinental",
            hotel_id: "201509011910079274",
            property_type: "Hotel",
            star_rating: 5,
            discount_price: 4499,
          },
        ],
        instagramUserName: "",
        fbLink: "",
        linkedInLink: "",
        twitterUserName: "",
        websiteLink: "",
        createdAt: {
          $date: "2024-03-04T17:12:08.298Z",
        },
        updatedAt: {
          $date: "2024-03-04T17:13:41.908Z",
        },
        __v: 1,
        ourHotel: {
          hotel_name: "The Bridge",
          hotel_id: "201612271337584297",
          property_type: "Hotel",
          distance_from_hero: null,
          star_rating: 4,
          discount_price: 3008,
          image_urls: [
            "//r1imghtlak.mmtcdn.com/e7f572049fc211e899190a7d71e38dc2.jpg",
            "//r1imghtlak.mmtcdn.com/ed3491149fc211e8bef10ace404a0b5c.jpg",
            "//r1imghtlak.mmtcdn.com/ee329ce69fc211e8997002b6f4abf134.jpg",
            "//r1imghtlak.mmtcdn.com/f5abc0b09fc211e8be5a0ace404a0b5c.jpg",
            "//r2imghtlak.mmtcdn.com/r2-mmt-htl-image/room-imgs/201612271337584297-205656-f295daaac10211e6a4970224510f5e5b.jpg",
          ],
        },
      },
    ],
  },
  reducers: {
    addUser: (state, action) => {
      state.userList.push(action.payload);
    },
  },
});

export const { addUser } = userSlice.actions;
export default userSlice.reducer;
