// loginSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {REACT_APP_API_URL} from '../../secrets'
const seceretKey = process.env.REACT_APP_BASE_URL
const token =localStorage.getItem('token');
export const subsciberData = createAsyncThunk("rateX/subscriber", async (paginationAndQueryData) => {
    const response = await fetch(`${seceretKey}api/ratex/getSubscriptionDetail?Keyword=${paginationAndQueryData.searchQuery}&page=${paginationAndQueryData.currentPage}`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "mode":'no-cors',
            Authorization:token
        }
        
    });
    return await response.json();
});
export const patchSubscriber = createAsyncThunk(
    'RateX/patchSubscriber',
    async(e, thunkAPI) => {
        console.log(e.userId)
      try {
        const response = await fetch(`${seceretKey}api/ratex/editSubscriptionDetail?userId=${e.userId}`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization:token
          },
          body: JSON.stringify(e),
        });
        const data = await response.json();
        return data;
      } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    }
  );
const subscriberSlice = createSlice({
    name: "subscriber",
    initialState: {
        data: [],
        loading: false,
        error: null,
        patchData:null
    },
    extraReducers: (builder) => {
        builder.addCase(subsciberData.pending, (state, action) => {
            state.loading = true;
            state.data = null;
            state.error = null;
        });
        builder.addCase(subsciberData.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
            state.error = null;
        });
        builder.addCase(subsciberData.rejected, (state, action) => {
            state.loading = false;
            state.data = null;
            state.error = action.error.message;
        });
        builder.addCase(patchSubscriber.pending, (state, action) => {
            state.patchData = null;

            state.loading = true;

          
        });
        builder.addCase(patchSubscriber.fulfilled, (state, action) => {
            state.loading = false;

            state.patchData = action.payload;
        });
        builder.addCase(patchSubscriber.rejected, (state, action) => {
            state.patchData = null;
            state.loading = false;

            // state.error = action.error.message;
        });
    }
});

export default subscriberSlice.reducer;
