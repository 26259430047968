// loginSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { REACT_APP_API_URL } from "../../secrets";
const seceretKey = process.env.REACT_APP_BASE_URL 
const token =localStorage.getItem('token');

export const serviceFetch = createAsyncThunk("ROwn/get Service details", async (paginationAndQueryData) => {
    const response = await fetch(`${seceretKey}api/Rown/getserviceDetail?Keyword=${paginationAndQueryData.searchQuery}&page=${paginationAndQueryData.currentPage}`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "mode":'no-cors',
            Authorization:token
        }
    });
    return await response.json();
});

export const serviceDetailPatch = createAsyncThunk(
    'R-Own/service Detail Patch',
    async(e, thunkAPI) => {
        
      try {
        const response = await fetch(`${seceretKey}api/Rown/editServiceDetail?serviceId=${e.serviceId}`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization:token
          },
          body: JSON.stringify(e),
        });
        const data = await response.json();
        return data;
      } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    }
  );

const getserviceDetailSlice = createSlice({
    name: "user",
    initialState: {
        serviceData: null,
        loading: false,
        error: null,
        patchData:null
    },
    extraReducers: (builder) => {
        builder.addCase(serviceFetch.pending, (state, action) => {
            state.loading = true;
            state.serviceData = null;
            state.error = null;
        });
        builder.addCase(serviceFetch.fulfilled, (state, action) => {
            state.loading = false;
            state.serviceData = action.payload;
            state.error = null;
        });
        builder.addCase(serviceFetch.rejected, (state, action) => {
            state.loading = false;
            state.serviceData = null;
            state.error = action.error.message;
        });
        builder.addCase(serviceDetailPatch.pending, (state, action) => {
            state.patchData = null;
            state.loading = true;

        });
        builder.addCase(serviceDetailPatch.fulfilled, (state, action) => {
            state.loading = false;

            state.patchData = action.payload;
        });
        builder.addCase(serviceDetailPatch.rejected, (state, action) => {
            state.patchData = null;
            state.loading = false;

        });
    }
});

export default getserviceDetailSlice.reducer;
