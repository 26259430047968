// loginSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { REACT_APP_API_URL } from "../../secrets";
const seceretKey = process.env.REACT_APP_BASE_URL
const token =localStorage.getItem('token');
export const jobRequestFetch = createAsyncThunk("ROwn/jobRequest", async (paginationAndQueryData) => {
    const response = await fetch(`${seceretKey}api/Rown/getjobRequest?Keyword=${paginationAndQueryData.searchQuery}&page=${paginationAndQueryData.currentPage}`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "mode":'no-cors',
            Authorization:token

        }
    });
    return await response.json();
});
export const jobRequestPatch = createAsyncThunk(
    'R-Own/jobRequestPatch',
    async(e, thunkAPI) => {
        console.log(e.userId)
      try {
        const response = await fetch(`${seceretKey}api/Rown/editjobRequest?requestJob_id=${e.requestJob_id}`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization:token
          },
          body: JSON.stringify(e),
        });
        const data = await response.json();
        return data;
      } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    }
  );

const jobRequestSlice = createSlice({
    name: "jobRequest",
    initialState: {
        jobRequestData: null,
        loading: false,
        error: null,
        patchData:null,
    },
    extraReducers: (builder) => {
        builder.addCase(jobRequestFetch.pending, (state, action) => {
            state.loading = true;
            state.jobRequestData = null;
            state.error = null;
        });
        builder.addCase(jobRequestFetch.fulfilled, (state, action) => {
            state.loading = false;
            state.jobRequestData = action.payload;
            state.error = null;
        });
        builder.addCase(jobRequestFetch.rejected, (state, action) => {
            state.loading = false;
            state.jobRequestData = null;
            state.error = action.error.message;
        });
        builder.addCase(jobRequestPatch.pending, (state, action) => {
            state.patchData = null;
            state.loading = true;

        });
        builder.addCase(jobRequestPatch.fulfilled, (state, action) => {
            state.loading = false;

            state.patchData = action.payload;
        });
        builder.addCase(jobRequestPatch.rejected, (state, action) => {
            state.patchData = null;
            state.loading = false;

            // state.error = action.error.message;
        });
    
    }
});

export default jobRequestSlice.reducer;
