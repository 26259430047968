// loginSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { REACT_APP_API_URL } from "../../secrets";
const seceretKey = process.env.REACT_APP_BASE_URL
const token =localStorage.getItem('token');
export const varificationFetch = createAsyncThunk("ROwn/Verification", async (paginationAndQueryData) => {
    const response = await fetch(`${seceretKey}api/Rown/getVerificationDetailKeyword=${paginationAndQueryData.searchQuery}&page=${paginationAndQueryData.currentPage}`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "mode":'no-cors',
            Authorization:token
        }
    });
    return await response.json();
});
export const varificationPatch = createAsyncThunk(
    'R-Own/varificationPatch',
    async(e, thunkAPI) => {
        console.log(e.userId)
      try {
        const response = await fetch(`${seceretKey}api/Rown/editVerificationDetail?user_id=${e.user_id}`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization:token
          },
          body: JSON.stringify(e),
        });
        const data = await response.json();
        return data;
      } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    }
  );
const varificationSlice = createSlice({
    name: "varitication",
    initialState: {
        varificationData: null,
        loading: false,
        error: null,
        patchData:null,
    },
    extraReducers: (builder) => {
        builder.addCase(varificationFetch.pending, (state, action) => {
            state.loading = true;
            state.varificationData = null;
            state.error = null;
        });
        builder.addCase(varificationFetch.fulfilled, (state, action) => {
            state.loading = false;
            state.varificationData = action.payload;
            state.error = null;
        });
        builder.addCase(varificationFetch.rejected, (state, action) => {
            state.loading = false;
            state.varificationData = null;
            state.error = action.error.message;
        });
        builder.addCase(varificationPatch.fulfilled, (state, action) => {
            state.loading = false;
            state.patchData = action.payload;
        });
        builder.addCase(varificationPatch.rejected, (state, action) => {
            state.patchData = null;
            state.loading = false;
            // state.error = action.error.message;
        });
        builder.addCase(varificationPatch.pending, (state, action) => {
            state.loading = true;
            // state.error = action.error.message;
        });
    }
});

export default varificationSlice.reducer;
