import React from 'react'
import LayoutMain from '../../components/Layout/LayoutStyle'
const ProtectedRoute = (props) => {
    const { Component }= props
  return (
    
    <LayoutMain>
<Component/>

    </LayoutMain>
  )
}

export default ProtectedRoute