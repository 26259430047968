import React from 'react'
import { useSelector, useDispatch } from "react-redux";
import { useEffect,useState } from 'react';
import Link from 'antd/es/typography/Link';
import { useNavigate } from "react-router";
const Dashboard = () => {
    const [token, setToken] = useState(useSelector((store) => store.loginData?.data?.token) );
    const navigate = useNavigate();               
  useEffect(() => {
    if (token) {
      localStorage.setItem('token', token);
    }
  }, [token]);
  return ( 
    <div>
      Welcome to RatePulse Admin Panel
    
   
     <Link onClick={()=>{navigate('/CRM/Users', {
          state: {
          
            token: token,
          },

        })}} to='aadf'>asdf</Link>{token}
    </div>
  )
}

export default Dashboard
