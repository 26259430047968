import React, {useEffect, useState}from 'react';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { useDispatch,useSelector } from 'react-redux'; // Corrected import statement
import { addAuth } from '../../utils/authSlice';
import { Layout, Menu, Button } from 'antd';
import logo from "../../utils/icons/Dashboard logo .png";




const { Header } = Layout;


const HeaderStyle = () => {
  const dispatch = useDispatch();
  const [userName,setUserName] = useState(useSelector((store) => store.loginData?.loginData?.data?.name));
  console.log(userName)
  // const [name,setName]=useState('');
  useEffect(()=>{
    if(userName)
    localStorage.setItem('name',userName)
  },[])
  useEffect(()=>{
    setUserName(localStorage.getItem('name'));
  },[userName])


  

  // console.log(userName)
 
  const navigate = useNavigate();

  const handleSignOut = () => {
    dispatch(addAuth());
    toast.success('Logout Successfully', {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      
      });
      localStorage.clear();
    
    navigate('/')
    // Redirect or perform any other necessary actions after sign out
    // For example, you may want to redirect the user to the login page
    // window.location.href = '/login'; // Redirect to login page
  };

  return (
    <Header className='flex justify-between items-center text-sm sm:text-sm sm:justify-between'>
   
        <img className="h-9 py-1 sm:h-16" src={logo} alt="failed to load image" />
      <div className='text-white  font-bold cursor-pointer flex text-xs justify-end items-center ' >
        <h1 onClick={ ()=>{navigate('/settings')}}>

        Welcome , {userName?userName:"User"}
        </h1>
        <Button type="primary" className='flex justify-center items-center text-sm  m-2' onClick={handleSignOut}>
        Sign Out
      </Button>
      </div>
      
    </Header>
  );
};

export default HeaderStyle;
