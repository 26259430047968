// loginSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
const seceretKey = process.env.REACT_APP_BASE_URL
const token =localStorage.getItem('token');
export const  categoriesDataPost   = createAsyncThunk("config/Categories", async (info) => {
    console.log("info Inside",info)
    const formData = new FormData();
    formData.append('categoryImg', info.categoryImg);
    formData.append('category', info.category);
    formData.append('subCategory', info.subCategory);

    const response = await fetch(`${seceretKey}api/adminPannel/addBlogCategory`, {
        method: 'POST',
        headers: {
            Authorization:token
        },
        body: formData
    });
    return await response.json();
});

const CategoriesSlice = createSlice({
    name: "Categories",
    initialState: {
        postData: null,
        loading: false,
        error: null
    },
    extraReducers: (builder) => {
        builder.addCase(categoriesDataPost.pending, (state, action) => {
            state.loading = true;
            state.postData = null;
            state.error = null;
        });
        builder.addCase(categoriesDataPost.fulfilled, (state, action) => {
            state.loading = false;
            state.postData = action.payload;
            state.error = null;
        });
        builder.addCase(categoriesDataPost.rejected, (state, action) => {
            state.loading = false;
            state.postData = null;
            state.error = action.error.message;
        });
    }
});

export default CategoriesSlice.reducer;
