import React from "react";
import { Route, Navigate } from "react-router-dom";
import LayoutMain from '../../components/Layout/LayoutStyle'

const PrivateRoute = ({ Component: Component, isAuthenticated, ...rest }) => {
  return isAuthenticated ? (
    <LayoutMain>
      <Component />
    </LayoutMain>
  ) : (
    <Navigate to="/" />
  );
};

export default PrivateRoute;
