import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userData, userPatch } from "../../utils/settingSlice";
import { toast } from "react-toastify";
import { Divider, Form, Radio, Skeleton, Space, Switch } from 'antd';
const Settings = () => {
  const dispatch = useDispatch();
  const userId = localStorage.getItem("userId");
  useEffect(() => {
    if (userId) {
      dispatch(userData(userId));
    }
  }, [dispatch, userId]);
  const userDataInfo = useSelector(
    (store) => store.settingSlice?.userData?.data
  );
  useEffect(() => {
    setFirstName(userDataInfo?.firstName);
    setLastName(userDataInfo?.lastName);
    setDesignation(userDataInfo?.designation);
    setRole(userDataInfo?.role);
    setSelectedImage(userDataInfo?.profilePicture);
  }, [userDataInfo]);
  const [firstName, setFirstName] = useState(userDataInfo?.firstName);
  const [lastName, setLastName] = useState(userDataInfo?.lastName);
  const [designation, setDesignation] = useState(userDataInfo?.designation);
  const [role, setRole] = useState(userDataInfo?.role);
  const [selectedImage, setSelectedImage] = useState("");
  const [passwordHelper, setPasswordHelper] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const handleForm = (e) => {
    e.preventDefault();
  };
  const handleSubmit = (e) => {
    const info = {
      firstName: firstName,
      lastName: lastName,
      designation: designation,
      role: role,
      profilePicture: selectedImage,
      userId: userId,
      action: "addData",
    };

    dispatch(userPatch(info)).then((res) => {
      console.log(selectedImage);
      console.log(info);

      dispatch(userData(userId));
      toast.success("Updated");
      localStorage.setItem("name", firstName);
    });
  };
  const handleChangePassword = (e) => {
    const info = {
      action: "changePassword",
      newPassword: newPassword,
      oldPassword: oldPassword,
      confirmPassword: confirmPassword,
      userId: userId,
    };
    dispatch(userPatch(info)).then((res) => {
      dispatch(userData(userId));
      toast.success("password Updated");
    });
  };
  const removeImage = (e) => {
    const info = {
      action: "removeImg",
      userId: userId,
      removeImg: 0,
    };
    console.log(info);
    dispatch(userPatch(info)).then((res) => {
      dispatch(userData(userId));
      toast.success("Image Removed");
    });
  };
  return (
    <div>
      <form onSubmit={(e) => handleForm(e)}>
        <div className="container w-full  flex flex-col gap-2 rounded-lg ">
          <div className="imageSection w-full  flex border-2 p-4 rounded-lg gap-6">
            <div className="flex w-[40%] justify-start items-start">
              Profile Details
            </div>
            <div className="flex w-[80%] flex-col  items-center ">
              <div className="w-full flex  items-center gap-5 ">
              {  console.log(userDataInfo?.profilePicture,"my 10 mb image")}
              {/* https://rown-space-bucket.nyc3.digitaloceanspaces.com/R-superProfileImg/3629.jpg */}
              {/* <img src={"https://nyc3.digitaloceanspaces.com/rown-space-bucket/profilePicture/IMG_20231010_124017.jpg"} alt="dhruv 10 mb image" /> */}
              {userDataInfo?.profilePicture ? <input
                  type="image"
                  className="w-28 h-28 rounded-full"
                  // src={"https://nyc3.digitaloceanspaces.com/rown-space-bucket/profilePicture/IMG_20231010_124017.jpg"}
                  src={userDataInfo?.profilePicture?.includes('https')? userDataInfo?.profilePicture:"https://"+userDataInfo?.profilePicture}

                />: <div className="dhruv flex justify-center items-center overflow-hidden rounded-full">
                    <Skeleton.Image  className="flex justify-center items-center  rounded-full"  active={true} />
                  
                  </div>}  
                <label className="block text-gray-700 cursor-pointer rounded p-1 ">
                  <input
                    type="file"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      setSelectedImage(file);
                      console.log(file)
                      const info = {
                        userId: userId,
                        profilePicture: e.target.files[0],
                        action: "addImg",
                      };
                  
                      dispatch(userPatch(info)).then((res) => {
                  
                        dispatch(userData(userId));
                        toast.success("Updated Img");
                        localStorage.setItem("name", firstName);
                      });

                    }}
                    accept="image/* "
                    className="hidden"
                  />
                  <div>Change Image</div>
                  {/* <span className="h-10 p-2 rounded overflow-hidden bg-blue-500">Select Image</span> */}
                </label>

                <button
                  onClick={removeImage}
                  className="h-10 p-2 rounded  bg-slate-600 text-white cursor-pointer"
                >
                  Remove Image
                </button>
              </div>
            </div>
          </div>
          <div className="border-2 rounded-lg ">
            {!passwordHelper && (
              <div className="   p-4 ">
                <div className="flex justify-start">
                  <div>Basic Details</div>
                </div>
                <div className="flex justify-center gap-3">
                  <div className="flex w-[30%] justify-center gap-3 flex-col ">
                    <div className=" flex justify-between  rounded mt-3 ">
                      <div> First Name</div>
                      <input
                        type="text"
                        className="border-2 p-1 rounded "
                        value={firstName}
                        onChange={(e) => {
                          setFirstName(e.target.value);
                        }}
                        placeholder={userDataInfo?.firstName}
                      />
                    </div>
                    <div className=" flex justify-between  ">
                      <div> Last Name</div>
                      <input
                        type="text"
                        className="border-2 p-1 rounded "
                        value={lastName}
                        onChange={(e) => {
                          setLastName(e.target.value);
                        }}
                        placeholder={userDataInfo?.lastName || ""}
                      />
                    </div>
                    <div className=" flex  justify-between">
                      <div> Designation</div>
                      <input
                        type="text"
                        className="border-2 p-1 rounded "
                        value={designation}
                        onChange={(e) => {
                          setDesignation(e.target.value);
                        }}
                        placeholder={userDataInfo?.Designation || ""}
                      />
                    </div>
                    <div className=" flex justify-between">
                      <div> Role</div>
                      <input
                        type="text"
                        className="border-2 p-1 rounded "
                        value={role}
                        onChange={(e) => {
                          setRole(e.target.value);
                        }}
                        placeholder={userDataInfo?.role || ""}
                      />
                    </div>
                    <div
                      onClick={() => {
                        setPasswordHelper(!passwordHelper);
                      }}
                      className="w-full gap-5 h-8 text-zinc-100 p-2 flex cursor-pointer justify-center items-center rounded bg-slate-500"
                    >
                      Change Password
                    </div>
                  </div>{" "}
                </div>
              </div>
            )}
            {passwordHelper && (
              <div className="  p-4">
                <div className="flex justify-start">
                  <div>Basic Details</div>
                </div>
                <div className=" flex justify-center gap-4">
                <div className="flex w-[30%] justify-center gap-3 flex-col ">
                  <div  className=" flex justify-between  rounded  ">
                  <div> Old Passworrd</div>
                  <input
                    type="text"
                    className="border-2 p-1 rounded "
                    value={oldPassword}
                    onChange={(e) => {
                      setOldPassword(e.target.value);
                    }}
                    placeholder={"Enter your Old password"}
                  />
                </div>
                <div className=" flex justify-between   rounded  ">
                  <div> New Passworrd</div>
                  <input
                    type="text"
                    className="border-2 p-1 rounded "
                    value={newPassword}
                    onChange={(e) => {
                      setNewPassword(e.target.value);
                    }}
                    placeholder={"Enter you new password"}
                  />
                </div>
                <div className=" flex justify-between   rounded ">
                  <div> Confirm Passworrd</div>
                  <input
                    type="text"
                    className="border-2 p-1 rounded "
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                    }}
                    placeholder={"ReEnter your new password"}
                  />
                </div>
                <div className="flex justify-between ">
                  <div
                    onClick={handleChangePassword}
                    className="bg-slate-600 w-fit p-2 text-zinc-100  rounded cursor-pointer"
                  >
                    {" "}
                    Change password
                  </div>

                  <div
                    onClick={() => setPasswordHelper(!passwordHelper)}
                    className="bg-slate-600 w-fit p-2 text-zinc-100  rounded cursor-pointer"
                  >
                    {" "}
                    cancel
                  </div>
                </div></div>
              </div></div>
            )}
          </div>
          <div className="w-1/4  flex">
            <button
              onClick={handleSubmit}
              className="h-10 w-full p-2 cursor-pointer rounded text-slate-100  bg-blue-500"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Settings;
