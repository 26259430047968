import * as React from "react";

const DropDown = ({ children, item, index, keyi="" }) => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <div className="flex flex-col ">
      <button
        onClick={handleOpen}
        className={`flex flex-row ${keyi && keyi!="" ? 'justify-between': 'justify-end'} w-full`}
      >
        {
            keyi && keyi!="" && <div className="flex font-[600] text-[20px] ">{keyi}: </div>
        }
        
        <div
          className={
            open ? `` : " "
          }
        >
          {open ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="m19.5 8.25-7.5 7.5-7.5-7.5"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="m4.5 15.75 7.5-7.5 7.5 7.5"
              />
            </svg>
          )}
        </div>
      </button>
      <div>{!open ? <div> {children}</div> : <div></div>}</div>
    </div>
  );
};

export default DropDown;
