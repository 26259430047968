// loginSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { REACT_APP_API_URL } from "../../secrets";
const seceretKey = process.env.REACT_APP_BASE_URL
const token =localStorage.getItem('token');
export const profileFetch = createAsyncThunk("ROwn/serviceName", async (paginationAndQueryData) => {
    const response = await fetch(`${seceretKey}api/Rown/getProfile?Keyword=${paginationAndQueryData.searchQuery}&page=${paginationAndQueryData.currentPage}`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "mode":'no-cors',
            Authorization:token
        }
    });
    return await response.json();
});
export const profilePatch = createAsyncThunk(
    'R-Own/profilePatch',
    async(e, thunkAPI) => {
        console.log(e.userId)
      try {
        const response = await fetch(`${seceretKey}api/Rown/editProfile?Email=${e.Email}`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization:token
          },
          body: JSON.stringify(e),
        });
        const data = await response.json();
        return data;
      } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    }
  );

const profileSlice = createSlice({
    name: "varitication",
    initialState: {
        profileData: null,
        loading: false,
        error: null,
        patchData:null,
    },
    extraReducers: (builder) => {
        builder.addCase(profileFetch.pending, (state, action) => {
            state.loading = true;
            state.profileData = null;
            state.error = null;
        });
        builder.addCase(profileFetch.fulfilled, (state, action) => {
            state.loading = false;
            state.profileData = action.payload;
            state.error = null;
        });
        builder.addCase(profileFetch.rejected, (state, action) => {
            state.loading = false;
            state.profileData = null;
            state.error = action.error.message;
        });
        builder.addCase(profilePatch.pending, (state, action) => {
            state.patchData = null;
            state.loading = true;

          
        });
        builder.addCase(profilePatch.fulfilled, (state, action) => {
            state.loading = false;

            state.patchData = action.payload;
        });
        builder.addCase(profilePatch.rejected, (state, action) => {
            state.patchData = null;
            state.loading = false;

            // state.error = action.error.message;
        });
    
    }
});

export default profileSlice.reducer;
