import logo from './logo.svg';
import { useEffect } from 'react';
import MainRoutes from './routes';
import LayoutMain from './components/Layout/LayoutStyle'
import { ToastContainer, toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";
function App() {


  const checkTokenValidity = (token) => {
    if (token) {
      const decodedToken = jwtDecode(token);
      console.log(decodedToken,"decoded token")
      const currentTime = Date.now() / 1000; // Get current time in seconds

      // Check if token is expired
      if (decodedToken.exp < currentTime) {
        // Token expired
        return false;
      } else {
        // Token valid
        return true;
      }
    } else {
      // No token found
      return false;
    }
  };

  const logoutIfTokenExpired = () => {
    const token = localStorage.getItem('token');
    if (!checkTokenValidity(token)) {
     
      localStorage.removeItem('token');
      // You can also perform any other necessary logout actions here
    }
  };

  useEffect(() => {
    logoutIfTokenExpired();
  });

  return (
    <div className="App">
      <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>

<ToastContainer />
      <MainRoutes />
    </div> 
  );
}

export default App;
