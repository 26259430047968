import { useEffect, useState } from "react";
import { user, crmPatch } from "../../../utils/ProductsSlices/CRM/UserSlice";
import {  toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { Space,Table,Skeleton } from "antd";
import Model from "../../../components/Model";

const CRMUsers = () => {
  const userData = useSelector((store) => store.CRMuser);
  const access= localStorage.getItem('access');
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [readOnly, setReadOnly] = useState(true);
  

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };
  
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    dispatch(user(page))
  };


  const columns = [
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
    },
    {
      title: "is_verified",
      key: "is_verified",
      render: (_, record) => (
        <Space size="middle">
          <p> {record.is_verified ? "true" : "false"}</p>
        </Space>
      ),
    },
    {
      title: "is_email_Verified",
      key: "is_email_Verified",
      render: (_, record) => (
        <Space size="middle">
          <p> {record.is_email_Verified ? "true" : "false"}</p>
        </Space>
      ),
    },
    {
      title: "View/Edit",
      render: (_, record) => (
        <Space size="middle">

          <div>
            <a
              className="text-blue-700"
              onClick={() => {
                setSelectedRecord(record);
                handleOpen();
                setReadOnly(true);
              }}
            >
              View
            </a>
            {access=='true' ?
            <a
              className="p-2 text-red-950"
              onClick={() => {
                setSelectedRecord(record);
                handleOpen();
                setReadOnly(false);
              }}
            >
              Edit
            </a>
            :
            <></>
            }
          </div>
        </Space>
      ),
      key: "email",
    },
  ];
  useEffect(() => {
    dispatch(user(currentPage));
  }, [dispatch]);
  
  function handleEditSubmit(e) {
    dispatch(crmPatch(e)).then((res) => {
      dispatch(user(currentPage));
      setIsOpen(false);
      if (res.payload.code === 200) {
        toast.success("Updated Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error(res.payload.message);
      }
    });
  }

  return (
    <div>
      {isOpen && (
        <Model
          data={selectedRecord}
          editable={readOnly}
          onSubmitt={handleEditSubmit}
          onClose={()=>setIsOpen(false)}
        />
      )}
      {userData.loading ? (
        <Skeleton active />
      ) : (
        <Table columns={columns}   
        pagination={{
              total: userData?.userData?.totalCount,
              current: currentPage,
              onChange: handlePageChange,
            }} dataSource={userData?.userData?.data} />
      )}
    </div>
  );
};

export default CRMUsers;
