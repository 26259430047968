// loginSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
const seceretKey = process.env.REACT_APP_BASE_URL
const token =localStorage.getItem('token');
export const DomainGet = createAsyncThunk("Blogs/blogTaskGet", async (credentials) => {
    const response = await fetch(`${seceretKey}api/adminPannel/getDomainDetail`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "mode":'no-cors',
        },
        body: JSON.stringify(credentials)
    });
    return await response.json();
});

export const  domainData   = createAsyncThunk("config/Domain", async (credentials) => {
    const response = await fetch(`${seceretKey}api/adminPannel/addDomain`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            "mode":'no-cors',
             Authorization:token,
        },
        body: JSON.stringify(credentials)
    });
    return await response.json();
});

const domainSlice = createSlice({
    name: "Domain",
    initialState: {
        domainPost: null,
        loading: false,
        error: null,
        domainGet:null,
    },
    extraReducers: (builder) => {
        builder.addCase(domainData.pending, (state, action) => {
            state.loading = true;
            state.domainPost = null;
            state.error = null;
        });
        builder.addCase(domainData.fulfilled, (state, action) => {
            state.loading = false;
            state.domainPost = action.payload;
            state.error = null;
        });
        builder.addCase(domainData.rejected, (state, action) => {
            state.loading = false;
            state.domainPost = null;
            state.error = action.error.message;
        });
        builder.addCase(DomainGet.pending, (state, action) => {
            state.loading = true;
            state.domainGet = null;
            state.error = null;
        });
        builder.addCase(DomainGet.fulfilled, (state, action) => {
            state.loading = false;
            state.domainGet = action.payload;
            state.error = null;
        });
        builder.addCase(DomainGet.rejected, (state, action) => {
            state.loading = false;
            state.domainGet = null;
            state.error = action.error.message;
        });
    
    }
});

export default domainSlice.reducer;
