// loginSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {REACT_APP_API_URL} from '../../secrets'
const seceretKey = process.env.REACT_APP_BASE_URL
const token =localStorage.getItem('token');
export const propertiesData = createAsyncThunk("rateX/Properties", async (paginationAndQueryData) => {
    const response = await fetch(`${seceretKey}api/ratex/getPropertyDetail?Keyword=${paginationAndQueryData.searchQuery}&page=${paginationAndQueryData.currentPage} `, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "mode":'no-cors',
            Authorization:token
        }
    });
    return await response.json();
});
export const propertiePatch = createAsyncThunk(
    'RateX/patchSubscriber',
    async(e, thunkAPI) => {
        console.log(e.userId)
      try {
        const response = await fetch(`${seceretKey}api/ratex/editPropertyDetail?hId=${e.hId}`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization:token
          },
          body: JSON.stringify(e),
        });
        const data = await response.json();
        return data;
      } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    }
  );
const propertiesSlice = createSlice({
    name: "properties",
    initialState: {
        propertyData: null,
        loading: false,
        error: null,
        patchData:null,
    },
    extraReducers: (builder) => {
        builder.addCase(propertiesData.pending, (state, action) => {
            state.loading = true;
            state.propertyData = null;
            state.error = null;
        });
        builder.addCase(propertiesData.fulfilled, (state, action) => {
            state.loading = false;
            state.propertyData = action.payload;
            console.log(action.payload)
            state.error = null;
        });
        builder.addCase(propertiesData.rejected, (state, action) => {
            state.loading = false;
            state.propertyData = null;
            state.error = action.error.message;
        }); builder.addCase(propertiePatch.pending, (state, action) => {
            state.patchData = null;
            state.loading = true;

          
        });
        builder.addCase(propertiePatch.fulfilled, (state, action) => {
            state.loading = false;

            state.patchData = action.payload;
        });
        builder.addCase(propertiePatch.rejected, (state, action) => {
            state.patchData = null;
            state.loading = false;

            // state.error = action.error.message;
        });
    }
});

export default propertiesSlice.reducer;
