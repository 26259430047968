import React from 'react'
import { BrowserRouter as Router, Routes,Route } from 'react-router-dom'
import Dashboard from '../pages/dashboard'
import Users from '../pages/dashboard/HotelAudit/Users'
import HotelAuditDashboard from '../pages/dashboard/HotelAudit/Dashboard'
import HotelAuditPayment from '../pages/dashboard/HotelAudit/Payment'
import UserStatus from '../pages/dashboard/HotelAudit/UserStatus'
import ReportIssues from '../pages/dashboard/HotelAudit/ReportIssues'
import LayoutMain from '../components/Layout/LayoutStyle'
import Login from '../components/Login/Login'
import ProtectedRoute from './privateRoutes/ProtectedRoute'
import HotelIntelligenceDashboard from '../pages/dashboard/HotelIntelligence/Dashboard'
import HotelIntelligenceProperties from '../pages/dashboard/HotelIntelligence/Properties'
import HotelIntelligenceUsers from '../pages/dashboard/HotelIntelligence/Users'
import RatePulseDashboard from '../pages/dashboard/RatePulse/Dashboard'
import RatePulseProperties from '../pages/dashboard/RatePulse/Properties'
import RatePulseUsers from '../pages/dashboard/RatePulse/Users'
import RateXDashboard from '../pages/dashboard/RateX/Dashboard'
import RateXPayments from '../pages/dashboard/RateX/Payments'
import RateXProperties from '../pages/dashboard/RateX/Properties'
import RateXSubscribers from '../pages/dashboard/RateX/Subscribers'
import RateXUsers from '../pages/dashboard/RateX/Users'
import CRMUsers from '../pages/dashboard/CRM/Users'
import RVarification from '../pages/dashboard/R-Own/RVarification'
import ROwnBlogs from '../pages/dashboard/R-Own/Blogs'
import ROwnDashboard from '../pages/dashboard/R-Own/Dashboard'
import ROwnUsers from '../pages/dashboard/R-Own/Users'
import Blogs from '../pages/dashboard/Blogs/Blogs'
import BlogTask from '../pages/dashboard/Blogs/BlogTask'
import BlogWriter from '../pages/dashboard/Blogs/BlogWriter'
import Access from '../pages/dashboard/Creator/Access'
import CreatorUsers from '../pages/dashboard/Creator/CreatorUser'
import PrivateRoute from './privateRoutes/privateRoutes'
import LoginPrivate from './privateRoutes/LoginPrivate'
import SeoDocCreator from '../pages/dashboard/Config/SeoDocCreator'
import Domain from '../pages/dashboard/Config/Domain'
import Categories from '../pages/dashboard/Config/Categories'
import RspottedBug from '../pages/dashboard/R-Own/RSpottedBug'
import GetServiceDetails from '../pages/dashboard/R-Own/getServiceDetails'
import ROwnServiceName from '../pages/dashboard/R-Own/ServiceName'
import ROwnProfile from '../pages/dashboard/R-Own/Profile'
import ROwnJobRequest from '../pages/dashboard/R-Own/JobRequest'
import InterestData from '../pages/dashboard/R-Own/InterestDetails'
import HotelData from '../pages/dashboard/R-Own/HotelDetail'
import FAQData from '../pages/dashboard/R-Own/FAQ'
import DesignationData from '../pages/dashboard/R-Own/Designation'
import CompaniesData from '../pages/dashboard/R-Own/Companies'
import { useSelector } from 'react-redux'
import Settings from '../pages/Settings/Settings'
import Account from '../pages/dashboard/Jobs/Account'
import Designation from '../pages/dashboard/Jobs/Designation'
import JobDetail from '../pages/dashboard/Jobs/JobDetail'
import JobsCategories from '../pages/dashboard/Jobs/JobsCategories'
import Courses from '../pages/Courses/Courses'
const MainRoutes = () => {
  const isAuthenticated = useSelector((store)=>store.Auth.isAuth)
  // const isAuthenticated = localStorage.getItem('isAuth') === null ? false : localStorage.getItem('isAuth')
  // console.log(isAuthenticated,'auth')

  return (
    <Router>
      <Routes>
        <Route element={<LoginPrivate Component={Login}  isAuthenticated={isAuthenticated} />} path='/' />
        {/* <Route element={<Login />} path='/login' /> */}
        <Route element={<PrivateRoute Component={Dashboard}  isAuthenticated={isAuthenticated} /> } path='/dashboard' />
        {/* <Route element={<ProtectedRoute Component={Dashboard} />} path='/dashboard'/> */}
        <Route element={<ProtectedRoute Component={Users} /> } path='/hotel-audit/Users' />
        <Route element={<ProtectedRoute Component={HotelAuditDashboard} /> } path='/hotel-audit/Dashboard' />
        <Route element={<ProtectedRoute Component={HotelAuditPayment} /> } path='/hotel-audit/payments' />
        <Route element={<ProtectedRoute Component={ReportIssues} /> } path='Hotel-Audit/Report-Issues' />
        <Route element={<ProtectedRoute Component={UserStatus} /> } path='Hotel-Audit/User-Status' />

        <Route element={<ProtectedRoute Component={HotelIntelligenceDashboard} /> } path='Hotel-Intelligence/Dashboard' />
        <Route element={<ProtectedRoute Component={HotelIntelligenceProperties} /> } path='Hotel-Intelligence/Properties' />
        <Route element={<ProtectedRoute Component={HotelIntelligenceUsers} /> } path='Hotel-Intelligence/Users' />
        <Route element={<ProtectedRoute Component={RatePulseDashboard} /> } path='RatePulse/Dashboard' />
        <Route element={<ProtectedRoute Component={RatePulseProperties} /> } path='RatePulse/Properties' />
        <Route element={<ProtectedRoute Component={RatePulseUsers} /> } path='RatePulse/Users' />
        <Route element={<ProtectedRoute Component={RateXDashboard} /> } path='RateX/Dashboard' />
        <Route element={<ProtectedRoute Component={RateXPayments} /> } path='RateX/Payments' />
        <Route element={<ProtectedRoute Component={RateXProperties} /> } path='RateX/Properties' />
        <Route element={<ProtectedRoute Component={RateXSubscribers} /> } path='RateX/Subscribers' />
        <Route element={<ProtectedRoute Component={RateXUsers} /> } path='RateX/Users' />
        <Route element={<ProtectedRoute Component={CRMUsers} /> } path='crm/users' />

        <Route element={<ProtectedRoute Component={ROwnUsers} /> } path='R-Own/Users' />
        <Route element={<ProtectedRoute Component={ROwnBlogs} /> } path='R-Own/Blogs' />
        <Route element={<ProtectedRoute Component={ROwnDashboard} /> } path='R-Own/Dashboard' />
        <Route element={<ProtectedRoute Component={RVarification} /> } path='R-Own/R-Verification-Request' />
        <Route element={<ProtectedRoute Component={RspottedBug} /> } path='R-Own/SpottedBugs' />
        <Route element={<ProtectedRoute Component={GetServiceDetails} /> } path='R-Own/ServiceDetails' />
        <Route element={<ProtectedRoute Component={ROwnServiceName} /> } path='R-Own/ServiceNames' />
        <Route element={<ProtectedRoute Component={ROwnProfile} /> } path='R-Own/Profile' />
        <Route element={<ProtectedRoute Component={ROwnJobRequest} /> } path='R-Own/jobRequest' />
        <Route element={<ProtectedRoute Component={InterestData} /> } path='R-Own/InterestDetail' />
        <Route element={<ProtectedRoute Component={HotelData} /> } path='R-Own/HotelDetail' />
        <Route element={<ProtectedRoute Component={FAQData} /> } path='R-Own/FAQs' />
        <Route element={<ProtectedRoute Component={DesignationData} /> } path='R-Own/Designation' />
        <Route element={<ProtectedRoute Component={CompaniesData} /> } path='R-Own/Companies' />

        <Route element={<ProtectedRoute Component={BlogTask} /> } path='Blog/Blog-Tasks' />
        <Route element={<ProtectedRoute Component={Blogs} /> } path='Blog/Blogs' />
        <Route element={<ProtectedRoute Component={BlogWriter} /> } path='Blog/Blog-Writer' />

        <Route element={<ProtectedRoute Component={SeoDocCreator} /> } path='Config/SEO-Doc-Creator' />
        <Route element={<ProtectedRoute Component={Domain} /> } path='Config/Domain' />
        <Route element={<ProtectedRoute Component={Categories} /> } path='Config/Categories' />

        <Route element={<ProtectedRoute Component={CreatorUsers } /> } path='Creators/Users' />
        <Route element={<ProtectedRoute Component={Access } /> } path='Creators/Access' />

        <Route element={<ProtectedRoute Component={Account } /> } path='Jobs/Accounts' />
        <Route element={<ProtectedRoute Component={Designation } /> } path='Jobs/Designation' />
        <Route element={<ProtectedRoute Component={JobDetail } /> } path='Jobs/Job-Details' />
        <Route element={<ProtectedRoute Component={JobsCategories } /> } path='Jobs/Jobs-Categories' />
        <Route element={<ProtectedRoute Component={Courses } /> } path='Courses/Courses' />


        <Route element={<PrivateRoute Component={Settings } isAuthenticated={isAuthenticated}/> } path='/Settings' />


        
      </Routes>
       
    </Router>
  )
}

export default MainRoutes
