import React, { useEffect } from 'react'
import { Space,Table,Skeleton } from "antd";
import { accountGet } from '../../../utils/ProductsSlices/jobSlice/jobDetail';
import { useDispatch, useSelector } from 'react-redux';
const Account = () => {
  const dispatch=useDispatch();
useEffect(()=>{
  dispatch(accountGet());
},[])
const Account=useSelector((store)=>store.jobDetails?.accountData?.data)
console.log(Account,'accounts')
  const columns = [
    {
      title: "userId",
      dataIndex: "userId",
      key: "userId",
    },
    {
      title: "firstName",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "lastName",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "userName",
      key: "userName",
      dataIndex: "userName",
    },
    {
      title: "enail",
      key: "email",
      dataIndex: "email",
    },
    {
      title: "phoneNumber",
      key: "phoneNumber",
      dataIndex: "phoneNumber",
    },
    // },
    // {
    //   title: "is_verified",
    //   key: "is_verified",
    //   render: (_, record) => (
    //     <Space size="middle">
    //       <p> {record.is_verified ? "true" : "false"}</p>
    //     </Space>
    //   ),
    // },
    // {
    //   title: "is_email_Verified",
    //   key: "is_email_Verified",
    //   render: (_, record) => (
    //     <Space size="middle">
    //       <p> {record.is_email_Verified ? "true" : "false"}</p>
    //     </Space>
    //   ),
    // },
    // {
    //   title: "View/Edit",
    //   render: (_, record) => (
    //     <Space size="middle">

    //       <div>
    //         <a
    //           className="text-blue-700"
    //           onClick={() => {
                
    //           }}
    //         >
    //           View
    //         </a>
           
    //       </div>
    //     </Space>
    //   ),
    //   key: "email",
    // },
  ];
  return (
    <div>


<Table columns={columns}   
        pagination={{
              // total: userData?.userData?.totalCount,
              // current: currentPage,
              // onChange: handlePageChange,
            }} dataSource={Account} />
    </div>
  )
}

export default Account