import React from "react";
import {
  LaptopOutlined,
  NotificationOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Route, Link, Routes, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Breadcrumb, Layout, Menu, theme } from "antd";
import HeaderStyle from "./HeaderStyle";
import SliderStyle from "./SliderStyle";
import ContentStyle from "./ContentStyle";
const { Header, Content, Sider } = Layout;
const items1 = ["1", "2", "3"].map((key) => ({
  key,
  label: `nav ${key}`,
}));
const items2 = [UserOutlined, LaptopOutlined, NotificationOutlined].map(
  (icon, index) => {
    const key = String(index + 1);
    return {
      key: `sub${key}`,
      icon: React.createElement(icon),
      label: `subnav ${key}`,
      children: new Array(4).fill(null).map((_, j) => {
        const subKey = index * 4 + j + 1;
        return {
          key: subKey,
          label: `option${subKey}`,
        };
      }),
    };
  }
);
const LayoutMain = ({children}) => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const location = useLocation();
  const { hash, pathname, search } = location;
  return (
    <Layout>
      <HeaderStyle />
      
      <Layout>
        <SliderStyle />
        <Layout>
        <div className=' p-2 ml-3 font-bold text-xs'>{pathname.slice(1,pathname.length)}</div>
        <ContentStyle>
          
        {children}
        </ContentStyle>
        </Layout>
      </Layout>
    </Layout>
  );
};
export default LayoutMain;
