// loginSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {REACT_APP_API_URL} from '../../../utils/secrets'
const seceretKey = process.env.REACT_APP_BASE_URL

const token =localStorage.getItem('token');
export const ReportData = createAsyncThunk("HotelAudit/Payment", async (paginationAndQueryData) => {
    const response = await fetch(`${seceretKey}api/auditReportRoutes/getAuditReportIssue?Keyword=${paginationAndQueryData.searchQuery}&page=${paginationAndQueryData.currentPage}`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "mode":'no-cors',
            Authorization:token,
        }
    });
    return await response.json();
});
export const reportPatch = createAsyncThunk(
    'R-Own/report issue Patch',
    async(e, thunkAPI) => {
        
      try {
        const response = await fetch(`${seceretKey}api/auditReportRoutes/editAuditReportIssue?customerID=${e.customerID}`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization:token
          },
          body: JSON.stringify(e),
        });
        const data = await response.json();
        return data;
      } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    });

const reportIssueSlice = createSlice({
    name: "report Issue",
    initialState: {
        issue: null,
        loading: false,
        error: null,
        patchData:null,
    },
    extraReducers: (builder) => {
        builder.addCase(ReportData.pending, (state, action) => {
            state.loading = true;
            state.issue = null;
            state.error = null;
        });
        builder.addCase(ReportData.fulfilled, (state, action) => {
            state.loading = false;
            state.issue = action.payload;
            state.error = null;
        });
        builder.addCase(ReportData.rejected, (state, action) => {
            state.loading = false;
            state.issue = null;
            state.error = action.error.message;
        });
        builder.addCase(reportPatch.pending, (state, action) => {
            state.patchData = null;
          
        });
        builder.addCase(reportPatch.fulfilled, (state, action) => {
            
            state.patchData = action.payload;
        });
        builder.addCase(reportPatch.rejected, (state, action) => {
            state.patchData = null;
        });
    }
});

export default reportIssueSlice.reducer;
