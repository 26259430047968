// loginSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
const seceretKey = process.env.REACT_APP_BASE_URL
const token =localStorage.getItem('token');
export const  SEOData   = createAsyncThunk("config/SEODocCreator", async (obj) => {
    const userId=localStorage.getItem('userId');
    // const obj = [];
    // obj.append("title", info.title );
    // if (info.primaryKeyword && Array.isArray(info.primaryKeyword)) {
    //     info.primaryKeyword.forEach((item, index) => {
    //         obj.append(`domain[${index}][domainName]`, item.domainName || '');
    //         // formData.append(`domain[${index}][domainName]`, item.key2 || '');
    //         // Add more keys as needed
    //     });
    // }
    console.log('obj',obj);
    const response = await fetch(`${seceretKey}api/adminPannel/addSeoDocCreator?userId=${userId}`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            "mode":'no-cors',
            Authorization:token
        },
        body: JSON.stringify(obj)
    });
    return await response.json();
});

const SEODataSlice = createSlice({
    name: "Blogs",
    initialState: {
        postData: null,
        loading: false,
        error: null
    },
    extraReducers: (builder) => {
        builder.addCase(SEOData.pending, (state, action) => {
            state.loading = true;
            state.postData = null;
            state.error = null;
        });
        builder.addCase(SEOData.fulfilled, (state, action) => {
            state.loading = false;
            state.postData = action.payload;
            state.error = null;
        });
        builder.addCase(SEOData.rejected, (state, action) => {
            state.loading = false;
            state.postData = null;
            state.error = action.error.message;
        });
    }
});

export default SEODataSlice.reducer;
