import React,{useEffect,useState} from 'react'
import { propertiesData } from '../../../utils/ProductsSlices/RatePulse/RpPropertiesSlice'
import { useSelector ,useDispatch} from "react-redux";
import { Space, Table,Skeleton } from "antd";
import Model from '../../../components/Model';
import { patchProperty } from '../../../utils/ProductsSlices/RatePulse/RpPropertiesSlice';
import {  toast } from "react-toastify";
import { Input } from "antd";
const { Search } = Input; 

const RatePulseProperties = () => {
  const dispatch = useDispatch();
  
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [readOnly, setReadOnly] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  const [searchQuery, setSearchQuery] = useState("");

  const PropertyData = useSelector((store) => store.ratePulseProperty);
  const access= localStorage.getItem('access');

  let paginationAndQueryData = {
    searchQuery: searchQuery ? searchQuery : "",
    currentPage: currentPage,
  };
 const handleSearch = () => {
    dispatch(propertiesData(paginationAndQueryData));
  };
  useEffect(() => {
    paginationAndQueryData = {
      searchQuery: searchQuery ? searchQuery : "",
      currentPage: currentPage,
    };
    dispatch(propertiesData(paginationAndQueryData));
  }, [searchQuery, currentPage]);

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };
  
  const handlePageChange = (page) => {
    setCurrentPage(page);
    dispatch(propertiesData(paginationAndQueryData))
  };
  function handleEditSubmit(e){
    
    dispatch(patchProperty(e)).then((res) => {
      dispatch(propertiesData(paginationAndQueryData));
      setIsOpen(false) 
       if(res.payload.code===200){
        toast.success('Updated Successfully', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          
          });
        }else{
          toast.error(res.payload.message)
        }
    });; 
  }

  const columns = [
    {
      title: 'hId',
      dataIndex: 'hId',
      key: 'hId',
      
    },
    {
      title: 'hName',
      dataIndex: 'hName',
      key: 'hName',
    },
    {
      title: 'cityCode',
      dataIndex: 'cityCode',
      key: 'cityCode',
    },
    {
      title: 'isRetvens',
      key: 'isRetvens',
      render: (_, record) => (
        <Space size="middle">
          <p> {record.isRetvens?"true":"false"}</p>
          
        </Space>
        ),
    },{
      title: 'extractionDate',
      key: 'extractionDate',
      dataIndex: 'extractionDate',
    },
    {
      title: "View/Edit",
      render: (_, record) => (
        <Space size="middle">
          <div>
            <a className="text-blue-700"
              onClick={() => {
                setSelectedRecord(record);
                handleOpen();
                setReadOnly(true);
              }}
            >
              View
            </a>
            {access=='true' && 
            <a className="p-2 text-red-950"
              onClick={() => {
                setSelectedRecord(record);
                handleOpen();
                setReadOnly(false);
              }}
            >
              Edit
            </a>}
          </div>
        </Space>
      ),
      key: "email",
    }

  ];
  useEffect(() => {
    dispatch(propertiesData(paginationAndQueryData));
  }, [dispatch]);

  console.log(PropertyData)
  return (
    
    <div>
       <Search
        onChange={(e) => {
          setSearchQuery(e.target.value);
          
        }}
        value={searchQuery}
        placeholder="input search text"
        enterButton="Search"
        size="large"
        className="m-2 rounded"
        onSearch={handleSearch}
      />

      {isOpen &&( <Model  data={selectedRecord} editable={readOnly}  onSubmitt={handleEditSubmit}  onClose={()=>setIsOpen(false)} />)}
      { PropertyData.loading ? <Skeleton active /> : 

    <Table columns={columns} pagination={{
      total: PropertyData?.data?.totalCount,
      current: currentPage,
      onChange: handlePageChange,
    }} dataSource={PropertyData?.data?.data} />}
    </div>
  )
}

export default RatePulseProperties