import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { Layout, Menu, theme } from "antd";
import { UserOutlined, TeamOutlined } from "@ant-design/icons";
import ContentStyle from "./ContentStyle";
const { Sider } = Layout;
const { SubMenu } = Menu;

const SliderStyle = () => {
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  function getItem(label, key, icon, children) {
    return {
      key,
      icon,
      children,
      label,
    };
  }

  const items = [
    
    getItem("Products", "sub1", <UserOutlined />, [
      getItem("RateX", "1.1", "", [
        getItem("Dashboard", "1.1.1"),
        getItem("Users", "1.1.2"),
        getItem("Subscribers", "1.1.3"),
        getItem("Payments", "1.1.4"),
        getItem("Properties", "1.1.5"),
      ]),
      getItem("RatePulse", "1.2", "", [
        getItem("Dashboard", "1.2.1"),
        getItem("Users", "1.2.2"),
        getItem("Properties", "1.2.3"),
      ]),
      getItem("Hotel Intelligence", "1.3", "", [
        getItem("Dashboard", "1.3.1"),
        getItem("Users", "1.3.2"),
        getItem("Properties", "1.3.3"),
      ]),
      getItem("Hotel Audit", "1.4", "", [
        getItem("Dashboard", "1.4.1"),
        getItem("Users", "1.4.2"),
        getItem("Payments", "1.4.3"),
        getItem("Report Issues", "1.4.4"),
        getItem("User Status", "1.4.5"),
      ]),
      getItem("CRM", "1.5", "", [getItem("Users", "1.5.1")]),
      getItem("R-Own", "1.6", "", [
        getItem("Users", "1.6.1"),
        getItem("Dashboard", "1.6.2"),
        getItem("Blogs", "1.6.4"),
        getItem("R-Verification Request", "1.6.5"),
        getItem("SpottedBugs", "1.6.7"),
        getItem("ServiceDetails", "1.6.8"),
        getItem("ServiceNames", "1.6.9"),
        getItem("Profile", "1.6.10"),
        getItem("jobRequest", "1.6.11"),
        getItem("InterestDetail", "1.6.12"),
        getItem("HotelDetail", "1.6.13"),
        getItem("FAQs", "1.6.14"),
        getItem("Designation", "1.6.15"),
        getItem("Companies", "1.6.16"),
        
      ]),
    ]),
    getItem("Blogs", "sub2", <TeamOutlined />, [
      getItem("Blog", "2.1", "", [
        getItem("Blog Tasks", "2.1.1"),
        getItem("Blog Writer", "2.1.2"),
        getItem("Blogs", "2.1.3"),
      ]),
      getItem("Config", "2.2", "", [
        getItem("SEO Doc Creator", "2.2.1"),
        getItem("Categories", "2.2.2"),
        getItem("Domain", "2.2.3"),
      ]),
      getItem("Creators", "2", "", [
        getItem("Users", "2.3.1",),
        getItem("Access", "2.3.2"),
      ])
    ]),
    getItem('Jobs', 'sub3', <TeamOutlined />, [
      getItem('Jobs', '3.1', <TeamOutlined />, [
        getItem('Job Details', '3.1.1'),
        getItem('Jobs Categories', '3.1.2'),
        getItem('Accounts', '3.1.3'),
        getItem('Designation', '3.1.4'),
      ]),
      getItem('Courses', '3.2', <TeamOutlined />, [
        getItem('Courses', '3.2.1'),
      ])
    ]),

  ];

  return (
      <Sider
      className="Slider overflow-y-scroll"
        style={{
          height: '90vh',

         
        }}
        collapsible
        collapsed={collapsed}
        onCollapse={setCollapsed}
      >
        <div className="demo-logo-vertical w-80" />

        <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
          {items?.map((item) => (
            <SubMenu key={item.key} title={item.label} icon={item.icon}>
              {item.children?.map((child) => (
                <SubMenu key={child.key} title={child.label}>
                  {child.children?.map((grandchild) => (
                    <Menu.Item key={grandchild?.key} title={grandchild?.label}>
                      <Link
                      
                        to={`/${child.label.split(" ").join("-")}/${grandchild?.label.split(" ").join("-")}` }
                      >
                        {grandchild.label}
                      </Link>
                    </Menu.Item>
                  ))}
                </SubMenu>
              ))}
            </SubMenu>
          ))}
        </Menu>
      </Sider>
  );
};

export default SliderStyle;
