// loginSlice.js// loginSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
const seceretKey = process.env.REACT_APP_BASE_URL 
const token =localStorage.getItem('token');
export const BlogTaskPost = createAsyncThunk("Blog/BlogTask", async (info) => {
    const userId=localStorage.getItem('userId');
    const formData = new FormData();
    formData.append("blogTitle", info.blogTitle );
    formData.append("uploadDoc", info.keywordDoc);
    formData.append("assigned", info.assigned);
    formData.append('assignedTo', info.assignedTo);
    // formData.append('domain', domain);
    if (info.domain && Array.isArray(info.domain)) {
        info.domain.forEach((item, index) => {
            formData.append(`domain[${index}][domainName]`, item.domainName || '')  ;
            // formData.append(`domain[${index}][domainName]`, item.key2 || '');
            // Add more keys as needed
        });
    }
    // console.log('infoDaomin',info.domain)
    console.log("entire formData",formData)
    // console.log('formData Id',formData.id)
    const response = await fetch(`${seceretKey}api/adminPannel/addBlogTask?userId=${userId}`, {
        method: 'POST',
        headers: {
            Authorization:token
        },
        body: formData 
    });
    return await response.json();
});
export const BlogTaskGet = createAsyncThunk("Blogs/blogTaskGet", async (credentials) => {
    const response = await fetch(`${seceretKey}api/adminPannel/getBlogTask`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "mode":'no-cors',
            Authorization:token
        },
        body: JSON.stringify(credentials)
    });
    return await response.json();
});

export const BlogTaskPatch = createAsyncThunk(
    'R-Own/BlogTask patch',
    async(e, thunkAPI) => {
        
      try {
        const response = await fetch(`${seceretKey}api/adminPannel/editBlogTask?userId=${e.userId}`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization:token
          },
          body: JSON.stringify(e),
        });
        const data = await response.json();
        return data;
      } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    });

const BlogTaskSlice = createSlice({
    name: "BlogTask",
    initialState: {
        PostData: null,
        loading: false,
        error: null,
        blogData:null,
        patchData:null,
    },
    extraReducers: (builder) => {
        builder.addCase(BlogTaskPost.pending, (state, action) => {
            state.loading = true;
            state.PostData = null;
            state.error = null;
        });
        builder.addCase(BlogTaskPost.fulfilled, (state, action) => {
            state.loading = false;
            state.PostData = action.payload;
            state.error = null;
        });
        builder.addCase(BlogTaskPost.rejected, (state, action) => {
            state.loading = false;
            state.PostData = null;
            state.error = action.error.message;
        });
        builder.addCase(BlogTaskGet.pending, (state, action) => {
            state.loading = true;
            state.blogData = null;
            state.error = null;
        });
        builder.addCase(BlogTaskGet.fulfilled, (state, action) => {
            state.loading = false;
            state.blogData = action.payload;
            state.error = null;
        });
        builder.addCase(BlogTaskGet.rejected, (state, action) => {
            state.loading = false;
            state.blogData = null;
            state.error = action.error.message;
        });
        builder.addCase(BlogTaskPatch.pending, (state, action) => {
            state.loading = true;
            state.patchData = null;
            state.error = null;
        });
        builder.addCase(BlogTaskPatch.fulfilled, (state, action) => {
            state.loading = false;
            state.patchData = action.payload;
            state.error = null;
        });
        builder.addCase(BlogTaskPatch.rejected, (state, action) => {
            state.loading = false;
            state.patchData = null;
            state.error = action.error.message;
        });
    }
});

export default BlogTaskSlice.reducer;
