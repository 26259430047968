// loginSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const seceretKey = process.env.REACT_APP_BASE_URL
export const userData = createAsyncThunk("setting/user", async (userId,credentials) => {
    console.log("userId is",userId)
    const response = await fetch(`${seceretKey}api/adminPannel/getUserProfile?userId=${userId}`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "mode":'no-cors'
        },
    });

    return await response.json();
});
export const userPatch = createAsyncThunk(
    'R-Own/crm Patch',
    async(info, thunkAPI) => {
      try {

        const formData = new FormData();
        if(info.action=='addData'){
        formData.append('profilePicture', info.profilePicture);
        formData.append('lastName', info.lastName);
        formData.append('designation', info.designation);
        formData.append('role', info.role);
        formData.append('firstName', info.firstName);
        }else if(info.action=='removeImg'){
        formData.append('removeImg',info.removeImg)}
        else if(info.action=='changePassword'){
            formData.append('newPassword',info.newPassword)
            formData.append('oldPassword',info.oldPassword)
            formData.append('confirmPassword',info.confirmPassword)
        }else if(info.action=='addImg'){
            console.log("iam in add img")

            formData.append('profilePicture',info.profilePicture)
        }
        console.log("formData",formData)
        const response = await fetch(`${seceretKey}api/adminPannel/editUserProfile?userId=${info.userId}`, {
          method: 'PATCH',
          headers: {
          },
          body: formData,
        });
        const data = await response.json();
        return data;
      } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    });

    
const settingSlice = createSlice({
    name: "properties",
    initialState: {
        userData: null,
        loading: false,
        error: null,
        patchData:null,
    },
    extraReducers: (builder) => {
        builder.addCase(userData.pending, (state, action) => {
            state.loading = true;
            state.userData = null;
            state.error = null;
        });
        builder.addCase(userData.fulfilled, (state, action) => {
            state.loading = false;
            state.userData = action.payload;
            state.error = null;
        });
        builder.addCase(userData.rejected, (state, action) => {
            state.loading = false;
            state.userData = null;
            state.error = action.error.message;
        });
         builder.addCase(userPatch.pending, (state, action) => {
            state.patchData = null;
          
        });
        builder.addCase(userPatch.fulfilled, (state, action) => {
            
            state.patchData = action.payload;
        });
        builder.addCase(userPatch.rejected, (state, action) => {
            state.patchData = null;
        });
    }
});

export default settingSlice.reducer;
